import React, { Component, Fragment } from "react";
import { connect } from "react-redux";
import PropTypes from "prop-types";
import { getArticulo } from "../../actions/articulo";

import AnadirCarrito from "./AnadirCarrito";
import Precio from "./Precio";

import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";

import BotonAnadirLista from "./BotonAnadirLista";
import ArticulosRelacionados from "./ArticulosRelacionados";
import Galeria from "./Galeria";
import FamiliasMenu from "./FamiliasMenu";

import "../../css/articulo.css";
import Migas from "./Migas";
import AgrupacionFiltros from "./parciales/AgrupacionFiltros";

export class Articulo extends Component {
  static propTypes = {
    articulo: PropTypes.object.isRequired,
    menus: PropTypes.array.isRequired,
    auth: PropTypes.object.isRequired,
    getArticulo: PropTypes.func.isRequired,
  };

  componentDidMount() {
    const { id_articulo } = this.props.match.params;
    this.props.getArticulo(
      id_articulo,
      this.props.auth.hash != null ? this.props.auth.hash : ""
    );
  }

  componentDidUpdate(prevProps) {
    window.wookie();

    const { id_articulo } = this.props.match.params;
    if (
      this.props.articulo.id !== undefined &&
      this.props.articulo.id !== null &&
      this.props.articulo.id !== Number.parseInt(id_articulo)
    ) {
      this.props.getArticulo(
        id_articulo,
        this.props.auth.hash != null ? this.props.auth.hash : ""
      );
    }
  }

  render() {
    let findPos = (elem) => elem.nombre === "LISTAS";
    let findPos2 = (elem) => elem.programa === "listasCompra";
    let pos;
    let pos2;
    if (this.props.menus !== undefined) {
      pos = this.props.menus.findIndex(findPos);
      if (pos !== -1) {
        pos2 = this.props.menus[pos].hijos.findIndex(findPos2);
      } else {
        pos2 = -1;
      }
    } else {
      pos = -1;
      pos2 = -1;
    }

    let anadir_lista = false;

    if (pos2 !== -1) anadir_lista = true;

    

    return (
      <Fragment>
        <FamiliasMenu />
        <div className="container">
          <Migas id_articulo={this.props.match.params.id_articulo} />
          <div id="tt-pageContent" className="articulo">
            <div className="container-indent">
              {/* <div className="tt-mobile-product-layout visible-xs">
                 <div className="tt-mobile-product-slider arrow-location-center slick-animated-show-js">
                  {this.props.galeria.map((imagen) => (
                    <div key={"div_img_" + imagen.id}>
                      <Imagen
                        codigo_articulo={imagen.articulo}
                        principal="N"
                        numero_imagen={imagen.numero_imagen}
                      />
                    </div>
                  ))}
                </div> 
              </div> */}
              <div className="container container-fluid-mobile">
                <div className="row">
                  <div className="col-lg-6 col-md-6 col-sm-12">
                    {this.props.articulo.codigo_articulo !== undefined ? (
                      <Galeria
                        codigo_articulo={this.props.articulo.codigo_articulo}
                        origen="desktop"
                      />
                    ) : (
                      ""
                    )}
                  </div>
                  <div className="col-lg-6 col-md-6 col-sm-12">
                    <div className="tt-product-single-info">
                      <div className="tt-add-info">
                        <ul>
                          <li>
                            <span>Cód. Articulo:</span>{" "}
                            {this.props.articulo.codigo_articulo}
                          </li>
                        </ul>
                      </div>
                      <h1 className="tt-title">
                        {this.props.articulo.descrip_comercial}
                      </h1>
                      {this.props.articulo.descrip_web_reducida !== undefined &&
                      this.props.articulo.descrip_web_reducida !== "" &&
                      this.props.articulo.descrip_web_reducida !== null ? (
                        <div className="tt-wrapper">
                          <span className="descrip_web_reducida">
                            {this.props.articulo.descrip_web_reducida}
                          </span>
                        </div>
                      ) : (
                        ""
                      )}
                      {this.props.articulo.codigo_articulo !== undefined &&
                      anadir_lista === true ? (
                        <div className="tt-wrapper">
                          <ul className="tt-list-btn">
                            <li>
                              {this.props.articulo.codigo_articulo !==
                                undefined && anadir_lista === true ? (
                                <BotonAnadirLista
                                  origen="articulo"
                                  codigo_articulo={
                                    this.props.articulo.codigo_articulo
                                  }
                                />
                              ) : (
                                ""
                              )}
                            </li>
                          </ul>
                        </div>
                      ) : (
                        ""
                      )}
                      {this.props.articulo.tiene_agrupaciones === "S" ? (
                        <Fragment>
                          {this.props.articulo.codigo_articulo !== undefined ? (
                            <AgrupacionFiltros
                              codigo_articulo={
                                this.props.articulo.codigo_articulo
                              }
                            />
                          ) : (
                            ""
                          )}
                        </Fragment>
                      ) : (
                        <Fragment>
                          <div className="tt-price">
                            <span className="new-price">
                              {this.props.articulo.codigo_articulo !==
                              undefined ? (
                                <Precio
                                  codigo_articulo={
                                    this.props.articulo.codigo_articulo
                                  }
                                />
                              ) : (
                                ""
                              )}
                            </span>
                          </div>
                          <div className="tt-wrapper">
                            {this.props.articulo.codigo_articulo !==
                            undefined ? (
                              <AnadirCarrito
                                articulo={this.props.articulo}
                                mostrar_texto_btn="S"
                              />
                            ) : (
                              ""
                            )}
                          </div>
                          <div className="tt-collapse-block">
                            <div className="tt-item active">
                              <div className="tt-collapse-title">
                                DESCRIPCIÓN
                              </div>
                              <div
                                className="tt-collapse-content"
                                style={{ display: "block" }}
                                dangerouslySetInnerHTML={
                                  this.props.articulo.descrip_web_ampliada !==
                                  undefined
                                    ? {
                                        __html: this.props.articulo.descrip_web_ampliada.replaceAll(
                                          "\n",
                                          "<br>"
                                        ),
                                      }
                                    : { __html: "" }
                                }
                              ></div>
                            </div>
                            {/* <div className="tt-item">
                          <div className="tt-collapse-title">
                            ADDITIONAL INFORMATION
                          </div>
                          <div className="tt-collapse-content">
                            <table className="tt-table-03">
                              <tbody>
                                <tr>
                                  <td>Color:</td>
                                  <td>Blue, Purple, White</td>
                                </tr>
                                <tr>
                                  <td>Size:</td>
                                  <td>20, 24</td>
                                </tr>
                                <tr>
                                  <td>Material:</td>
                                  <td>100% Polyester</td>
                                </tr>
                              </tbody>
                            </table>
                          </div>
                        </div> */}
                          </div>
                        </Fragment>
                      )}
                    </div>
                  </div>
                </div>
              </div>
            </div>
            {this.props.articulo.tiene_agrupaciones === "S" ? (
              <div className="container-indent">
                <div className="container container-fluid-mobile">
                  <div className="row">
                    <div className="col-sm-12">
                      <div className="tt-collapse-block">
                        <div className="tt-item active">
                          <div className="tt-collapse-title">DESCRIPCIÓN</div>
                          <div
                            className="tt-collapse-content"
                            style={{ display: "block" }}
                            dangerouslySetInnerHTML={
                              this.props.articulo.descrip_web_ampliada !==
                              undefined
                                ? {
                                    __html: this.props.articulo.descrip_web_ampliada.replaceAll(
                                      "\n",
                                      "<br>"
                                    ),
                                  }
                                : { __html: "" }
                            }
                          ></div>
                        </div>
                        {/* <div className="tt-item">
                          <div className="tt-collapse-title">
                            ADDITIONAL INFORMATION
                          </div>
                          <div className="tt-collapse-content">
                            <table className="tt-table-03">
                              <tbody>
                                <tr>
                                  <td>Color:</td>
                                  <td>Blue, Purple, White</td>
                                </tr>
                                <tr>
                                  <td>Size:</td>
                                  <td>20, 24</td>
                                </tr>
                                <tr>
                                  <td>Material:</td>
                                  <td>100% Polyester</td>
                                </tr>
                              </tbody>
                            </table>
                          </div>
                        </div> */}
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            ) : (
              ""
            )}
            {this.props.articulo.id !== undefined ? (
              <ArticulosRelacionados id_articulo={this.props.articulo.id} />
            ) : (
              ""
            )}
          </div>
        </div>
      </Fragment>
    );
  }
}

const mapStateToProps = (state) => ({
  articulo: state.articulo.articulo,
  menus: state.portal.menus,
  auth: state.auth,
});

export default connect(mapStateToProps, {
  getArticulo,
})(Articulo);
