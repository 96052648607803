import  axios from "../axiosConfig";

import { createMessage, returnErrors } from "./messages";
import {
  ENVIAR_CONTACTO,
  ENVIAR_CONTACTO_SUCCESS,
  ENVIAR_CONTACTO_FAIL,
} from "./types";

// ENVIAR FORMULARIO DE CONTACTO
export const enviarContacto = (nombre, email, asunto, mensaje) => (
  dispatch,
  getState
) => {
  //User Loading
  dispatch({ type: ENVIAR_CONTACTO });

  //Headers
  const config = {
    headers: {
      "Content-Type": "application/json",
    },
  };

  const { auth } = getState();
  let datos = {
    nombre,
    email,
    asunto,
    mensaje,
    usuario: auth.isAuthenticated ? auth.user.usuario_web : "",
  };

  // Request Body
  const body = JSON.stringify(datos);
  
  axios
    .post(`/usuario/enviarContacto`, body, config)
    .then((res) => {
      console.log("Contacto: ", res.data);
      if (res.data.resultado === "OK") {
        dispatch({
          type: ENVIAR_CONTACTO_SUCCESS,
        });
        dispatch(
          createMessage({
            error: "",
            recuperarPass: res.data.mensaje,
          })
        );
      } else {
        dispatch({
          type: ENVIAR_CONTACTO_FAIL,
        });
        dispatch(returnErrors(res.data.mensaje, 0));
      }
    })
    .catch((err) => {
      dispatch(returnErrors(err.response.data, err.response.status));
      dispatch({
        type: ENVIAR_CONTACTO_FAIL,
      });
    });
};
