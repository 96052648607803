import React, { Component, Fragment } from "react";
import { connect } from "react-redux";
import PropTypes from "prop-types";
import {
  getTiposExpedientes,
  getTiposExpedientesCampos,
  getExpedientes,
  nuevoExpediente,
} from "../../actions/expedientes";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faEye, faPlus } from "@fortawesome/free-solid-svg-icons";
import NuevoExpediente from "./NuevoExpediente";
import ExpedientesCreados from "./ExpedientesCreados";

import "../../css/expedientes.css";

export class Expedientes extends Component {

  state={
    accion:"",
    tipo_expediente_seleccionado:""
  }

  static propTypes = {
    tipos_expedientes: PropTypes.array.isRequired,
    tipos_expedientes_campos: PropTypes.array.isRequired,
    expedientes: PropTypes.array.isRequired,
    getExpedientes: PropTypes.func.isRequired,
    getTiposExpedientes: PropTypes.func.isRequired,
    getTiposExpedientesCampos: PropTypes.func.isRequired,
    nuevoExpediente: PropTypes.func.isRequired,
  };

  componentDidMount() {
    this.props.getTiposExpedientes();
  }

  nuevo = (e) => {
    e.preventDefault();
    let tipo_expediente = e.currentTarget.dataset.tipoExpediente;
    this.props.getTiposExpedientesCampos(tipo_expediente);
    this.setState({ tipo_expediente_seleccionado: tipo_expediente });
    this.setState({ accion: "nuevo" });
  };

  listar = (e) => {
    e.preventDefault();
    let tipo_expediente = e.currentTarget.dataset.tipoExpediente;
    this.props.getExpedientes(tipo_expediente);
    this.setState({ accion: "listar" });
  };

  render() {
    return (
      <Fragment>
        <div className="container">
          <div id="tt-pageContent" className="expedientes">
            <div className="container-indent">
              <div className="container">
                <h1 className="tt-title-subpages noborder">
                  TIPOS EXPEDIENTES
                </h1>
                <div className="row">
                  <div className="col-sm-12 col-md-12 col-lg-12">
                    {this.props.tipos_expedientes !== undefined &&
                    this.props.tipos_expedientes.length === 0 ? (
                      ""
                    ) : (
                      <Fragment>
                        <div className="tt-shopcart-table listas_compra_desctop row">
                          <div className="col-sm-12 col-md-12 col-lg-12">
                            <div className="row titulos">
                              <div className="col-sm-12 col-md-3 col-lg-3">
                                TIPO EXPEDIENTE
                              </div>
                              <div className="col-sm-12 col-md-3 col-lg-3">
                                DESCRIPCIÓN
                              </div>
                              <div className="col-sm-12 col-md-6 col-lg-6">
                                ACCIONES
                              </div>
                            </div>
                            {this.props.tipos_expedientes !== undefined &&
                            this.props.tipos_expedientes.length > 0
                              ? this.props.tipos_expedientes.map(
                                  (tipo_expediente) => {
                                    return (
                                      <div
                                        className="row"
                                        key={tipo_expediente.tipo_expediente}
                                      >
                                        <div className="col-sm-12 col-md-3 col-lg-3">
                                          {tipo_expediente.tipo_expediente}
                                        </div>
                                        <div className="col-sm-12 col-md-3 col-lg-3">
                                          {tipo_expediente.descripcion}
                                        </div>
                                        <div className="col-sm-12 col-md-6 col-lg-6">
                                          <span
                                            onClick={this.nuevo}
                                            data-tipo-expediente={
                                              tipo_expediente.tipo_expediente
                                            }
                                            className="accion"
                                          >
                                            <FontAwesomeIcon icon={faPlus} />
                                          </span>
                                          <span
                                            onClick={this.listar}
                                            data-tipo-expediente={
                                              tipo_expediente.tipo_expediente
                                            }
                                            className="accion"
                                          >
                                            <FontAwesomeIcon icon={faEye} />
                                          </span>
                                        </div>
                                      </div>
                                    );
                                  }
                                )
                              : ""}
                          </div>
                        </div>

                        <div className="tt-shopcart-table listas_compra_mobile row">
                          <div className="col-sm-12 col-md-12 col-lg-12">
                            {this.props.tipos_expedientes !== undefined &&
                            this.props.tipos_expedientes.length > 0
                              ? this.props.tipos_expedientes.map(
                                  (tipo_expediente) => {
                                    return (
                                      <div
                                        className="row"
                                        key={tipo_expediente.tipo_expediente}
                                      >
                                        <div className="col-12">
                                          <div className="row ">
                                            <div className="col-6 titulos">
                                              TIPO EXPEDIENTE
                                            </div>
                                            <div className="col-6">
                                              {tipo_expediente.tipo_expediente}
                                            </div>
                                          </div>

                                          <div className="row ">
                                            <div className="col-6 titulos">
                                              DESCRIPCIÓN
                                            </div>
                                            <div className="col-6">
                                              {tipo_expediente.descripcion}
                                            </div>
                                          </div>
                                          <div className="row ">
                                            <div className="col-6 titulos">
                                              ACCIONES
                                            </div>
                                            <div className="col-6">
                                              <div className="row">
                                                <div className="col-6">
                                                  <span
                                                    onClick={this.nuevo}
                                                    data-tipo-expediente={
                                                      tipo_expediente.tipo_expediente
                                                    }
                                                    className="accion"
                                                  >
                                                    <FontAwesomeIcon
                                                      icon={faPlus}
                                                    />
                                                  </span>
                                                </div>
                                                <div className="col-6">
                                                  <span
                                                    onClick={this.listar}
                                                    data-tipo-expediente={
                                                      tipo_expediente.tipo_expediente
                                                    }
                                                    className="accion"
                                                  >
                                                    <FontAwesomeIcon
                                                      icon={faEye}
                                                    />
                                                  </span>
                                                </div>
                                              </div>
                                            </div>
                                          </div>
                                        </div>
                                      </div>
                                    );
                                  }
                                )
                              : ""}
                          </div>
                        </div>
                      </Fragment>
                    )}
                  </div>
                </div>
              </div>
            </div>

            {this.state.accion !== undefined &&
            this.state.accion === "nuevo" &&
            this.props.tipos_expedientes_campos !== undefined &&
            this.props.tipos_expedientes_campos.length > 0 ? (
              <NuevoExpediente
                tipos_expedientes_campos={this.props.tipos_expedientes_campos}
                tipo_expediente={this.state.tipo_expediente_seleccionado}
              />
            ) : (
              ""
            )}

            {this.state.accion !== undefined &&
            this.state.accion === "listar" &&
            this.props.expedientes !== undefined &&
            this.props.expedientes.length > 0 ? (
              <ExpedientesCreados expedientes={this.props.expedientes} />
            ) : (
              ""
            )}
          </div>
        </div>
      </Fragment>
    );
  }
}

const mapStateToProps = (state) => ({
  tipos_expedientes: state.expedientes.tipos_expedientes,
  tipos_expedientes_campos: state.expedientes.tipos_expedientes_campos,
  expedientes: state.expedientes.expedientes,
});

export default connect(mapStateToProps, {
  getExpedientes,
  getTiposExpedientes,
  getTiposExpedientesCampos,
  nuevoExpediente,
})(Expedientes);
