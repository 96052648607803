import axios from "../axiosConfig";
import { createMessage } from "./messages";
import {
  GET_TIPOS_EXPEDIENTES,
  GET_TIPOS_EXPEDIENTES_CAMPOS,
  GET_EXPEDIENTES,
} from "./types";

import { tokenConfig } from "./auth";

export const getTiposExpedientes = () => (dispatch, getState) => {
  axios
    .get(`/tiposexpedientes/`, tokenConfig(getState))
    .then((res) => {
      dispatch({
        type: GET_TIPOS_EXPEDIENTES,
        payload: res.data,
      });
    })
    .catch((err) => console.log(err));
};

export const getTiposExpedientesCampos = (search = "") => (
  dispatch,
  getState
) => {
  axios
    .get(`/tiposexpedientescampos/?search=${search}`, tokenConfig(getState))
    .then((res) => {
      dispatch({
        type: GET_TIPOS_EXPEDIENTES_CAMPOS,
        payload: res.data,
      });
    })
    .catch((err) => console.log(err));
};

export const getExpedientes = (search = "") => (dispatch, getState) => {
  axios
    .get(`/expedientes/?search=${search}`, tokenConfig(getState))
    .then((res) => {
      dispatch({
        type: GET_EXPEDIENTES,
        payload: res.data,
      });
    })
    .catch((err) => console.log(err));
};

export const nuevoExpediente = (datos) => (dispatch, getState) => {
  const body = JSON.stringify({ datos: datos });

  axios
    .post(`/expedientes/`, body, tokenConfig(getState))
    .then((res) => {
      dispatch(
        createMessage({
          expedienteOK: res.data,
        })
      );
    })
    .catch((err) => {
      console.log(err);
      dispatch(
        createMessage({
          expedienteKO: err.response.data,
        })
      );
    });
};
