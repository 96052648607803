import React, { Component, Fragment } from "react";
import { connect } from "react-redux";
import PropTypes from "prop-types";
import { getAlmacenes, setAlmacenRecogida } from "../../actions/almacenes";
import { getEstado, getProvincia } from "../../actions/domiciliosEnvio";

export class Almacenes extends Component {
  static propTypes = {
    getAlmacenes: PropTypes.func.isRequired,
    setAlmacenRecogida: PropTypes.func.isRequired,
    almacenes: PropTypes.array.isRequired,
    almacen: PropTypes.object.isRequired,
    estado: PropTypes.object.isRequired,
    provincia: PropTypes.object.isRequired,
    getEstado: PropTypes.func.isRequired,
    getProvincia: PropTypes.func.isRequired,
  };

  componentDidMount() {
    this.props.getAlmacenes();
  }

  componentDidUpdate(prevProps) {
    if (prevProps.almacenes !== this.props.almacenes) {
      if (this.props.almacenes.length === 1) {
        this.props.getEstado(this.props.almacenes[0].codigo_estado);
        this.props.getProvincia(
          this.props.almacenes[0].codigo_estado,
          this.props.almacenes[0].codigo_provincia
        );
        this.props.setAlmacenRecogida(this.props.almacenes[0]);
      }
    }
  }

  onchange = (e) => {
    const almacen_recogida = this.props.almacenes.filter(
      (alma) => alma.almacen === e.target.value
    );
    this.props.setAlmacenRecogida(almacen_recogida[0]);

    this.props.getEstado(almacen_recogida[0].codigo_estado);
    this.props.getProvincia(
      almacen_recogida[0].codigo_estado,
      almacen_recogida[0].codigo_provincia
    );
  };

  render() {
    return (
      <Fragment>
        <div className="tt-shopcart-box">
          <h4 className="tt-title">PUNTOS DE RECOGIDA</h4>

          <p>Seleccione un lugar de la lista</p>
          <form className="form-default">
            <div className="form-group">
              <label htmlFor="almacen_recogida">
                PUNTO DE RECOGIDA<sup>*</sup>
              </label>

              {this.props.almacenes.length > 0 ? (
                <Fragment>
                  <div className="form-group">
                    <select
                      className="form-control"
                      name="almacen_recogida"
                      onChange={this.onchange}
                    >
                      <option key="0" value=""></option>
                      {this.props.almacenes.map((almacen, index) => {
                        if (almacen.almacen === this.props.almacen.almacen) {
                          return (
                            <option
                              key={index}
                              value={almacen.almacen}
                              selected
                            >
                              {almacen.nombre}
                            </option>
                          );
                        } else {
                          return (
                            <option key={index} value={almacen.almacen}>
                              {almacen.nombre}
                            </option>
                          );
                        }
                      })}
                    </select>
                  </div>
                  <div className="form-group">
                    <label htmlFor="address_province">DIRECCIÓN</label>
                    <input
                      type="text"
                      name="direccion"
                      value={this.props.almacen.direccion || ""}
                      className="form-control"
                      placeholder="Dirección"
                      readOnly
                    />
                  </div>
                  <div className="form-group">
                    <label htmlFor="address_province">LOCALIDAD</label>
                    <input
                      type="text"
                      name="localidad"
                      value={this.props.almacen.localidad || ""}
                      className="form-control"
                      placeholder="Localidad"
                      readOnly
                    />
                  </div>
                  <div className="form-group">
                    <label htmlFor="address_province">CÓDIGO POSTAL</label>
                    <input
                      type="text"
                      name="cod_postal"
                      value={this.props.almacen.cod_postal || ""}
                      className="form-control"
                      placeholder="Código Postal"
                      readOnly
                    />
                  </div>
                  <div className="form-group">
                    <label htmlFor="address_province">
                      ESTADO <sup>*</sup>
                    </label>
                    <input
                      type="text"
                      name="estado"
                      value={
                        this.props.estado !== undefined
                          ? this.props.estado.nombre
                          : ""
                      }
                      className="form-control"
                      placeholder="Estado"
                      readOnly
                    />
                  </div>
                  <div className="form-group">
                    <label htmlFor="address_zip">PROVINCIA</label>
                    <input
                      type="text"
                      name="provincia"
                      value={
                        this.props.provincia !== undefined
                          ? this.props.provincia.nombre
                          : ""
                      }
                      className="form-control"
                      placeholder="Provincia"
                      readOnly
                    />
                  </div>
                </Fragment>
              ) : (
                <p>No existen puntos de recogida disponibles</p>
              )}
            </div>
          </form>
        </div>
      </Fragment>
    );
  }
}

const mapStateToProps = (state) => ({
  almacenes: state.almacenes.almacenes,
  almacen: state.almacenes.almacen,
  estado: state.domiciliosEnvio.estado,
  provincia: state.domiciliosEnvio.provincia,
});

export default connect(mapStateToProps, {
  getAlmacenes,
  setAlmacenRecogida,
  getProvincia,
  getEstado,
})(Almacenes);
