import axios from "../axiosConfig";
import { tokenConfig } from "./auth";
import {
  FETCH_MENU_INFORMES,
  GET_MENU_INFORMES,
  GET_FILTROS_PLUGINS_INFORME,
  SET_INFORME_SELECCIONADO,
  SET_DATOS_INFORME,
  GET_DATOS_INFORME,
  OBTENER_EFACTURA,
  DESCARGA_EFACTURA,
  OBTENER_REPORT,
  DESCARGA_REPORT,
  GET_MAS_DATOS_INFORME,
  SET_MAS_DATOS_INFORME,
  SET_LISTA_VALORES,
} from "./types";

const descargarFichero = (nombre, fichero, extension = "") => {
  const binary = atob(fichero.replace(/\s/g, ""));
  const len = binary.length;
  const buffer = new ArrayBuffer(len);
  const view = new Uint8Array(buffer);

  for (let i = 0; i < len; i += 1) {
    view[i] = binary.charCodeAt(i);
  }
  // create the blob object with content-type "application/pdf"
  const blob = new Blob([view], { type: "application/octet-stream" });
  const url = URL.createObjectURL(blob);

  const a = document.createElement("a");
  document.body.appendChild(a);
  a.style = "display: none";
  a.href = url;
  a.download = nombre;

  a.click();
};

// GET MENU INFORMES
export const getMenuInformes = () => (dispatch, getState) => {
  dispatch({
    type: FETCH_MENU_INFORMES,
  });

  // const refrescar = refreshToken(dispatch);
  axios
    .get("/menuinformes/", tokenConfig(getState))
    .then((res) => {
      dispatch({
        type: GET_MENU_INFORMES,
        payload: res.data,
      });
    })
    .catch((err) => {
      console.log(err);
      //
      // if (err.response.status === 401) {
      //   refrescar();
      // }
    });
};

// GET FILTROS Y PLUGINS INFORME
export const getFiltrosPluginsInforme = (informe) => (dispatch, getState) => {
  // const refrescar = refreshToken(dispatch);

  dispatch({
    type: SET_INFORME_SELECCIONADO,
    payload: informe,
  });

  axios
    .get(`/filtrospluginsinformes/?informe=${informe}`, tokenConfig(getState))
    .then((res) => {
      dispatch({
        type: GET_FILTROS_PLUGINS_INFORME,
        payload: res.data["respuesta"],
      });
    })
    .catch((err) => {
      console.log(err);
      //
      // if (err.response.status === 401) {
      //   refrescar();
      // }
    });
};

// GET DATOS DEL INFORME INFORME
export const getDatosInforme = (informe, filtros, tipo_salida = "") => (
  dispatch,
  getState
) => {
  const body = JSON.stringify({ informe, filtros, tipo_salida });

  dispatch({
    type: GET_DATOS_INFORME,
  });

  axios
    .post("/datosinformes/", body, tokenConfig(getState))
    .then((res) => {
      console.log(res.data.respuesta);
      dispatch({
        type: SET_DATOS_INFORME,
        payload: res.data.respuesta,
      });
    })
    .catch((err) => console.log(err));
};

// GET DATOS DEL INFORME INFORME
export const getMasDatosInforme = (informe, filtros, tipo_salida = "") => (
  dispatch,
  getState
) => {
  const body = JSON.stringify({ informe, filtros, tipo_salida });

  dispatch({
    type: GET_MAS_DATOS_INFORME,
  });

  axios
    .post("/datosinformes/", body, tokenConfig(getState))
    .then((res) => {
      dispatch({
        type: SET_MAS_DATOS_INFORME,
        payload: res.data.respuesta,
      });
    })
    .catch((err) => console.log(err));
};

export const obtenerEFactura = (ejercicio, numero_serie, numero_factura) => (
  dispatch,
  getState
) => {
  const body = JSON.stringify({ ejercicio, numero_serie, numero_factura });

  dispatch({
    type: OBTENER_EFACTURA,
  });

  axios
    .post("/obtnerefactura/", body, tokenConfig(getState))
    .then((res) => {
      if (Array.isArray(res.data.registros)) {
        for (var i = 0; i < res.data.registros.length; i++) {
          var registro = res.data.registros[i];

          descargarFichero(registro.nombre_archivo, registro.archivo);
        }
      } else {
        descargarFichero(
          res.data.registros.registro.nombre_archivo,
          res.data.registros.registro.archivo
        );
      }

      dispatch({
        type: DESCARGA_EFACTURA,
      });
    })
    .catch((err) => console.log(err));
};

export const obtenerReport = (modulo, parametros) => (dispatch, getState) => {
  const body = JSON.stringify({ modulo, parametros });

  dispatch({
    type: OBTENER_REPORT,
  });

  // const refrescar = refreshToken(dispatch);
  axios
    .post("/obtnerreport/", body, tokenConfig(getState))
    .then((res) => {
      descargarFichero(res.data.nombre, res.data.fichero);

      dispatch({
        type: DESCARGA_REPORT,
      });
    })
    .catch((err) => {
      console.log(err);
      dispatch({
        type: DESCARGA_REPORT,
      });
      //
      // if (err.response.status === 401) {
      //   refrescar();
      // }
    });
};

// GET DATOS DEL INFORME INFORME
export const imprimirInforme = (informe, filtros, tipo_salida = "") => (
  dispatch,
  getState
) => {
  const body = JSON.stringify({ informe, filtros, tipo_salida });

  let nombre;

  if (tipo_salida === "XLS") {
    nombre = informe + ".xls";
  } else if (tipo_salida === "HTML") {
    nombre = informe + ".html";
  } else {
    nombre = informe + ".pdf";
  }

  dispatch({
    type: OBTENER_REPORT,
  });

  axios
    .post("/datosinformes/", body, tokenConfig(getState))
    .then((res) => {
      descargarFichero(nombre, res.data.respuesta.fichero, tipo_salida);
      dispatch({
        type: DESCARGA_REPORT,
      });
    })
    .catch((err) => console.log(err));
};

export const getLov = (informe, lista_valores) => (dispatch, getState) => {
  axios
    .get(
      `/lovs/?informe=${informe}&lista_valores=${lista_valores}`,
      tokenConfig(getState)
    )
    .then((res) => {
      console.log(res.data);
      dispatch({
        type: SET_LISTA_VALORES,
        payload: res.data,
        lista_valores: lista_valores,
      });
    })
    .catch((err) => console.log(err));
};
