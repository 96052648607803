import axios from "../axiosConfig";

import {
  GET_ARTICULOS,
  FETCH_ARTICULOS,
  GET_MAS_ARTICULOS,
  CHANGE_ARTICULOS_PAGINA,
  SET_BUSQUEDA,
  CHANGE_ARTICULOS_ORDEN,
} from "./types";

import { tokenConfig } from "./auth";

// GET ARTICULOS
export const getArticulos = (
  search = "",
  limit = 12,
  order = "",
  hash = ""
) => (dispatch, getState) => {
  dispatch({
    type: FETCH_ARTICULOS,
  });
  axios
    .get(
      `/articulos/?search=${search}&limit=${limit}&order=${order}&inv=${hash}`,
      tokenConfig(getState)
    )
    .then((res) => {
      //Añado el término de búsqueda a la respuesta
      //para poder guardarlo en el estado dentro del reducer
      res.data.search = search;
      res.data.articulos_pagina = limit;
      res.data.articulos_orden = order;

      dispatch({
        type: GET_ARTICULOS,
        payload: res.data,
      });
    })
    .catch((err) => console.log(err));
};

export const getArticulosByFamily = (
  filtros_aplicados,
  limit = "",
  order = "",
  search = "",
  hash = ""
) => (dispatch, getState) => {
  dispatch({
    type: FETCH_ARTICULOS,
  });

  axios
    .post(
      `/articulos/listByFamily/?search=${search}&limit=${limit}&order=${order}&inv=${hash}`,
      {
        filtros: filtros_aplicados,
      },
      tokenConfig(getState)
    )
    .then((res) => {
      res.data.search = search;
      res.data.articulos_pagina = limit;
      res.data.articulos_orden = order;
      dispatch({
        type: GET_ARTICULOS,
        payload: res.data,
      });
    })
    .catch((err) => console.log(err));
};

// GET MÁS ARTÍCULOS
export const getMasArticulos = (
  url,
  filtros_aplicados = [],
  origen = "articulos"
) => (dispatch, getState) => {
  dispatch({
    type: FETCH_ARTICULOS,
  });

  if (filtros_aplicados.length === 0) {
    axios
      .get(url, tokenConfig(getState))
      .then((res) => {
        dispatch({
          type: GET_MAS_ARTICULOS,
          payload: res.data,
          origen: origen,
        });
      })
      .catch((err) => console.log(err));
  } else {
    axios
      .post(
        url,
        {
          filtros: filtros_aplicados,
        },
        tokenConfig(getState)
      )
      .then((res) => {
        dispatch({
          type: GET_MAS_ARTICULOS,
          payload: res.data,
          origen: origen,
        });
      })
      .catch((err) => console.log(err));
  }
};

export const cambiaArticulosPagina = (art_pagina) => (dispatch, getState) => {
  dispatch({
    type: CHANGE_ARTICULOS_PAGINA,
    payload: art_pagina,
  });
};

export const setBusqueda = (search) => (dispatch, getState) => {
  dispatch({
    type: SET_BUSQUEDA,
    payload: search,
  });
};

export const cambiaArticulosOrden = (orden) => (dispatch, getState) => {
  dispatch({
    type: CHANGE_ARTICULOS_ORDEN,
    payload: orden,
  });
};
