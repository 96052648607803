import axios from "../axiosConfig";

import {
  FETCH_FORMAS_PAGO,
  GET_FORMAS_PAGO,
  SET_FORMA_PAGO,
} from "../actions/types.js";
import { tokenConfig } from "./auth";

// GET ARTICULOS
export const getFormasPago = () => (dispatch, getState) => {
  dispatch({
    type: FETCH_FORMAS_PAGO,
    payload: true,
  });
  axios
    .get(`/formaspago/`, tokenConfig(getState))
    .then((res) => {
      dispatch({
        type: GET_FORMAS_PAGO,
        payload: res.data,
      });
    })
    .catch((err) => console.log(err));
};

export const setFormaPago = (forma_pago) => (dispatch) => {
  dispatch({
    type: SET_FORMA_PAGO,
    payload: forma_pago,
  });
};
