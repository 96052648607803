import axios from "../../axiosConfig";

import { Document, Page, pdfjs } from "react-pdf";

import { b64DecodeUnicode } from "../../helpers/funciones";

import React, { Component } from "react";
import ReactMarkdown from "react-markdown";

export class Terminos extends Component {
  state = {
    componente: null,
    contenido: "",
    tipo: "",
    html: "",
    numPages: null,
    pageNumber: 1,
  };

  componentWillMount() {
    

    const componente =
      this.props.match !== undefined
        ? this.props.match.params.componente
        : this.props.componente;

    if (this.state.componente !== componente) {
      this.setState({
        ...this.state,
        componente: componente,
        contenido: "",
        tipo: "",
        html: "",
        numPages: null,
        pageNumber: 1,
      });
    }
  }

  componentDidMount() {
    this.cargarContenido();
  }

  componentDidUpdate() {
    this.cargarContenido();
  }

  cargarContenido() {
    

    const componente =
      this.props.match !== undefined
        ? this.props.match.params.componente
        : this.props.componente;

    if (this.state.componente !== componente) {
      this.setState({
        ...this.state,
        componente: componente,
        contenido: "",
        tipo: "",
        html: "",
        numPages: null,
        pageNumber: 1,
      });
    } else if (this.state.contenido === "" && this.state.html === "") {
      const { componente } = this.state;

      if (
        pdfjs.GlobalWorkerOptions.workerSrc !==
        `https://cdnjs.cloudflare.com/ajax/libs/pdf.js/${pdfjs.version}/pdf.worker.js`
      ) {
        pdfjs.GlobalWorkerOptions.workerSrc = `https://cdnjs.cloudflare.com/ajax/libs/pdf.js/${pdfjs.version}/pdf.worker.js`;
      }

      axios
        .get(`/estaticos?componente=${componente}`)
        .then((res) => {
          let estatico = res.data[0];

          

          if (estatico.tipo === "MD") {
            estatico.contenido = b64DecodeUnicode(estatico.contenido);
          } else if (estatico.tipo === "PDF") {
            estatico.contenido = atob(estatico.contenido);
          } else if (estatico.tipo === "HTML") {
            //estatico.contenido = b64DecodeUnicode(estatico.contenido);
            estatico.html = estatico.html;
          }

          this.setState({
            contenido: estatico.contenido,
            html: estatico.html,
            tipo: estatico.tipo,
          });
        })
        .catch((err) => {
          console.log(err);

          

          this.setState({
            contenido: "",
            tipo: "",
            html: "",
            numPages: null,
            pageNumber: 1,
          });
        });
    }
  }

  onDocumentLoadSuccess = ({ numPages }) => {
    if (this.state.numPages === null) {
      this.setState({ numPages });
    }
  };

  siguientePagina = (e) => {
    e.preventDefault();
    if (this.state.pageNumber + 1 <= this.state.numPages) {
      this.setState({ pageNumber: this.state.pageNumber + 1 });
    }
  };

  anteriorPagina = (e) => {
    e.preventDefault();
    if (this.state.pageNumber - 1 > 0) {
      this.setState({ pageNumber: this.state.pageNumber - 1 });
    }
  };

  seleccionarContenido(tipo, contenido, html) {
    

    switch (tipo) {
      case "MD":
        return <ReactMarkdown className="mt-5" source={contenido} />;
      case "PDF":
        return (
          <div className="estatico-centro">
            <div className="mt-5">
              <a
                href="/#"
                className="btn-link mr-2"
                onClick={this.anteriorPagina}
              >
                Anterior
              </a>
              <span>
                Página {this.state.pageNumber} de {this.state.numPages}
              </span>
              <a
                href="/#"
                className="btn-link ml-2"
                onClick={this.siguientePagina}
              >
                Siguiente
              </a>
            </div>
            <Document
              file={{ data: contenido }}
              onLoadSuccess={this.onDocumentLoadSuccess}
              loading="Cargando..."
            >
              <Page pageNumber={this.state.pageNumber} />
            </Document>
          </div>
        );
      case "HTML":
        return <div dangerouslySetInnerHTML={{ __html: html }}></div>;
      default:
        return "";
    }
  }

  render() {
    const { contenido, tipo, html } = this.state;
    return (
      <div className="container">
        <div id="tt-pageContent">
          {this.seleccionarContenido(tipo, contenido, html)}
        </div>
      </div>
    );
  }
}

export default Terminos;
