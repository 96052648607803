import React, { Component, Fragment } from "react";
import { withAlert } from "react-alert";
import { connect } from "react-redux";
import PropTypes from "prop-types";

export class Alerts extends Component {
  static propTypes = {
    error: PropTypes.object.isRequired,
    message: PropTypes.object.isRequired,
  };

  componentDidUpdate(prevProps) {
    const { error, alert, message } = this.props;
    if (error !== prevProps.error) {
      if (error.msg.descripcion)
        alert.error(`Descripción: ${error.msg.descripcion.join()}`);
      if (error.msg.importe)
        alert.error(`Importe: ${error.msg.importe.join()}`);
      if (error.msg.fecha) alert.error(`Fecha: ${error.msg.fecha.join()}`);
      if (error.msg.non_field_errors)
        alert.error(error.msg.non_field_errors.join());
      if (error.msg.username) alert.error(error.msg.username.join());
      alert.error(error.msg);
    }
    if (message !== prevProps.message) {
      if (message.pedidoIntegrado) alert.success(message.pedidoIntegrado);
      if (message.pedidoEliminado) alert.success(message.pedidoEliminado);
      if (message.noDomEnvio) alert.error(message.noDomEnvio);
      if (message.passNotMatch) alert.error(message.passNotMatch);
      if (message.passCambiada) alert.success(message.passCambiada);
      if (message.recuperarPass) alert.success(message.recuperarPass);
      if (message.lineaEliminada) alert.success(message.lineaEliminada);
      if (message.lineaAnadida) alert.success(message.lineaAnadida);
      if (message.lineaListaEliminada)
        alert.success(message.lineaListaEliminada);
      if (message.listaEliminada) alert.success(message.listaEliminada);
      if (message.expedienteOK) alert.success(message.expedienteOK);
      if (message.expedienteKO) alert.error(message.expedienteKO);
      if (message.aceptar_registro) alert.error(message.aceptar_registro);
    }
  }

  render() {
    return <Fragment />;
  }
}

const mapStateToProps = (state) => ({
  error: state.errors,
  message: state.messages,
});

export default connect(mapStateToProps)(withAlert()(Alerts));
