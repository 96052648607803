import React, { Component } from "react";
import { Link, Redirect } from "react-router-dom";
import { connect } from "react-redux";
import PropTypes from "prop-types";
import { enviarRegistro } from "../../actions/auth";
import { ordenarMenu } from "../../helpers/funciones";
import { createMessage } from "../../actions/messages";

import "../../css/registro.css";

export class Registro extends Component {
  state = {
    nombre: "",
    cliente: "",
    mensaje: "",
    email: "",
    usuario_web: "",
    terminos_condiciones: false,
    politica_cookies: false,
    politica_privacidad: false,
  };

  static propTypes = {
    enviarRegistro: PropTypes.func.isRequired,
    isAuthenticated: PropTypes.bool,
    portal: PropTypes.object.isRequired,
    menus: PropTypes.array.isRequired,
    hash: PropTypes.string,
    invitado: PropTypes.bool,
    createMessage: PropTypes.func.isRequired,
  };

  onSubmit = (e) => {
    e.preventDefault();

    if (!this.state.terminos_condiciones) {
      this.props.createMessage({
        aceptar_registro: "Debe aceptar los terminos y condiciones de uso.",
      });
    } else if (!this.state.politica_cookies) {
      this.props.createMessage({
        aceptar_registro: "Debe aceptar la política de cookies.",
      });
    } else if (!this.state.politica_privacidad) {
      this.props.createMessage({
        aceptar_registro: "Debe aceptar la política de privacidad.",
      });
    } else {
      this.props.enviarRegistro(
        this.state.nombre,
        this.state.cliente,
        this.state.mensaje,
        this.state.email,
        this.state.usuario_web
      );
      this.setState({
        nombre: "",
        cliente: "",
        mensaje: "",
        email: "",
        usuario_web: "",
      });
    }
  };

  onChange = (e) => this.setState({ [e.target.name]: e.target.value });

  render() {
    const { nombre, cliente, mensaje, email, usuario_web } = this.state;
    const { portal } = this.props;
    const menus = this.props.menus;

    if (menus !== undefined && menus.length > 0) {
      menus.sort(ordenarMenu);

      if (this.props.isAuthenticated && this.props.invitado === false) {
        const irA = menus.find(
          (menu) => menu.activo === "S" && menu.programa !== null
        );

        if (irA !== undefined && irA !== null) {
          return <Redirect to={"/" + irA.programa} />;
        } else {
          return "";
        }
      }
    }

    return (
      <div id="tt-pageContent" className="registro">
        <div class="container-indent">
          <div className="container">
            <a className="tt-logo" href="index.html">
              <img className="tt-retina" src="images/custom/logo.png" alt="" />
            </a>
            <h1 className="tt-title-subpages noborder">{portal.descripcion}</h1>
            <div class="tt-login-form">
              <div className="row	justify-content-center">
                <div className="col-md-5">
                  <div class="tt-item">
                    <h2 className="tt-title">FORMULARIO DE REGISTRO</h2>
                    <div className="form-default justify-content-center">
                      <form onSubmit={this.onSubmit}>
                        <div className="form-group">
                          <label htmlFor="input_nombre">Nombre</label>
                          <input
                            className="form-control"
                            type="text"
                            name="nombre"
                            id="input_nombre"
                            onChange={this.onChange}
                            value={nombre}
                            placeholder="Nombre, establecimiento o empresa"
                            required
                          />
                        </div>
                        <div className="form-group">
                          <label htmlFor="input_cliente">
                            Código de cliente
                          </label>
                          <input
                            className="form-control"
                            type="text"
                            name="cliente"
                            id="input_cliente"
                            onChange={this.onChange}
                            value={cliente}
                            placeholder="Podrá encontrarlo en albaranes, facturas o presupuestos"
                            required
                          />
                        </div>
                        <div className="form-group">
                          <label htmlFor="input_email">Email</label>
                          <input
                            className="form-control"
                            type="text"
                            name="email"
                            id="input_email"
                            onChange={this.onChange}
                            value={email}
                            placeholder="Email de contacto"
                            required
                          />
                        </div>
                        <div className="form-group">
                          <label htmlFor="input_usuario_web">Usuario web</label>
                          <input
                            className="form-control"
                            type="text"
                            name="usuario_web"
                            id="input_usuario_web"
                            onChange={this.onChange}
                            value={usuario_web}
                            placeholder="Usuario web que le gustaría usar"
                            required
                          />
                        </div>
                        <div className="form-group">
                          <label htmlFor="input_mensaje">Mensaje</label>
                          <textarea
                            className="form-control"
                            name="mensaje"
                            id="input_mensaje"
                            onChange={this.onChange}
                            value={mensaje}
                            placeholder="Escriba un mensaje"
                            required
                          />
                        </div>

                        <div class="form-check">
                          <input
                            type="checkbox"
                            class="form-check-input"
                            id="terminos_condiciones"
                            name="terminos_condiciones"
                            onChange={this.onChange}
                            checked={this.state.terminos_condiciones}
                          />
                          <label
                            class="form-check-label"
                            for="terminos_condiciones"
                          >
                            He leído y acepto los términos y condicines de uso
                          </label>
                        </div>
                        <div class="form-check">
                          <input
                            type="checkbox"
                            class="form-check-input"
                            id="politica_cookies"
                            name="politica_cookies"
                            onChange={this.onChange}
                            checked={this.state.politica_cookies}
                          />
                          <label
                            class="form-check-label"
                            for="politica_cookies"
                          >
                            He leído y acepto la política de cookies
                          </label>
                        </div>
                        <div class="form-check">
                          <input
                            type="checkbox"
                            class="form-check-input"
                            id="politica_privacidad"
                            name="politica_privacidad"
                            onChange={this.onChange}
                            checked={this.state.politica_privacidad}
                          />
                          <label
                            class="form-check-label"
                            for="politica_privacidad"
                          >
                            He leído y acepto la política de privacidad
                          </label>
                        </div>

                        <div class="row">
                          <div class="col-auto mr-auto">
                            <div class="form-group">
                              <button type="submit" className="btn btn-primary">
                                Enviar
                              </button>
                            </div>
                          </div>
                        </div>
                        <p>
                          ¿Ya tiene usuario?{" "}
                          <Link to="/login">Acceda al sitio</Link>
                        </p>
                      </form>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }
}

const mapStateToProps = (state) => ({
  isAuthenticated: state.auth.isAuthenticated,
  hash: state.auth.hash,
  invitado: state.auth.invitado,
  portal: state.portal.portal,
  menus: state.portal.menus,
});

export default connect(mapStateToProps, { enviarRegistro, createMessage })(
  Registro
);
