import {
  GET_ARTICULOS,
  FETCH_ARTICULOS,
  GET_MAS_ARTICULOS,
  GET_OFERTAS,
  GET_NOVEDADES,
  GET_PEDIDO_HABITUAL,
  GET_RELACIONADOS,
  CHANGE_ARTICULOS_PAGINA,
  SET_BUSQUEDA,
  CHANGE_ARTICULOS_ORDEN,
} from "../actions/types.js";

const initialState = {
  articulos: {
    articulos: [],
    articulos_total: 0,
    articulos_siguiente: "",
    articulos_anterior: "",
    filtros_aplicables: [],
  },
  ofertas: {
    articulos: [],
    articulos_total: 0,
    articulos_siguiente: "",
    articulos_anterior: "",
  },
  novedades: {
    articulos: [],
    articulos_total: 0,
    articulos_siguiente: "",
    articulos_anterior: "",
  },
  pedido_habitual: {
    articulos: [],
    articulos_total: 0,
    articulos_siguiente: "",
    articulos_anterior: "",
  },
  relacionados: {
    articulos: [],
    articulos_total: 0,
    articulos_siguiente: "",
    articulos_anterior: "",
  },
  articulos_pagina: 12,
  articulos_search: "",
  articulos_orden: "",
  isLoading: false,
};

export default function (state = initialState, action) {
  switch (action.type) {
    case FETCH_ARTICULOS:
      return {
        ...state,
        isLoading: true,
      };
    case GET_ARTICULOS:
      let articulos_nuevo = JSON.parse(JSON.stringify(state.articulos));
      articulos_nuevo.articulos = action.payload.results;
      articulos_nuevo.articulos_total = action.payload.count;
      articulos_nuevo.articulos_siguiente = action.payload.next;
      articulos_nuevo.articulos_anterior = action.payload.previous;
      articulos_nuevo.filtros_aplicables = action.payload.filtros_aplicables;
      return {
        ...state,
        articulos: articulos_nuevo,
        articulos_search: action.payload.search,
        articulos_pagina: action.payload.articulos_pagina,
        articulos_orden: action.payload.articulos_orden,
        isLoading: false,
      };
    case GET_PEDIDO_HABITUAL:
      let pedido_habitual_nuevo = JSON.parse(
        JSON.stringify(state.pedido_habitual)
      );
      pedido_habitual_nuevo.articulos = action.payload.results;
      pedido_habitual_nuevo.articulos_total = action.payload.count;
      pedido_habitual_nuevo.articulos_siguiente = action.payload.next;
      pedido_habitual_nuevo.articulos_anterior = action.payload.previous;
      return {
        ...state,
        pedido_habitual: pedido_habitual_nuevo,
        articulos_search: "",
        articulos_pagina: 12,
        articulos_orden: "",
        isLoading: false,
      };

    case GET_NOVEDADES:
      let novedades_nuevo = JSON.parse(JSON.stringify(state.novedades));
      novedades_nuevo.articulos = action.payload.results;
      novedades_nuevo.articulos_total = action.payload.count;
      novedades_nuevo.articulos_siguiente = action.payload.next;
      novedades_nuevo.articulos_anterior = action.payload.previous;
      return {
        ...state,
        novedades: novedades_nuevo,
        articulos_search: "",
        articulos_pagina: 12,
        articulos_orden: "",
        isLoading: false,
      };
    case GET_OFERTAS:
      let ofertas_nuevo = JSON.parse(JSON.stringify(state.ofertas));
      ofertas_nuevo.articulos = action.payload.results;
      ofertas_nuevo.articulos_total = action.payload.count;
      ofertas_nuevo.articulos_siguiente = action.payload.next;
      ofertas_nuevo.articulos_anterior = action.payload.previous;
      return {
        ...state,
        ofertas: ofertas_nuevo,
        articulos_search: "",
        articulos_pagina: 12,
        articulos_orden: "",
        isLoading: false,
      };
    case GET_RELACIONADOS:
      let relacionados_nuevo = JSON.parse(JSON.stringify(state.relacionados));
      relacionados_nuevo.articulos = action.payload.results;
      relacionados_nuevo.articulos_total = action.payload.count;
      relacionados_nuevo.articulos_siguiente = action.payload.next;
      relacionados_nuevo.articulos_anterior = action.payload.previous;
      return {
        ...state,
        relacionados: relacionados_nuevo,
        articulos_search: "",
        articulos_pagina: 12,
        articulos_orden: "",
        isLoading: false,
      };
    case GET_MAS_ARTICULOS:
      let mas_articulos;
      switch (action.origen) {
        case "articulos":
          mas_articulos = JSON.parse(JSON.stringify(state.articulos));
          mas_articulos.articulos = [
            ...mas_articulos.articulos,
            ...action.payload.results,
          ];
          mas_articulos.articulos_siguiente = action.payload.next;
          mas_articulos.articulos_anterior = action.payload.previous;
          return {
            ...state,
            articulos: mas_articulos,
            isLoading: false,
          };
        case "pedido_habitual":
          mas_articulos = JSON.parse(JSON.stringify(state.pedido_habitual));
          mas_articulos.articulos = [
            ...mas_articulos.articulos,
            ...action.payload.results,
          ];
          mas_articulos.articulos_siguiente = action.payload.next;
          mas_articulos.articulos_anterior = action.payload.previous;
          return {
            ...state,
            pedido_habitual: mas_articulos,
            isLoading: false,
          };
        case "novedades":
          mas_articulos = JSON.parse(JSON.stringify(state.novedades));
          mas_articulos.articulos = [
            ...mas_articulos.articulos,
            ...action.payload.results,
          ];
          mas_articulos.articulos_siguiente = action.payload.next;
          mas_articulos.articulos_anterior = action.payload.previous;
          return {
            ...state,
            novedades: mas_articulos,
            isLoading: false,
          };
        case "ofertas":
          mas_articulos = JSON.parse(JSON.stringify(state.ofertas));
          mas_articulos.articulos = [
            ...mas_articulos.articulos,
            ...action.payload.results,
          ];
          mas_articulos.articulos_siguiente = action.payload.next;
          mas_articulos.articulos_anterior = action.payload.previous;
          return {
            ...state,
            ofertas: mas_articulos,
            isLoading: false,
          };
      }
      return {
        ...state,
        isLoading: false,
      };

    case SET_BUSQUEDA:
      return {
        ...state,
        articulos_search: action.payload,
      };
    case CHANGE_ARTICULOS_PAGINA:
      return {
        ...state,
        articulos_pagina: action.payload,
      };
    case CHANGE_ARTICULOS_ORDEN:
      return {
        ...state,
        articulos_orden: action.payload,
      };
    default:
      return state;
  }
}
