import React, { Component, Fragment } from "react";
import { connect } from "react-redux";
import PropTypes from "prop-types";
import { getArticulosByFamily, getArticulos } from "../../actions/articulos";
import {
  añadirFiltro,
  limpiarFiltros,
  quitarFiltro,
} from "../../actions/filtros";

export class ArticulosFiltros extends Component {
  static propTypes = {
    filtros: PropTypes.array.isRequired,
    filtros_aplicados: PropTypes.array.isRequired,
    articulos_search: PropTypes.string,
    articulos_orden: PropTypes.string,
    getArticulosByFamily: PropTypes.func.isRequired,
    añadirFiltro: PropTypes.func.isRequired,
    limpiarFiltros: PropTypes.func.isRequired,
    quitarFiltro: PropTypes.func.isRequired,
    getArticulos: PropTypes.func.isRequired,
    filtros_aplicables: PropTypes.array.isRequired,
    auth: PropTypes.object.isRequired,
  };

  componentDidUpdate(prevProps) {
    if (this.props.filtros_aplicados !== prevProps.filtros_aplicados) {
      if (this.props.filtros_aplicados.length > 0) {
        this.props.getArticulosByFamily(
          this.props.filtros_aplicados,
          this.props.articulos_pagina,
          this.props.articulos_orden,
          this.props.articulos_search,
          this.props.auth.hash != null ? this.props.auth.hash : ""
        );
      } else {
        this.props.getArticulos(
          this.props.articulos_search,
          this.props.articulos_pagina,
          this.props.articulos_orden,
          this.props.auth.hash != null ? this.props.auth.hash : ""
        );
      }
    }
  }

  onClick = (e) => {
    e.preventDefault();

    let _estadistico = e.target.dataset.estadistico;
    let _familia = e.target.dataset.codigo;
    let _nombre = e.target.dataset.familia;

    let findPos = (elem) =>
      elem.estadistico === _estadistico && elem.familia === _familia;

    if (this.props.filtros_aplicados.findIndex(findPos) === -1) {
      this.props.añadirFiltro({
        estadistico: _estadistico,
        familia: _familia,
        nombre: _nombre,
      });
    }
  };

  eliminarFiltros = (e) => {
    e.preventDefault();
    this.props.limpiarFiltros();
  };

  eliminarFiltro = (e) => {
    e.preventDefault();
    let _estadistico = e.target.dataset.estadistico;
    let _familia = e.target.dataset.codigo;
    let _nombre = e.target.dataset.familia;
    this.props.quitarFiltro({
      estadistico: _estadistico,
      familia: _familia,
      nombre: _nombre,
    });
    this.forceUpdate();
  };

  render() {
    return (
      <Fragment>
        <div className="col-md-4 col-lg-3 col-xl-3 leftColumn aside">
          <div className="tt-btn-col-close">
            <a href="/#">Cerrar</a>
          </div>
          <div className="tt-collapse open tt-filter-detach-option">
            <div className="tt-collapse-content">
              <div className="filters-mobile">
                <div className="filters-row-select"></div>
              </div>
            </div>
          </div>
          {this.props.filtros_aplicados.length === 0 ? (
            ""
          ) : (
            <div className="tt-collapse open ">
              <h3 className="tt-collapse-title">FILTROS ACTIVOS</h3>
              <div className="tt-collapse-content">
                <ul className="tt-filter-list">
                  {this.props.filtros_aplicados === undefined
                    ? ""
                    : this.props.filtros_aplicados.map((filtro, index) => {
                        return (
                          <li className="active" key={index}>
                            <a
                              href="/#"
                              data-estadistico={filtro.estadistico}
                              data-codigo={filtro.familia}
                              onClick={this.eliminarFiltro}
                            >
                              {filtro.nombre}
                            </a>
                          </li>
                        );
                      })}
                </ul>
                <a
                  href="/#"
                  className="btn-link-02"
                  onClick={this.eliminarFiltros}
                >
                  Limpiar todos
                </a>
              </div>
            </div>
          )}
          {this.props.filtros === undefined
            ? ""
            : this.props.filtros.map((filtro, index) => {
                if (
                  this.props.filtros_aplicables.some(
                    (filtro_aplicable) =>
                      (filtro_aplicable.estadistico = filtro.estadistico)
                  )
                ) {
                  return (
                    <div key={index} className="tt-collapse open ">
                      <h3 className="tt-collapse-title">{filtro.nombre}</h3>
                      <div className="tt-collapse-content">
                        <ul className="tt-list-row">
                          {filtro.familias === undefined
                            ? ""
                            : filtro.familias.map((familia, index) => {
                                if (
                                  this.props.filtros_aplicables.some(
                                    (filtro_aplicable) =>
                                      filtro_aplicable.estadistico ==
                                        filtro.estadistico &&
                                      filtro_aplicable.familias.some(
                                        (familia_aplicable) =>
                                          familia_aplicable ==
                                          familia.codigo_familia
                                      )
                                  )
                                ) {
                                  return (
                                    <li key={index} className="">
                                      <a
                                        href="/#"
                                        data-familia={familia.descripcion}
                                        data-estadistico={familia.numero_tabla}
                                        data-codigo={familia.codigo_familia}
                                        onClick={this.onClick}
                                      >
                                        {familia.descripcion}
                                      </a>
                                    </li>
                                  );
                                } else {
                                  return "";
                                }
                              })}
                        </ul>
                      </div>
                    </div>
                  );
                } else {
                  return "";
                }
              })}
        </div>
      </Fragment>
    );
  }
}

const mapStateToProps = (state) => ({
  filtros: state.articulos.filtros,
  filtros_aplicados: state.articulos.filtros_aplicados,
  articulos_search: state.listadoArticulos.articulos_search,
  articulos_pagina: state.listadoArticulos.articulos_pagina,
  articulos_orden: state.listadoArticulos.articulos_orden,
  filtros_aplicables: state.listadoArticulos.articulos.filtros_aplicables,
  auth: state.auth,
});

export default connect(mapStateToProps, {
  getArticulosByFamily,
  añadirFiltro,
  limpiarFiltros,
  quitarFiltro,
  getArticulos,
})(ArticulosFiltros);
