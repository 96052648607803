import React, { Component, Fragment } from "react";
import { connect } from "react-redux";
import PropTypes from "prop-types";
import { getTirasDet } from "../../actions/tiras";

import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

export class Tira extends Component {
  static propTypes = {
    tiras: PropTypes.array.isRequired,
    contenido: PropTypes.array.isRequired,
    getTirasDet: PropTypes.func.isRequired,
  };

  componentDidMount() {
    const contenido = this.props.contenido.filter(
      (c) => c.id_tira === this.props.id_tira
    );

    if (contenido.length === 0) {
      this.props.getTirasDet(this.props.id_tira);
    }
  }

  componentDidUpdate(prevProps, prevState) {
    window.wookie();
  }

  render() {
    const id_tira = this.props.id_tira;

    const tira = this.props.tiras.filter((t) => t.id_tira === id_tira)[0];

    const contenido = this.props.contenido.filter((c) => c.id_tira === id_tira);

    console.log("tira: ", tira);
    console.log("contenido: ", contenido);

    if (tira !== undefined && tira !== null) {
      const estilo = {
        marginTop: "-8px",
      };

      if (tira.color !== null && tira.color !== "") {
        estilo["backgroundColor"] = `#${tira.color}`;
      }

      return (
        <div className="tira" style={estilo}>
          {contenido.map((caja) => {
            const estiloCaja = {};
            if (caja.color !== null && caja.color !== "") {
              estiloCaja["color"] = `#${caja.color}`;
            }
            return (
              <div key={caja.id} className="tira_caja">
                {caja.icono !== null && caja.icono !== "" ? (
                  <div className="tira_icono" style={estiloCaja}>
                    <FontAwesomeIcon icon={caja.icono} />
                  </div>
                ) : caja.imagen !== null && caja.imagen !== "" ? (
                  <div className="tira_imagen">
                    <img src={`data:image/png;base64,${caja.imagen}`} />
                  </div>
                ) : (
                  ""
                )}
                <div className="tira_titulo" style={estiloCaja}>
                  {caja.titulo}
                </div>
                <div className="tira_texto">{caja.texto}</div>
              </div>
            );
          })}
        </div>
      );
    } else {
      return <Fragment />;
    }
  }
}

const mapStateToProps = (state) => ({
  tiras: state.tiras.tiras,
  contenido: state.tiras.contenido,
});

export default connect(mapStateToProps, {
  getTirasDet,
})(Tira);
