import React, { Component, Fragment } from "react";
import { connect } from "react-redux";
import PropTypes from "prop-types";
import { getBanners } from "../../actions/banners";
import Slider from "react-slick";
import ImagenBanner from "./ImagenBanner";

import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import "../../css/galeria.css";
import "../../css/banners.css";

export class Banners extends Component {
  static propTypes = {
    banners: PropTypes.array.isRequired,
    getBanners: PropTypes.func.isRequired,
  };

  componentDidMount() {
    this.props.getBanners();
  }

  componentDidUpdate(prevProps, prevState) {
    window.wookie();
    let elementos = document.getElementById("root").children;
    let alturaOcupada = 0;

    for (let i = 0; i < elementos.length; i++) {
      let elemento = elementos[i];
      if (elemento.className === "banners") {
        break;
      } else {
        let estilos = window.getComputedStyle(elemento);
        if (estilos.position !== "fixed" && estilos.position !== "absolute") {
          alturaOcupada += parseFloat(elemento.offsetHeight);
        }
      }
    }

    let alturaPantalla = window.innerHeight;
    this.alturaBanners = alturaPantalla - alturaOcupada;
  }

  render() {
    let findPos = (elem) => elem.id_banner === this.props.id_banner;
    let pos = this.props.banners.findIndex(findPos);

    const banner = this.props.banners[pos];
    const imagenes = banner !== undefined ? banner.imagenes : [];
    const alturaBanners = this.alturaBanners;

    return (
      <Fragment>
        {banner !== undefined ? (
          <div
            className="banners"
            style={
              banner.completo === "S"
                ? {
                    height: alturaBanners,
                    minHeight: alturaBanners,
                  }
                : {
                    height: "auto",
                  }
            }
          >
            <div
              className="banner"
              style={{
                width: banner.ancho + "%",
                height: "auto",
              }}
            >
              <div className="container-indent">
                <Slider
                  slidesToShow={1}
                  slidesToScroll={1}
                  lazyLoad={true}
                  infinite={true}
                  ref={(slider) => (this.slider1 = slider)}
                  autoplay={banner.autoplay === "S" ? true : false}
                  speed={400}
                  autoplaySpeed={banner.intervalo}
                  cssEase="linear"
                >
                  {imagenes !== undefined
                    ? imagenes.map((imagen) => (
                        <div key={imagen.id} className="slide">
                          <a href={imagen.url}>
                            <ImagenBanner
                              id_banner={imagen.id_banner}
                              numero_imagen={imagen.orden}
                            />
                          </a>
                          <div className="slide-caption">
                            <h3>{imagen.titulo}</h3>
                            <p>{imagen.texto}</p>
                          </div>
                        </div>
                      ))
                    : ""}
                </Slider>
              </div>
            </div>
          </div>
        ) : (
          ""
        )}
      </Fragment>
    );
  }
}

const mapStateToProps = (state) => ({
  banners: state.banners.banners,
});

export default connect(mapStateToProps, {
  getBanners,
})(Banners);
