import React, { Component, Fragment } from "react";
import { BrowserRouter as Router } from "react-router-dom";

import { Provider as AlertProvider } from "react-alert";
import AlertTemplate from "react-alert-template-basic";

import { loadUser } from "./actions/auth";
import { getPortal, getMenus } from "./actions/portal";
import { showLoader } from "./actions/loader";

import "./App.css";

import { Provider } from "react-redux";
import store from "./store";

import ControladorRutas from "./components/common/ControladorRutas";
import CookieConsent from "react-cookie-consent";

import config from "./config";

import Header from "./components/layout/Header";
import Loader from "./components/layout/Loader";
import Alerts from "./components/layout/Alerts";
import Footer from "./components/layout/Footer";
import ModalGenerica from "./components/ecommerce/ModalGenerica";
import Avisos from "./components/layout/Avisos";
import RedesSociales from "./components/layout/RedesSociales";

// Alert Options
const alertOptions = {
  timeout: 3000,
  position: "top right",
};

export class App extends Component {
  UNSAFE_componentWillMount() {
    store.dispatch(showLoader());
    let hash_usuario =
      store.getState().auth.hash !== null && store.getState().auth.hash !== ""
        ? store.getState().auth.hash
        : "";
    store.dispatch(getPortal(hash_usuario));
  }

  componentDidUpdate(prevProps, prevState) {
    if (
      prevProps.portal !== this.props.portal &&
      store.getState().portal.descripcion !== undefined
    ) {
      store.dispatch(getMenus());
    }
  }

  componentDidMount() {
    store.dispatch(loadUser());
  }

  render() {
    return (
      <Provider store={store}>
        <AlertProvider template={AlertTemplate} {...alertOptions}>
          <Router>
            <Fragment>
              <Loader />
              <Header />
              <Alerts />
              <Avisos />
              <ControladorRutas />
              <Footer />
              <CookieConsent
                location="bottom"
                buttonText={config.cookies.texto_boton}
                expires={150}
              >
                {config.cookies.mensaje}
              </CookieConsent>
              <ModalGenerica />
              <RedesSociales />
            </Fragment>
          </Router>
        </AlertProvider>
      </Provider>
    );
  }
}

export default App;
