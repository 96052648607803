import axios from "../axiosConfig";

import {
  FETCH_AGRUP_HIJOS,
  GET_AGRUP_HIJOS,
  FETCH_AGRUP_COND,
  GET_AGRUP_COND,
} from "./types";

import { tokenConfig } from "./auth";

// GET ARTICULOS HIJOS DE AGRUPACION
export const getAgrupacionHijos = (id_articulo) => (dispatch, getState) => {
  dispatch({
    type: FETCH_AGRUP_HIJOS,
  });

  axios
    .get(`/agrupacionhijos?articulo=${id_articulo}`, tokenConfig(getState))
    .then((res) => {
      dispatch({
        type: GET_AGRUP_HIJOS,
        payload: res.data,
      });
    })
    .catch((err) => console.log(err));
};

// GET CONDICIONES DE AGRUPACION
export const getAgrupacionCondiciones = (id_articulo) => (
  dispatch,
  getState
) => {
  dispatch({
    type: FETCH_AGRUP_COND,
  });

  axios
    .get(
      `/agrupacioncondiciones?articulo=${id_articulo}`,
      tokenConfig(getState)
    )
    .then((res) => {
      dispatch({
        type: GET_AGRUP_COND,
        payload: res.data,
      });
    })
    .catch((err) => console.log(err));
};
