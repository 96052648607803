import React, { Component, Fragment } from "react";
import { connect } from "react-redux";
import PropTypes from "prop-types";
import { getFormasPago, setFormaPago } from "../../actions/formasPago";

export class FormasPago extends Component {
  static propTypes = {
    getFormasPago: PropTypes.func.isRequired,
    setFormaPago: PropTypes.func.isRequired,
    formas_pago: PropTypes.array.isRequired,
    forma_pago: PropTypes.object.isRequired,
  };

  componentDidMount() {
    this.props.getFormasPago();
  }

  onchange = (e) => {
    const forma_pago = this.props.formas_pago.filter(
      (form_pag) => form_pag.codigo_forma_pago_web === e.target.value
    );

    this.props.setFormaPago(forma_pago[0]);
  };

  render() {
    return (
      <Fragment>
        {this.props.formas_pago !== undefined &&
        this.props.formas_pago.length > 0 ? (
          <div className="tt-shopcart-box">
            <h4 className="tt-title">FORMAS DE PAGO</h4>
            <p>Seleccione una forma de pago de la lista</p>
            <form className="form-default">
              <div className="form-group">
                <label htmlFor="forma_envio">
                  FORMA DE PAGO <sup>*</sup>
                </label>
                <select
                  className="form-control"
                  name="forma_pago"
                  onChange={this.onchange}
                >
                  <option key="0" value=""></option>
                  {this.props.formas_pago.map((forma_pago, index) => (
                    <option
                      key={index}
                      value={forma_pago.codigo_forma_pago_web}
                    >
                      {forma_pago.descripcion_forma_pago}
                    </option>
                  ))}
                </select>
              </div>
            </form>
          </div>
        ) : (
          ""
        )}
      </Fragment>
    );
  }
}

const mapStateToProps = (state) => ({
  formas_pago: state.formasPago.formas_pago,
  forma_pago: state.formasPago.forma_pago,
});

export default connect(mapStateToProps, { getFormasPago, setFormaPago })(
  FormasPago
);
