import React, { Component, Fragment } from "react";
import { connect } from "react-redux";
import PropTypes from "prop-types";
import {
  getAgrupacionHijos,
  getAgrupacionCondiciones,
} from "../../../actions/agrupaciones";

import AnadirCarrito from "../AnadirCarrito";
import Precio from "../Precio";

//import "../../css/articulo.css";

export class AgrupacionFiltros extends Component {
  state = {};

  static propTypes = {
    articulo: PropTypes.object.isRequired,
    hijos: PropTypes.array.isRequired,
    condiciones: PropTypes.array.isRequired,
    getAgrupacionHijos: PropTypes.func.isRequired,
    getAgrupacionCondiciones: PropTypes.func.isRequired,
  };

  componentDidMount() {
    const codigo_articulo = this.props.codigo_articulo;
    this.props.getAgrupacionHijos(codigo_articulo);
    this.props.getAgrupacionCondiciones(codigo_articulo);
  }

  componentDidUpdate(prevProps) {
    window.wookie();
  }

  onClick = (e) => {
    this.setState({ [e.target.name]: e.target.value });
  };

  render() {
    const { condiciones, hijos, articulo } = this.props;
    const seleccion = this.state;

    const hijos_filtrados = hijos.filter((hijo) => {
      let cumple_condiciones = true;
      hijo.claves.forEach((clave) => {
        if (seleccion[clave.clave] !== clave.valor_clave) {
          cumple_condiciones = false;
        }
      });
      return cumple_condiciones;
    });

    console.log("condiciones", condiciones);
    console.log("seleccion", seleccion);
    console.log("hijos", hijos);
    console.log("hijos_filtrados", hijos_filtrados);

    return (
      <div className="agrupacion_articulos">
        <div className="tt-price">
          <span className="new-price">
            <Precio
              codigo_articulo={
                hijos_filtrados.length == 1
                  ? hijos_filtrados[0].codigo_articulo
                  : articulo.codigo_articulo
              }
            />
          </span>
        </div>
        {condiciones.map((condicion) => (
          <div key={condicion.id} className="condicion_agrupacion">
            <div className="titulo_agrupacion">{condicion.clave.nombre}</div>
            {condicion.valores.map((valor) => {
              return (
                <button
                  key={valor.id}
                  className={
                    valor.valor_clave === seleccion[valor.clave]
                      ? "btn btn-small btn-primary"
                      : "btn btn-small btn-border"
                  }
                  name={valor.clave}
                  value={valor.valor_clave}
                  onClick={this.onClick}
                >
                  {valor.nombre}
                </button>
              );
            })}
          </div>
        ))}
        {Object.keys(condiciones).length === Object.keys(seleccion).length ? (
          hijos_filtrados.length === 1 ? (
            <Fragment>
              <div className="tt-wrapper">
                {this.props.articulo.codigo_articulo !== undefined ? (
                  <AnadirCarrito
                    articulo={hijos_filtrados[0]}
                    mostrar_texto_btn="S"
                  />
                ) : (
                  ""
                )}
              </div>
            </Fragment>
          ) : hijos_filtrados.length === 0 ? (
            "No se ha encontrado ningún artículo con estas condiciones"
          ) : (
            <div className="tabla_agrupacion">
              <h5>Seleccione un artículo</h5>
              {hijos_filtrados.map((hijo) => (
                <div key={hijo.id} className="tabla_agrupacion_fila">
                  <div className="tabla_agrupacion_columna columna_descripcion">
                    {hijo.descrip_comercial}
                  </div>
                  <div className="tabla_agrupacion_columna columna_precio">
                    <Precio codigo_articulo={hijo.codigo_articulo} />
                  </div>
                  <div className="tabla_agrupacion_columna columna_carrito">
                    <AnadirCarrito articulo={hijo} mostrar_texto_btn="N" />
                  </div>
                </div>
              ))}
            </div>
          )
        ) : (
          "Utilice los selectores para configurar su artículo"
        )}
      </div>
    );
  }
}

const mapStateToProps = (state) => ({
  articulo: state.articulo.articulo,
  hijos: state.agrupaciones.hijos,
  condiciones: state.agrupaciones.condiciones,
});

export default connect(mapStateToProps, {
  getAgrupacionHijos,
  getAgrupacionCondiciones,
})(AgrupacionFiltros);
