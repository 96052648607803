import React, { Component, Fragment } from "react";
import { connect } from "react-redux";
import PropTypes from "prop-types";
import { getFiltrosPluginsInforme } from "../../actions/informes";

export class InformesMenu extends Component {
  static propTypes = {
    informes: PropTypes.array.isRequired,
    getFiltrosPluginsInforme: PropTypes.func.isRequired,
  };

  onClick = (e) => {
    e.preventDefault();
    if (e.target.dataset.informe !== "") {
      this.props.getFiltrosPluginsInforme(e.target.dataset.informe);
    }
  };

  render() {
    return (
      <Fragment>
        <header className="menu-informes">
          <div className="tt-color-scheme-01">
            <div className="container">
              <div className="tt-header-holder">
                <div className="tt-obj-menu">
                  <div className="tt-desctop-parent-menu tt-parent-box">
                    <div className="tt-desctop-menu tt-hover-02">
                      <nav>
                        <ul>
                          {this.props.informes === undefined
                            ? ""
                            : this.props.informes.map((informe, index) => {
                                if (informe.nivel === "1") {
                                  return (
                                    <li
                                      key={index}
                                      className="dropdown tt-megamenu-col-02 tt-submenu"
                                    >
                                      <a
                                        href="/#"
                                        data-informe={informe.nombre_informe}
                                        onClick={this.onClick}
                                      >
                                        {informe.nombre}
                                      </a>
                                      {this.props.informes === undefined ? (
                                        ""
                                      ) : this.props.informes
                                          .filter(
                                            (inf) =>
                                              inf.cod_padre ===
                                              informe.codigo_menu
                                          )
                                          .reduce(
                                            (accumulator, currentValue) =>
                                              accumulator + 1,
                                            0
                                          ) > 0 ? (
                                        <div className="dropdown-menu">
                                          <div className="row tt-col-list">
                                            <div className="col">
                                              <ul className="tt-megamenu-submenu tt-sub-img">
                                                {this.props.informes.map(
                                                  (hijo, hijo_index) => {
                                                    if (
                                                      hijo.cod_padre ===
                                                      informe.codigo_menu
                                                    ) {
                                                      return (
                                                        <li key={hijo_index}>
                                                          <a
                                                            href="/#"
                                                            data-informe={
                                                              hijo.nombre_informe
                                                            }
                                                            onClick={
                                                              this.onClick
                                                            }
                                                          >
                                                            {hijo.nombre}
                                                          </a>
                                                        </li>
                                                      );
                                                    }
                                                    return "";
                                                  }
                                                )}
                                              </ul>
                                            </div>
                                          </div>
                                        </div>
                                      ) : (
                                        ""
                                      )}
                                    </li>
                                  );
                                }
                                return "";
                              })}
                        </ul>
                      </nav>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </header>
      </Fragment>
    );
  }
}

const mapStateToProps = (state) => ({
  informes: state.informes.informes,
});

export default connect(mapStateToProps, {
  getFiltrosPluginsInforme,
})(InformesMenu);
