import React, { Component, Fragment } from "react";
import { connect } from "react-redux";
import PropTypes from "prop-types";
import { createMessage } from "../../actions/messages";
import { cambiarPass } from "../../actions/auth";

export class Cuenta extends Component {
  state = {
    pass: "",
    nuevo_pass: "",
    re_nuevo_pass: "",
    error_iguales: false,
    error_vacios: false,
  };

  static propTypes = {
    auth: PropTypes.object.isRequired,
    cambiarPass: PropTypes.func.isRequired,
    createMessage: PropTypes.func.isRequired,
  };

  componentDidUpdate() {
    window.wookie();
  }

  onChange = (e) => this.setState({ [e.target.name]: e.target.value });

  onSubmit = (e) => {
    e.preventDefault();

    const { pass, nuevo_pass, re_nuevo_pass } = this.state;
    // perform all neccassary validations
    if (pass !== "" && nuevo_pass !== "" && re_nuevo_pass !== "") {
      if (nuevo_pass !== re_nuevo_pass) {
        this.props.createMessage({
          error: "",
          passNotMatch: "Las contraseñas no son iguales.",
        });
        this.setState({ error_iguales: true });
      } else {
        this.props.cambiarPass(pass, nuevo_pass);
      }
    } else {
      this.props.createMessage({
        error: "",
        passNotMatch: "El campo no puede estar vacío.",
      });
      this.setState({ error_vacios: true });
    }
  };

  render() {
    const { isAuthenticated, user } = this.props.auth;
    const {
      pass,
      nuevo_pass,
      re_nuevo_pass,
      error_iguales,
      error_vacios,
    } = this.state;
    return (
      <Fragment>
        <div id="tt-pageContent">
          <div className="container-indent">
            <div className="container container-fluid-custom-mobile-padding">
              <h1 className="tt-title-subpages noborder">MI CUENTA</h1>
              <div className="tt-shopping-layout">
                <div className="tt-wrapper">
                  <h3 className="tt-title">MIS DATOS</h3>
                  <div className="tt-table-responsive">
                    <table className="tt-table-shop-02">
                      <tbody>
                        <tr>
                          <td>USUARIO:</td>
                          <td>{isAuthenticated ? user.usuario_web : ""} </td>
                        </tr>
                        <tr>
                          <td>E-MAIL:</td>
                          <td>{isAuthenticated ? user.email_usuario_web : ""} </td>
                        </tr>
                        <tr>
                          <td>NOMBRE:</td>
                          <td>{isAuthenticated ? user.nombre : ""} </td>
                        </tr>
                        {/*<tr>
                          <td>ADDRESS:</td>
                          <td>
                            Eexercitation ullamco laboris nisi ut aliquip ex ea
                          </td>
                        </tr>
                        <tr>
                          <td>ADDRESS 2:</td>
                          <td>Commodo consequat. Duis aute irure dol</td>
                        </tr>
                        <tr>
                          <td>COUNTRY:</td>
                          <td>Lorem ipsum dolor sit amet conse ctetur</td>
                        </tr>
                        <tr>
                          <td>ZIP:</td>
                          <td>555</td>
                        </tr>
                        <tr>
                          <td>PHONE:</td>
                          <td>888888888</td>
                        </tr>*/}
                      </tbody>
                    </table>
                  </div>
                </div>
                <div className="tt-wrapper">
                  <h3 className="tt-title">CAMBIAR CONTRASEÑA</h3>
                  <div className="tt-login-form">
                    <div className="row justify-content-center">
                      <div className="col-md-8 col-lg-6">
                        <div className="tt-item">
                          <div className="form-default">
                            <form onSubmit={this.onSubmit}>
                              <div className="form-group">
                                <label htmlFor="pass">
                                  CONTRASEÑA ACTUAL *
                                </label>
                                <div className="tt-required">
                                  * Campos Obligatorios
                                </div>
                                <input
                                  type="password"
                                  name="pass"
                                  onChange={this.onChange}
                                  value={pass}
                                  className={
                                    error_vacios
                                      ? "form-control error"
                                      : "form-control"
                                  }
                                  id="pass"
                                  placeholder="Contraseña actual"
                                  required
                                />
                              </div>
                              <div className="form-group">
                                <label htmlFor="nuevo_pass">
                                  NUEVA CONTRASEÑA *
                                </label>
                                <input
                                  type="password"
                                  name="nuevo_pass"
                                  onChange={this.onChange}
                                  value={nuevo_pass}
                                  className={
                                    error_iguales || error_vacios
                                      ? "form-control error"
                                      : "form-control"
                                  }
                                  id="nuevo_pass"
                                  placeholder="Nueva contraseña"
                                  required
                                />
                              </div>
                              <div className="form-group">
                                <label htmlFor="re_nuevo_pass">
                                  REPITA CONTRASEÑA *
                                </label>
                                <input
                                  type="password"
                                  name="re_nuevo_pass"
                                  onChange={this.onChange}
                                  value={re_nuevo_pass}
                                  className={
                                    error_iguales || error_vacios
                                      ? "form-control error"
                                      : "form-control"
                                  }
                                  id="re_nuevo_pass"
                                  placeholder="Repita contraseña"
                                  required
                                />
                              </div>
                              <div className="row">
                                <div className="col-auto">
                                  <div className="form-group">
                                    <button
                                      className="btn btn-border"
                                      type="submit"
                                    >
                                      CAMBIAR
                                    </button>
                                  </div>
                                </div>
                              </div>
                            </form>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </Fragment>
    );
  }
}

const mapStateToProps = (state) => ({
  auth: state.auth,
});

export default connect(mapStateToProps, { cambiarPass, createMessage })(Cuenta);
