import React, { Component, Fragment } from "react";
import { connect } from "react-redux";
import PropTypes from "prop-types";
import { getImagen } from "../../actions/imagen";

export class Imagen extends Component {
  static propTypes = {
    codigo_articulo: PropTypes.string.isRequired,
    numero_imagen: PropTypes.number,
    principal: PropTypes.string,
    getImagen: PropTypes.func.isRequired,
    imagenes: PropTypes.object.isRequired,
  };

  componentDidMount() {
    if (
      this.props.imagenes[this.props.codigo_articulo] === undefined ||
      this.props.imagenes[this.props.codigo_articulo][
        this.props.numero_imagen
      ] === undefined ||
      this.props.imagenes[this.props.codigo_articulo][this.props.numero_imagen]
        .error
    ) {
      this.props.getImagen(
        this.props.codigo_articulo,
        this.props.numero_imagen !== undefined ? this.props.numero_imagen : 1,
        this.props.principal !== undefined ? this.props.principal : "S"
      );
    }
  }

  componentDidUpdate(prevProps, prevState) {
    if (prevProps.codigo_articulo !== this.props.codigo_articulo) {
      if (
        this.props.imagenes[this.props.codigo_articulo] === undefined ||
        this.props.imagenes[this.props.codigo_articulo][
          this.props.numero_imagen
        ] === undefined ||
        this.props.imagenes[this.props.codigo_articulo][
          this.props.numero_imagen
        ].error
      ) {
        this.props.getImagen(
          this.props.codigo_articulo,
          this.props.numero_imagen !== undefined ? this.props.numero_imagen : 1,
          this.props.principal !== undefined ? this.props.principal : "S"
        );
      }
    }
  }

  render() {
    if (
      this.props.imagenes[this.props.codigo_articulo] !== undefined &&
      this.props.imagenes[this.props.codigo_articulo][
        this.props.numero_imagen
      ] !== undefined &&
      this.props.imagenes[this.props.codigo_articulo][this.props.numero_imagen]
        .isLoading
    ) {
      return (
        <Fragment>
          <img
            className="loader-precio"
            src="assets/img/loader.gif"
            data-src="assets/img/loader.gif"
            alt="Cargando..."
          />
          <span>Cargando...</span>
        </Fragment>
      );
    } else if (
      this.props.imagenes[this.props.codigo_articulo] !== undefined &&
      this.props.imagenes[this.props.codigo_articulo][
        this.props.numero_imagen
      ] !== undefined &&
      this.props.imagenes[this.props.codigo_articulo][this.props.numero_imagen]
        .imagen !== ""
    ) {
      return (
        <Fragment>
          <img
            src={
              "data:image/png;base64, " +
              this.props.imagenes[this.props.codigo_articulo][
                this.props.numero_imagen
              ].imagen
            }
            data-src={
              "data:image/png;base64, " +
              this.props.imagenes[this.props.codigo_articulo][
                this.props.numero_imagen
              ].imagen
            }
            alt=""
          />
        </Fragment>
      );
    } else {
      return (
        <Fragment>
          <img
            src="assets/img/placeholder.jpg"
            data-src="assets/img/placeholder.jpg"
            alt=""
          />
        </Fragment>
      );
    }

    // return (
    //   <Fragment>
    //     {imagen === "" ? (
    //       <img
    //         src="assets/img/placeholder.jpg"
    //         data-src="assets/img/placeholder.jpg"
    //         alt=""
    //       />
    //     ) : (
    //       <img
    //         src={"data:image/png;base64, " + imagen}
    //         data-src={"data:image/png;base64, " + imagen}
    //         alt=""
    //       />
    //     )}
    //   </Fragment>
    // );
  }
}

const mapStateToProps = (state) => ({
  imagenes: state.imagen.imagenes,
});

export default connect(mapStateToProps, {
  getImagen,
})(Imagen);
