import React, { Component, Fragment } from "react";
import { connect } from "react-redux";
import PropTypes from "prop-types";
import {
  getPedidosAnteriores,
  getLineasPedido,
} from "../../actions/pedidosAnteriores";
import { createMessage } from "../../actions/messages";
import "react-confirm-alert/src/react-confirm-alert.css";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faEye, faCartPlus } from "@fortawesome/free-solid-svg-icons";
import { postLinea, postLineas } from "../../actions/pedidos";
import Precio from "./Precio";
import Descripcion from "./Descripcion";
import ReactModal from "react-modal";

import "../../css/pedidosAnteriores.css";

ReactModal.setAppElement("#root");

export class ListasCompra extends Component {
  static propTypes = {
    pedidos_anteriores: PropTypes.array.isRequired,
    pedido_anterior_detalle: PropTypes.number.isRequired,
    lineas_pedido_anterior_detalle: PropTypes.array.isRequired,
    getPedidosAnteriores: PropTypes.func.isRequired,
    getLineasPedido: PropTypes.func.isRequired,
  };

  state = {
    is_open: false,
  };

  componentDidMount() {
    this.props.getPedidosAnteriores();
  }

  componentDidUpdate() {
    window.wookie();
  }

  verPedido = (e) => {
    e.preventDefault();
    let _id_pedido = e.currentTarget.dataset.id_pedido;

    this.props.getLineasPedido(_id_pedido);

    this.setState({ is_open: true });
  };

  anadirCarrito = (e) => {
    e.preventDefault();
    let _id_pedido = e.currentTarget.dataset.id_pedido;
    this.props.getLineasPedido(_id_pedido);

    let lineas = [];

    this.props.lineas_pedido_anterior_detalle.forEach((linea_pedido) => {
      let linea = {
        articulo: linea_pedido.articulo,
        descripcion: this.props.descripciones[linea_pedido.articulo].data
          .descrip_comercial,
        precio_venta: parseFloat(
          this.props.precios[linea_pedido.articulo].data.precio_neto
        ),
        observaciones: linea_pedido.observaciones,
        estado_linea: "B",
        cantidad_pedida: linea_pedido.cantidad_pedida,
        presentacion_pedido: linea_pedido.presentacion_pedido,
        tipo_linea: "P",
      };

      lineas.push(linea);
    });

    this.props.postLineas(lineas);
  };

  anadirLineaCarrito = (e) => {
    e.preventDefault();
    let _id = parseInt(e.currentTarget.dataset.id);

    this.props.lineas_pedido_anterior_detalle
      .filter((linea) => linea.id === _id)
      .forEach((linea_detalle) => {
        const linea = {
          articulo: linea_detalle.articulo,
          descripcion: this.props.descripciones[linea_detalle.articulo].data
            .descrip_comercial,
          precio_venta: parseFloat(
            this.props.precios[linea_detalle.articulo].data.precio_neto
          ),
          observaciones: linea_detalle.observaciones,
          estado_linea: "B",
          cantidad_pedida: linea_detalle.cantidad_pedida,
          presentacion_pedido: linea_detalle.presentacion_pedido,
          tipo_linea: "P",
        };

        this.props.postLinea(linea);
      });
  };

  cerrarModal = (e) => {
    e.preventDefault();
    this.setState({ is_open: false });
  };

  render() {
    const customStyles = {
      content: {
        top: "50%",
        left: "50%",
        right: "auto",
        bottom: "auto",
        marginRight: "-50%",
        transform: "translate(-50%, -50%)",
        width: "90%",
      },
      overlay: {
        "z-index": "10",
      },
    };

    return (
      <Fragment>
        <div className="container">
          <div id="tt-pageContent">
            <div className="container-indent">
              <div className="container">
                <h1 className="tt-title-subpages noborder">
                  PEDIDOS ANTERIORES
                </h1>
                <div className="row">
                  <div className="col-sm-12 col-md-12 col-lg-12">
                    {this.props.pedidos_anteriores.length === 0 ? (
                      ""
                    ) : (
                      <Fragment>
                        <div className="tt-shopcart-table pedidos_anteriores_desctop row">
                          <div className="col-sm-12 col-md-12 col-lg-12">
                            <div className="row titulos">
                              <div className="col-sm-12 col-md-3 col-lg-3">
                                SERIE/PEDIDO
                              </div>
                              <div className="col-sm-12 col-md-3 col-lg-3">
                                FECHA PEDIDO
                              </div>
                              <div className="col-sm-12 col-md-2 col-lg-2">
                                IMPORTE
                              </div>
                              <div className="col-sm-12 col-md-4 col-lg-4">
                                ACCIONES
                              </div>
                            </div>

                            {this.props.pedidos_anteriores.map((pedido) => {
                              return (
                                <div className="row" key={pedido.id_pedido}>
                                  <div className="col-sm-12 col-md-3 col-lg-3">
                                    {pedido.numero_serie !== null
                                      ? pedido.numero_serie +
                                        "/" +
                                        pedido.numero_pedido
                                      : pedido.numero_serie_pres +
                                        "/" +
                                        pedido.numero_pres}
                                  </div>
                                  <div className="col-sm-12 col-md-3 col-lg-3">
                                    {new Date(
                                      Date.parse(pedido.fecha_pedido)
                                    ).toLocaleDateString(this.props.locale)}
                                  </div>
                                  <div className="col-sm-12 col-md-2 col-lg-2">
                                    {new Intl.NumberFormat(this.props.locale, {
                                      style: "currency",
                                      currency: this.props.moneda,
                                    }).format(
                                      Number.parseFloat(
                                        pedido.importe_cobrado
                                      ).toFixed(2)
                                    )}
                                  </div>
                                  <div className="col-sm-12 col-md-2 col-lg-2">
                                    <span
                                      onClick={this.verPedido}
                                      data-id_pedido={pedido.id_pedido}
                                    >
                                      <FontAwesomeIcon icon={faEye} />
                                    </span>
                                  </div>
                                  <div className="col-sm-12 col-md-2 col-lg-2">
                                    {pedido.id_pedido !==
                                    this.props.pedido_anterior_detalle ? (
                                      ""
                                    ) : (
                                      <span
                                        onClick={this.anadirCarrito}
                                        data-id_pedido={pedido.id_pedido}
                                      >
                                        <FontAwesomeIcon icon={faCartPlus} />
                                      </span>
                                    )}
                                  </div>
                                </div>
                              );
                            })}
                          </div>
                        </div>
                        <div className="tt-shopcart-table pedidos_anteriores_mobile row">
                          <div className="col-sm-12 col-md-12 col-lg-12">
                            {this.props.pedidos_anteriores.map((pedido) => {
                              return (
                                <div className="row" key={pedido.id_pedido}>
                                  <div className="col-12">
                                    <div className="row ">
                                      <div className="col-6 titulos">
                                        SERIE/PEDIDO
                                      </div>
                                      <div className="col-6">
                                        {pedido.numero_serie !== null
                                          ? pedido.numero_serie +
                                            "/" +
                                            pedido.numero_pedido
                                          : pedido.numero_serie_pres +
                                            "/" +
                                            pedido.numero_pres}
                                      </div>
                                    </div>
                                    <div className="row ">
                                      <div className="col-6 titulos">
                                        FECHA PEDIDO
                                      </div>
                                      <div className="col-6">
                                        {new Date(
                                          Date.parse(pedido.fecha_pedido)
                                        ).toLocaleDateString(this.props.locale)}
                                      </div>
                                    </div>
                                    <div className="row ">
                                      <div className="col-6 titulos">
                                        IMPORTE
                                      </div>
                                      <div className="col-6">
                                        {new Intl.NumberFormat(
                                          this.props.locale,
                                          {
                                            style: "currency",
                                            currency: this.props.moneda,
                                          }
                                        ).format(
                                          Number.parseFloat(
                                            pedido.importe_cobrado
                                          ).toFixed(2)
                                        )}
                                      </div>
                                    </div>
                                    <div className="row ">
                                      <div className="col-6 titulos">
                                        ACCIONES
                                      </div>
                                      <div className="col-6">
                                        <div className="row">
                                          <div className="col-6">
                                            <span
                                              onClick={this.verPedido}
                                              data-id_pedido={pedido.id_pedido}
                                            >
                                              <FontAwesomeIcon icon={faEye} />
                                            </span>
                                          </div>
                                          <div className="col-6">
                                            {pedido.id_pedido !==
                                            this.props
                                              .pedido_anterior_detalle ? (
                                              ""
                                            ) : (
                                              <span
                                                onClick={this.anadirCarrito}
                                                data-id_pedido={
                                                  pedido.id_pedido
                                                }
                                              >
                                                <FontAwesomeIcon
                                                  icon={faCartPlus}
                                                />
                                              </span>
                                            )}
                                          </div>
                                        </div>
                                      </div>
                                    </div>
                                  </div>
                                </div>
                              );
                            })}
                          </div>
                        </div>
                      </Fragment>
                    )}
                  </div>
                </div>
              </div>
            </div>

            <ReactModal
              isOpen={this.state.is_open}
              onRequestClose={this.cerrarModal}
              style={customStyles}
            >
              <span onClick={this.cerrarModal}><i className="icon-f-67"></i></span>
              {this.props.lineas_pedido_anterior_detalle.length === 0 ||
              this.props.pedido_anterior_detalle === 0 ? (
                ""
              ) : (
                <div className="container-indent">
                  <div className="container">
                    <h1 className="tt-title-subpages noborder">
                      LINEAS DEL PEDIDO
                    </h1>
                    <div className="row">
                      <div className="col-sm-12 col-md-12 col-lg-12">
                        <div className="tt-shopcart-table pedidos_anteriores_detalle_desctop row">
                          <div className="col-sm-12 col-md-12 col-lg-12">
                            <div className="row titulos">
                              <div className="col-sm-12 col-md-2 col-lg-2">
                                ARTICULO
                              </div>
                              <div className="col-sm-12 col-md-2 col-lg-2">
                                DESCRIPCIÓN
                              </div>
                              <div className="col-sm-12 col-md-1 col-lg-1">
                                PRECIO
                              </div>
                              <div className="col-sm-12 col-md-1 col-lg-1">
                                CANTIDAD
                              </div>
                              <div className="col-sm-12 col-md-2 col-lg-2">
                                PRESENTACIÓN
                              </div>
                              <div className="col-sm-12 col-md-2 col-lg-2">
                                OBSERVACIONES
                              </div>
                              <div className="col-sm-12 col-md-2 col-lg-2">
                                ACCIONES
                              </div>
                            </div>

                            {this.props.lineas_pedido_anterior_detalle.map(
                              (linea) => {
                                return (
                                  <div className="row" key={linea.id}>
                                    <div className="col-sm-12 col-md-2 col-lg-2">
                                      {linea.articulo}
                                    </div>
                                    <div className="col-sm-12 col-md-2 col-lg-2">
                                      <Descripcion
                                        codigo_articulo={linea.articulo}
                                      />
                                    </div>
                                    <div className="col-sm-12 col-md-1 col-lg-1">
                                      <Precio
                                        codigo_articulo={linea.articulo}
                                      />
                                    </div>
                                    <div className="col-sm-12 col-md-1 col-lg-1">
                                      {linea.cantidad_pedida}
                                    </div>
                                    <div className="col-sm-12 col-md-2 col-lg-2">
                                      {linea.presentacion_pedido}
                                    </div>
                                    <div className="col-sm-12 col-md-2 col-lg-2">
                                      {linea.observaciones}
                                    </div>
                                    <div className="col-sm-12 col-md-2 col-lg-2">
                                      <span
                                        onClick={this.anadirLineaCarrito}
                                        data-id={linea.id}
                                      >
                                        <FontAwesomeIcon icon={faCartPlus} />
                                      </span>
                                    </div>
                                  </div>
                                );
                              }
                            )}
                          </div>
                        </div>

                        <div className="tt-shopcart-table pedidos_anteriores_detalle_mobile row">
                          <div className="col-sm-12 col-md-12 col-lg-12">
                            {this.props.lineas_pedido_anterior_detalle.map(
                              (linea) => {
                                return (
                                  <div className="row">
                                    <div className="col-12">
                                      <div className="row">
                                        <div className="col-6 titulos">
                                          ARTICULO
                                        </div>
                                        <div className="col-6">
                                          {linea.articulo}
                                        </div>
                                      </div>

                                      <div className="row">
                                        <div className="col-6 titulos">
                                          DESCRIPCIÓN
                                        </div>
                                        <div className="col-6">
                                          <Descripcion
                                            codigo_articulo={linea.articulo}
                                          />
                                        </div>
                                      </div>

                                      <div className="row">
                                        <div className="col-6 titulos">
                                          PRECIO
                                        </div>
                                        <div className="col-6">
                                          <Precio
                                            codigo_articulo={linea.articulo}
                                          />
                                        </div>
                                      </div>

                                      <div className="row">
                                        <div className="col-6 titulos">
                                          CANTIDAD
                                        </div>
                                        <div className="col-6">
                                          {linea.cantidad_pedida}
                                        </div>
                                      </div>

                                      <div className="row">
                                        <div className="col-6 titulos">
                                          PRESENTACIÓN
                                        </div>
                                        <div className="col-6">
                                          {linea.presentacion_pedido}
                                        </div>
                                      </div>

                                      <div className="row">
                                        <div className="col-6 titulos">
                                          OBSERVACIONES
                                        </div>
                                        <div className="col-6">
                                          {linea.observaciones}
                                        </div>
                                      </div>

                                      <div className="row">
                                        <div className="col-6 titulos">
                                          ACCIONES
                                        </div>
                                        <div className="col-6">
                                          <span
                                            onClick={this.anadirLineaCarrito}
                                            data-id={linea.id}
                                          >
                                            <FontAwesomeIcon
                                              icon={faCartPlus}
                                            />
                                          </span>
                                        </div>
                                      </div>
                                    </div>
                                  </div>
                                );
                              }
                            )}
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              )}
            </ReactModal>
          </div>
        </div>
      </Fragment>
    );
  }
}

const mapStateToProps = (state) => ({
  pedidos_anteriores: state.pedidosAnteriores.pedidos_anteriores,
  pedido_anterior_detalle: state.pedidosAnteriores.pedido_anterior_detalle,
  lineas_pedido_anterior_detalle:
    state.pedidosAnteriores.lineas_pedido_anterior_detalle,
  precios: state.precio.precio,
  descripciones: state.descripcion.descripciones,
  locale: state.precio.locale,
  moneda: state.precio.moneda,
});

export default connect(mapStateToProps, {
  getPedidosAnteriores,
  getLineasPedido,
  createMessage,
  postLinea,
  postLineas,
})(ListasCompra);
