import { GET_DOM_ENVIO, GET_ESTADO, GET_PROVINCIA,SET_DIRECCION } from "../actions/types.js";

const initialState = {
  domicilios_envio: [],
  domicilio: {},
  estado: {},
  provincia: {},
};

export default function (state = initialState, action) {
  switch (action.type) {
    case GET_DOM_ENVIO:
      return {
        ...state,
        domicilios_envio: action.payload,
      };
    case GET_ESTADO:
      return {
        ...state,
        estado: action.payload,
      };
    case GET_PROVINCIA:
      return {
        ...state,
        provincia: action.payload,
      };
    case SET_DIRECCION:
      return {
        ...state,
        domicilio: action.payload,
      };
    default:
      return state;
  }
}
