import React, { Component } from "react";
import { Link, Redirect } from "react-router-dom";
import { connect } from "react-redux";
import PropTypes from "prop-types";
import {
  recuperarPass,
  comprobarToken,
  reiniciarPass,
} from "../../actions/auth";

import queryString from "query-string";
import { ordenarMenu } from "../../helpers/funciones";

import "../../css/recuperar.css";

export class Recuperar extends Component {
  state = {
    username: "",
    token: "",
    nuevo_pass: "",
    re_nuevo_pass: "",
    error_iguales: false,
    error_vacios: false,
  };

  static propTypes = {
    recuperarPass: PropTypes.func.isRequired,
    comprobarToken: PropTypes.func.isRequired,
    reiniciarPass: PropTypes.func.isRequired,
    isAuthenticated: PropTypes.bool,
    irAInicio: PropTypes.bool,
    user: PropTypes.object,
    portal: PropTypes.object.isRequired,
  };

  componentDidMount() {
    let params = queryString.parse(this.props.location.search);

    if (params.t !== undefined) {
      this.setState({ token: params.t });
      this.props.comprobarToken(params.t);
    }
  }

  componentDidUpdate(prevProps) {
    if (this.props.irAInicio && this.props.irAInicio !== prevProps.irAInicio) {
      let url_inicio =
        window.location.protocol + "//" + window.location.hostname;

      window.location.href = url_inicio;
    }
  }

  onSubmit = (e) => {
    e.preventDefault();
    if (e.target.name === "recuperar") {
      this.props.recuperarPass(this.state.username);
    } else {
      const { nuevo_pass, re_nuevo_pass, token } = this.state;

      if (nuevo_pass !== "" && re_nuevo_pass !== "") {
        if (nuevo_pass !== re_nuevo_pass) {
          this.props.createMessage({
            error: "",
            passNotMatch: "Las contraseñas no son iguales.",
          });
          this.setState({ error_iguales: true });
        } else {
          this.props.reiniciarPass(
            this.props.user.usuario_web,
            token,
            nuevo_pass
          );
        }
      } else {
        this.props.createMessage({
          error: "",
          passNotMatch: "El campo no puede estar vacío.",
        });
        this.setState({ error_vacios: true });
      }
    }
  };

  onChange = (e) => this.setState({ [e.target.name]: e.target.value });

  render() {
    const {
      username,
      nuevo_pass,
      re_nuevo_pass,
      error_iguales,
      error_vacios,
    } = this.state;

    if (
      this.props.isAuthenticated &&
      this.props.invitado === false &&
      this.props.menus !== undefined &&
      this.props.menus.length > 0
    ) {
      this.props.menus.sort(ordenarMenu);

      const irA = this.props.menus.find(
        (menu) => menu.activo === "S" && menu.programa !== null
      );

      if (irA !== undefined && irA !== null) {
        return <Redirect to={"/" + irA.programa} />;
      } else {
        return "";
      }
    }

    let formulario;
    if (this.state.token !== "") {
      formulario = (
        <form name="cambiar" onSubmit={this.onSubmit}>
          <div className="form-group">
            <label htmlFor="nuevo_pass">NUEVA CONTRASEÑA *</label>
            <input
              type="password"
              name="nuevo_pass"
              onChange={this.onChange}
              value={nuevo_pass}
              className={
                error_iguales || error_vacios
                  ? "form-control error"
                  : "form-control"
              }
              id="nuevo_pass"
              placeholder="Nueva contraseña"
              required
            />
          </div>
          <div className="form-group">
            <label htmlFor="re_nuevo_pass">REPITA CONTRASEÑA *</label>
            <input
              type="password"
              name="re_nuevo_pass"
              onChange={this.onChange}
              value={re_nuevo_pass}
              className={
                error_iguales || error_vacios
                  ? "form-control error"
                  : "form-control"
              }
              id="re_nuevo_pass"
              placeholder="Repita contraseña"
              required
            />
          </div>
          <div className="row">
            <div className="col-auto">
              <div className="form-group">
                <button className="btn btn-border" type="submit">
                  CAMBIAR
                </button>
              </div>
            </div>
          </div>
        </form>
      );
    } else {
      formulario = (
        <form name="recuperar" onSubmit={this.onSubmit}>
          <div className="form-group">
            <input
              className="form-control"
              type="text"
              name="username"
              onChange={this.onChange}
              value={username}
              placeholder="Usuario"
            />
          </div>
          <button type="submit" className="btn btn-primary">
            Enviar
          </button>
          <Link to="/" className="btn btn-danger ml-3">
            Cancelar
          </Link>
          <p>
            ¿No tiene una cuenta?{" "}
            <Link to="/register">Formulario de Registro</Link>
          </p>
        </form>
      );
    }

    return (
      <div id="tt-pageContent" className="recuperar-contrasena">
        <div className="container-indent">
          <div className="container">
            <a className="tt-logo" href="index.html">
              <img className="tt-retina" src="images/custom/logo.png" alt="" />
            </a>
            <h1 className="tt-title-subpages noborder">
              {this.props.portal.descripcion}
            </h1>
            <div className="tt-login-form">
              <div className="row	justify-content-center">
                <div className="col-md-5">
                  <div className="tt-item">
                    <h2 className="tt-title">RECUPERACIÓN DE CONTRASEÑA</h2>
                    <div className="form-default justify-content-center">
                      {this.state.token !== "" ? (
                        <p>
                          Intoruzca su nueva contraseña dos veces para
                          modificarla
                        </p>
                      ) : (
                        <p>
                          Para recupear su contraseña, por favor, introduzca su
                          usuario y le enviaremos un email con más instrucciones
                        </p>
                      )}

                      {formulario}
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }
}

const mapStateToProps = (state) => ({
  isAuthenticated: state.auth.isAuthenticated,
  irAInicio: state.auth.irAInicio,
  user: state.auth.user,
  portal: state.portal.portal,
  hash: state.auth.hash,
});

export default connect(mapStateToProps, {
  recuperarPass,
  comprobarToken,
  reiniciarPass,
})(Recuperar);
