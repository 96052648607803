import React, { Component, Fragment } from "react";

export class InfromesFiltroField extends Component {
  onClick = (e) => {
    e.preventDefault();
    if (e.target.dataset.informe !== "") {
      this.props.getFiltrosPluginsInforme(e.target.dataset.informe);
    }
  };

  render() {
    if (this.props.filtro.tipo_filtro === "I") {
      return (
        <Fragment>
          <div className="row">
            <div className="col-md-6">
              <div className="form-group">
                <input
                  className={
                    "form-control " + this.props.filtro.nombre_filtro_idi
                  }
                  type={
                    this.props.filtro.data_type === "NUMBER" ? "number" : "text"
                  }
                  name={this.props.filtro.nombre_filtro + "_DESDE"}
                  placeholder={this.props.filtro.titulo_columna + " DESDE"}
                  required={this.props.filtro.obligatorio === "S"}
                  defaultValue={
                    !Array.isArray(this.props.filtro.valor_incial_filtro_desde)
                      ? this.props.filtro.valor_incial_filtro_desde
                      : ""
                  }
                />
              </div>
            </div>
            <div className="col-md-6">
              <div className="form-group">
                <input
                  className={
                    "form-control " + this.props.filtro.nombre_filtro_idi
                  }
                  type={
                    this.props.filtro.data_type === "NUMBER" ? "number" : "text"
                  }
                  name={this.props.filtro.nombre_filtro + "_HASTA"}
                  placeholder={this.props.filtro.titulo_columna + " HASTA"}
                  required={this.props.filtro.obligatorio === "S"}
                  defaultValue={
                    !Array.isArray(this.props.filtro.valor_incial_filtro_hasta)
                      ? this.props.filtro.valor_incial_filtro_hasta
                      : ""
                  }
                />
              </div>
            </div>
          </div>
        </Fragment>
      );
    } else {
      return (
        <Fragment>
          <div className="row">
            <div className="col-md-12">
              <div className="form-group">
                <input
                  className={
                    "form-control " + this.props.filtro.nombre_filtro_idi
                  }
                  type={
                    this.props.filtro.data_type === "NUMBER" ? "number" : "text"
                  }
                  name={this.props.filtro.nombre_filtro}
                  placeholder={this.props.filtro.titulo_columna}
                  required={this.props.filtro.obligatorio === "S"}
                  defaultValue={
                    !Array.isArray(this.props.filtro.valor_inicial_filtro)
                      ? this.props.filtro.valor_inicial_filtro
                      : ""
                  }
                />
              </div>
            </div>
          </div>
        </Fragment>
      );
    }
  }
}

export default InfromesFiltroField;
