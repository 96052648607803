import axios from "../axiosConfig";

import { GET_ARTICULO } from "./types";

import { tokenConfig } from "./auth";

// GET ARTICULO
export const getArticulo = (id_articulo, hash = "") => (dispatch, getState) => {
  axios
    .get(`/articulos/${id_articulo}/?inv=${hash}`, tokenConfig(getState))
    .then((res) => {
      dispatch({
        type: GET_ARTICULO,
        payload: res.data,
      });
    })
    .catch((err) => console.log(err));
};
