import React, { Component, Fragment } from "react";
import { Route, Switch } from "react-router-dom";
import { connect } from "react-redux";
import PropTypes from "prop-types";

import { getMenus } from "../../actions/portal";

import { ordenarMenu } from "../../helpers/funciones";

import { Helmet } from "react-helmet";

import Articulos from "../ecommerce/Articulos";
import Articulo from "../ecommerce/Articulo";
import Cuenta from "../usuarios/Cuenta";
import Informes from "../ecommerce/Informes";
import Resumen from "../ecommerce/Resumen";
import Login from "../usuarios/Login";
import Registro from "../usuarios/Registro";
import Logout from "../usuarios/Logout";
import Recuperar from "../usuarios/Recuperar";
import PrivateRoute from "./PrivateRoute";
import ListasCompra from "../ecommerce/ListasCompra";
import Inicio from "./Inicio";
import Estatico from "./Estatico";
import Contacto from "./Contacto";
import PedidosAnteriores from "../ecommerce/PedidosAnteriores";
import PedidoHabitual from "../ecommerce/PedidoHabitual";
import NovedadesOfertas from "../ecommerce/NovedadesOfertas";
import Widgets from "../widgets/Widgets";
import RegistroCompleto from "../usuarios/RegistroCompleto";
import Expedientes from "../expedientes/Expedientes";
import Pedido from "../ecommerce/Pedido";
import { GET_PORTAL } from "../../actions/types";
import Home from "../ecommerce/Home";
import Wompi from "../ecommerce/Wompi";
import Modular from "./Modular";

export class ControladorRutas extends Component {
  static propTypes = {
    menus: PropTypes.array.isRequired,
    portal: PropTypes.object.isRequired,
    getMenus: PropTypes.func.isRequired,
  };

  componentDidUpdate(prevProps, prevState) {
    if (
      prevProps.portal !== this.props.portal &&
      this.props.portal.descripcion !== undefined
    ) {
      this.props.getMenus();
    }
  }

  componenteDeRuta = (ruta) => ruta[0].toUpperCase() + ruta.slice(1);

  iterar_menus = (menu) => {
    if (menu.programa !== null) {
      if (menu.publico === "S") {
        switch (menu.programa) {
          case "articulos":
            return (
              <Route
                key={menu.programa}
                exact
                path="/articulos"
                component={Articulos}
              />
            );
          case "home":
            return (
              <Route key={menu.programa} exact path="/home" component={Home} />
            );
          case "login":
            return (
              <Route
                key={menu.programa}
                exact
                path="/login"
                component={Login}
              />
            );
          case "registro":
            return (
              <Route
                key={menu.programa}
                exact
                path="/registro"
                component={Registro}
              />
            );
          case "contacto":
            return (
              <Route
                key={menu.programa}
                exact
                path="/contacto"
                component={Contacto}
              />
            );
          case "registroCompleto":
            return (
              <Route
                key={menu.programa}
                exact
                path="/registroCompleto"
                component={RegistroCompleto}
              />
            );
          case "novedadesOfertas":
            return (
              <Route
                key={menu.programa}
                exact
                path="/novedadesOfertas"
                component={NovedadesOfertas}
              />
            );
          case "pedido":
            return (
              <Route
                key={menu.programa}
                exact
                path="/pedido"
                component={Pedido}
              />
            );
          default:
            return "";
        }
      } else {
        switch (menu.programa) {
          case "articulos":
            return (
              <PrivateRoute
                key={menu.programa}
                exact
                path="/articulos"
                component={Articulos}
              />
            );
          case "home":
            return (
              <PrivateRoute
                key={menu.programa}
                exact
                path="/home"
                component={Home}
              />
            );
          case "contacto":
            return (
              <PrivateRoute
                key={menu.programa}
                exact
                path="/contacto"
                component={Contacto}
              />
            );
          case "listasCompra":
            return (
              <PrivateRoute
                key={menu.programa}
                exact
                path="/listasCompra"
                component={ListasCompra}
              />
            );
          case "pedidoHabitual":
            return (
              <PrivateRoute
                key={menu.programa}
                exact
                path="/pedidoHabitual"
                component={PedidoHabitual}
              />
            );
          case "pedidosAnteriores":
            return (
              <PrivateRoute
                key={menu.programa}
                exact
                path="/pedidosAnteriores"
                component={PedidosAnteriores}
              />
            );
          case "novedadesOfertas":
            return (
              <PrivateRoute
                key={menu.programa}
                exact
                path="/novedadesOfertas"
                component={NovedadesOfertas}
              />
            );
          case "informes":
            return (
              <PrivateRoute
                key={menu.programa}
                exact
                path="/informes"
                component={Informes}
              />
            );
          case "widgets":
            return (
              <PrivateRoute
                key={menu.programa}
                exact
                path="/widgets"
                component={Widgets}
              />
            );
          case "expedientes":
            return (
              <PrivateRoute
                key={menu.programa}
                exact
                path="/expedientes"
                component={Expedientes}
              />
            );
          case "pedido":
            return (
              <PrivateRoute
                key={menu.programa}
                exact
                path="/pedido"
                component={Pedido}
              />
            );
          case "cuenta":
            return (
              <PrivateRoute
                key={menu.programa}
                exact
                path="/cuenta"
                component={Cuenta}
              />
            );
          case "logout":
            return (
              <PrivateRoute
                key={menu.programa}
                exact
                path="/logout"
                component={Logout}
              />
            );
          default:
            return "";
        }
      }
    } else {
      if (menu.hijos.length > 0) {
        return menu.hijos.map(this.iterar_menus);
      } else {
        return "";
      }
    }
  };

  render() {
    const menus = this.props.menus;
    const portal = this.props.portal;

    let Rutainicio;
    let Componenteinicio;
    let menu_articulos;

    if (menus !== undefined && menus.length > 0) {
      menus.sort(ordenarMenu);
      Rutainicio = menus.find(
        (menu) => menu.activo === "S" && menu.programa !== null
      );

      menu_articulos = menus.find((menu) => menu.programa === "articulos");

      Componenteinicio = Rutainicio.programa;
    } else {
      Componenteinicio = null;
      menu_articulos = null;
    }

    return (
      <Fragment>
        {menus !== undefined &&
        menus.length > 0 &&
        portal.descripcion !== undefined ? (
          <Fragment>
            <Helmet>
              <meta charSet="utf-8" />
              <meta
                name="description"
                content={
                  portal.descripcion !== undefined
                    ? portal.descripcion
                    : "Portal Ecommerce"
                }
              />
              <title>
                {portal.descripcion !== undefined
                  ? portal.descripcion
                  : "Portal Ecommerce"}
              </title>
              {portal.favicon !== undefined && portal.favicon !== null ? (
                <link
                  rel="icon"
                  type="image/png"
                  href={"data:image/png;base64, " + portal.favicon}
                  sizes="16x16"
                />
              ) : (
                ""
              )}
            </Helmet>

            <Switch>
              {Componenteinicio !== null ? (
                <Route
                  key="inicio"
                  exact
                  path="/"
                  render={(props) => (
                    <Inicio {...props} componente={Componenteinicio} />
                  )}
                />
              ) : (
                ""
              )}

              {menus.map(this.iterar_menus)}

              <Route
                key="estatico"
                exact
                path="/estatico/:componente"
                component={Estatico}
              />
              <Route
                key="modular"
                exact
                path="/modular/:modulo"
                component={Modular}
              />
              <Route
                key="recuperar"
                exact
                path="/recuperar"
                component={Recuperar}
              />
              <PrivateRoute
                key="resumen"
                exact
                path="/resumen"
                component={Resumen}
              />
              <PrivateRoute key="wompi" exact path="/wompi" component={Wompi} />

              {menu_articulos !== undefined &&
              menu_articulos !== null &&
              menu_articulos.publico === "S" ? (
                <Route
                  key="articulo"
                  exact
                  path="/articulo/:id_articulo/:descrip_comercial"
                  component={Articulo}
                />
              ) : (
                <PrivateRoute
                  key="articulo"
                  exact
                  path="/articulo/:id_articulo/:descrip_comercial"
                  component={Articulo}
                />
              )}
            </Switch>
          </Fragment>
        ) : (
          ""
        )}
      </Fragment>
    );
  }
}

const mapStateToProps = (state) => ({
  menus: state.portal.menus,
  portal: state.portal.portal,
});

export default connect(mapStateToProps, { getMenus })(ControladorRutas);
