import React, { Component, Fragment } from "react";
import { Link } from "react-router-dom";
import { connect } from "react-redux";
import PropTypes from "prop-types";
import AnadirCarrito from "./AnadirCarrito";
import Precio from "./Precio";
import Imagen from "./Imagen";
import BotonAnadirLista from "./BotonAnadirLista";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faTimes } from "@fortawesome/free-solid-svg-icons";
import { getPedidoHabitual } from "../../actions/pedidoHabitual";
import { getArticulosRelacionados } from "../../actions/articulosRelacionados";
import { getOfertas } from "../../actions/ofertas";
import { getNovedades } from "../../actions/novedades";
import {
  getArticulosByFamily,
  setBusqueda,
  cambiaArticulosOrden,
} from "../../actions/articulos";
import {
  getArticulos,
  getMasArticulos,
  cambiaArticulosPagina,
} from "../../actions/articulos";

import "../../css/articulos.css";
import "../../css/ListadoArticulos.css";

export class ListadoArticulos extends Component {
  static propTypes = {
    articulos: PropTypes.object.isRequired,
    pedido_habitual: PropTypes.object.isRequired,
    ofertas: PropTypes.object.isRequired,
    novedades: PropTypes.object.isRequired,
    relacionados: PropTypes.object.isRequired,
    articulos_pagina: PropTypes.number,
    articulos_search: PropTypes.string,
    articulos_orden: PropTypes.string,
    filtros_aplicados: PropTypes.array.isRequired,
    menus: PropTypes.array.isRequired,
    auth: PropTypes.object.isRequired,
    getArticulos: PropTypes.func.isRequired,
    getMasArticulos: PropTypes.func.isRequired,
    getPedidoHabitual: PropTypes.func.isRequired,
    getArticulosRelacionados: PropTypes.func.isRequired,
    getNovedades: PropTypes.func.isRequired,
    getOfertas: PropTypes.func.isRequired,
    cambiaArticulosPagina: PropTypes.func.isRequired,
  };

  componentDidMount() {
    switch (this.props.origen) {
      case "relacionados":
        this.props.getArticulosRelacionados(
          this.props.id_articulo,
          this.props.auth.hash != null ? this.props.auth.hash : ""
        );
        break;
      case "pedido_habitual":
        this.props.getPedidoHabitual(
          this.props.auth.hash != null ? this.props.auth.hash : ""
        );
        break;
      case "ofertas":
        this.props.getOfertas(
          this.props.auth.hash != null ? this.props.auth.hash : ""
        );
        break;
      case "novedades":
        this.props.getNovedades(
          this.props.auth.hash != null ? this.props.auth.hash : ""
        );
        break;
      case "articulos":
        if (this.props.filtros_aplicados.length > 0) {
          this.props.getArticulosByFamily(
            this.props.filtros_aplicados,
            this.props.articulos_pagina,
            this.props.articulos_orden,
            this.props.articulos_search,
            this.props.auth.hash != null ? this.props.auth.hash : ""
          );
        } else {
          this.props.getArticulos(
            this.props.articulos_search,
            this.props.articulos_pagina,
            this.props.articulos_orden,
            this.props.auth.hash != null ? this.props.auth.hash : ""
          );
        }

        break;
      default:
        this.props.getArticulos(
          this.props.articulos_search,
          this.props.articulos_pagina,
          this.props.articulos_orden,
          this.props.auth.hash != null ? this.props.auth.hash : ""
        );
        break;
    }
  }

  componentDidUpdate(prevProps, prevState) {
    window.wookie();

    switch (this.props.origen) {
      case "relacionados":
        if (prevProps.id_articulo !== this.props.id_articulo) {
          this.props.getArticulosRelacionados(
            this.props.id_articulo,
            this.props.auth.hash != null ? this.props.auth.hash : ""
          );
        }
        break;
      default:
        break;
    }
  }

  masArticulos = (e) => {
    e.preventDefault();
    let articulos_siguiente = "";
    switch (this.props.origen) {
      case "relacionados":
        articulos_siguiente = this.props.relacionados.articulos_siguiente;
        break;
      case "pedido_habitual":
        articulos_siguiente = this.props.pedido_habitual.articulos_siguiente;
        break;
      case "ofertas":
        articulos_siguiente = this.props.ofertas.articulos_siguiente;
        break;
      case "novedades":
        articulos_siguiente = this.props.novedades.articulos_siguiente;
        break;
      case "articulos":
        articulos_siguiente = this.props.articulos.articulos_siguiente;
        break;
      default:
        articulos_siguiente = this.props.articulos.articulos_siguiente;
        break;
    }

    this.props.getMasArticulos(
      articulos_siguiente,
      this.props.filtros_aplicados,
      this.props.origen
    );
  };

  limpiarBusqueda = (e) => {
    e.preventDefault();
    this.props.setBusqueda("");

    if (this.props.filtros_aplicados.length > 0) {
      this.props.getArticulosByFamily(
        this.props.filtros_aplicados,
        this.props.articulos_pagina,
        this.props.articulos_orden,
        "",
        this.props.auth.hash != null ? this.props.auth.hash : ""
      );
    } else {
      this.props.getArticulos(
        "",
        this.props.articulos_pagina,
        this.props.articulos_orden,
        this.props.auth.hash != null ? this.props.auth.hash : ""
      );
    }
  };

  onChange = (e) => {
    this.props.cambiaArticulosOrden(e.target.value);

    if (this.props.origen === "articulos") {
      if (this.props.filtros_aplicados.length > 0) {
        this.props.getArticulosByFamily(
          this.props.filtros_aplicados,
          this.props.articulos_pagina,
          e.target.value,
          this.props.articulos_search,
          this.props.auth.hash != null ? this.props.auth.hash : ""
        );
      } else {
        this.props.getArticulos(
          this.props.articulos_search,
          this.props.articulos_pagina,
          e.target.value,
          this.props.auth.hash != null ? this.props.auth.hash : ""
        );
      }
    }
  };

  changeArtPag = (e) => {
    e.preventDefault();

    this.props.cambiaArticulosPagina(e.target.value);

    if (this.props.filtros_aplicados.length > 0) {
      this.props.getArticulosByFamily(
        this.props.filtros_aplicados,
        e.target.value,
        this.props.articulos_orden,
        this.props.articulos_search,
        this.props.auth.hash != null ? this.props.auth.hash : ""
      );
    } else {
      this.props.getArticulos(
        this.props.articulos_search,
        e.target.value,
        this.props.articulos_orden,
        this.props.auth.hash != null ? this.props.auth.hash : ""
      );
    }
  };

  render() {
    let findPos = (elem) => elem.nombre === "LISTAS";
    let findPos2 = (elem) => elem.programa === "listasCompra";
    let pos;
    let pos2;
    if (this.props.menus !== undefined) {
      pos = this.props.menus.findIndex(findPos);
      if (pos !== -1) {
        pos2 = this.props.menus[pos].hijos.findIndex(findPos2);
      } else {
        pos2 = -1;
      }
    } else {
      pos = -1;
      pos2 = -1;
    }

    let anadir_lista = false;

    if (pos2 !== -1) anadir_lista = true;

    let articulos_render;
    switch (this.props.origen) {
      case "relacionados":
        articulos_render = this.props.relacionados;
        break;
      case "pedido_habitual":
        articulos_render = this.props.pedido_habitual;
        break;
      case "ofertas":
        articulos_render = this.props.ofertas;
        break;
      case "novedades":
        articulos_render = this.props.novedades;
        break;
      case "articulos":
        articulos_render = this.props.articulos;
        break;
      default:
        articulos_render = this.props.articulos;
        break;
    }

    return (
      <Fragment>
        <div className="content-indent">
          <div className="tt-filters-options">
            {this.props.origen === "articulos" ? (
              <h1 className="tt-title">
                Lista de Artículos{" "}
                <span className="tt-title-total">
                  ({this.props.articulos.articulos_total})
                </span>
              </h1>
            ) : (
              ""
            )}
            {this.props.origen === "novedades" ? (
              <h1 className="tt-title">
                Novedades{" "}
                <span className="tt-title-total">
                  ({this.props.novedades.articulos_total})
                </span>
              </h1>
            ) : (
              ""
            )}
            {this.props.origen === "ofertas" ? (
              <h1 className="tt-title">
                Ofertas{" "}
                <span className="tt-title-total">
                  ({this.props.ofertas.articulos_total})
                </span>
              </h1>
            ) : (
              ""
            )}
            {this.props.origen === "pedido_habitual" ? (
              <h1 className="tt-title">
                Lista de Artículos Habituales{" "}
                <span className="tt-title-total">
                  ({this.props.pedido_habitual.articulos_total})
                </span>
              </h1>
            ) : (
              ""
            )}
            {this.props.origen === "relacionados" &&
            articulos_render.articulos.length > 0 ? (
              <h1 className="tt-title">
                Artículos Relacionados{" "}
                <span className="tt-title-total">
                  ({this.props.relacionados.articulos_total})
                </span>
              </h1>
            ) : (
              ""
            )}
            {this.props.origen === "articulos" ? (
              <div className="tt-btn-toggle">
                <a href="#">FILTROS</a>
              </div>
            ) : (
              ""
            )}
            <div className="tt-sort">
              {this.props.articulos_search ? (
                <a href="#" onClick={this.limpiarBusqueda} className="">
                  <FontAwesomeIcon icon={faTimes} />{" "}
                  {this.props.articulos_search}
                </a>
              ) : (
                ""
              )}
              {this.props.origen === "articulos" ? (
                <select name="orden" onChange={this.onChange}>
                  <option value="Default Sorting">Ordenar por</option>
                  <option value="codigo">Código Artículo</option>
                  <option value="descripcion">Descripción</option>
                </select>
              ) : (
                ""
              )}
              {this.props.origen === "articulos" ? (
                <select name="resultados" onChange={this.changeArtPag}>
                  <option value="">Mostrar</option>
                  <option value="12">12</option>
                  <option value="24">24</option>
                  <option value="36">36</option>
                </select>
              ) : (
                ""
              )}
            </div>
            {/* <div className="tt-quantity">
              <a href="#" className="tt-show" data-value="4">
                <FontAwesomeIcon icon={faTh} />
              </a>
              <a href="#" className="tt-show" data-value="6">
                <FontAwesomeIcon icon={faThLarge} />
              </a>
            </div> */}
          </div>

          <div className="tt-product-listing row">
            {articulos_render.articulos.map((articulo) => (
              <div
                key={articulo.id}
                className="col-6 col-md-4 col-lg-3 col-xl-3 tt-col-item"
              >
                <div className="tt-product thumbprod-center">
                  <div className="tt-image-box">
                    {anadir_lista === true ? (
                      <BotonAnadirLista
                        origen="articulos"
                        codigo_articulo={articulo.codigo_articulo}
                      />
                    ) : (
                      ""
                    )}

                    <Link
                      to={
                        "/articulo/" +
                        articulo.id +
                        "/" +
                        (articulo.descrip_comercial !== undefined &&
                        articulo.descrip_comercial !== null
                          ? articulo.descrip_comercial
                              .replaceAll("/", " ")
                              .replaceAll(" ", "-")
                              .replaceAll("%", "")
                              .toLowerCase()
                          : "")
                      }
                    >
                      <span className="tt-img">
                        <Imagen
                          codigo_articulo={articulo.codigo_articulo}
                          numero_imagen={1}
                          principal="S"
                        />
                      </span>
                    </Link>
                  </div>
                  <div className="tt-description">
                    <div className="tt-row">
                      <ul className="tt-add-info">
                        <li>
                          <Link
                            to={
                              "/articulo/" +
                              articulo.id +
                              "/" +
                              (articulo.descrip_comercial !== undefined &&
                              articulo.descrip_comercial !== null
                                ? articulo.descrip_comercial
                                    .replaceAll("/", " ")
                                    .replaceAll(" ", "-")
                                    .replaceAll("%", "")
                                    .toLowerCase()
                                : "")
                            }
                          >
                            {articulo.codigo_articulo}
                          </Link>
                        </li>
                      </ul>
                    </div>
                    <h2 className="tt-title">
                      <Link
                        to={
                          "/articulo/" +
                          articulo.id +
                          "/" +
                          (articulo.descrip_comercial !== undefined &&
                          articulo.descrip_comercial !== null
                            ? articulo.descrip_comercial
                                .replaceAll("/", " ")
                                .replaceAll(" ", "-")
                                .replaceAll("%", "")
                                .toLowerCase()
                            : "")
                        }
                      >
                        {articulo.descrip_comercial}
                      </Link>
                    </h2>
                    <div className="tt-price">
                      <Precio codigo_articulo={articulo.codigo_articulo} />
                    </div>
                    <div className="tt-product-inside-hover">
                      <div className="tt-row-btn">
                        {articulo.tiene_agrupaciones !== "S" ? (
                          <AnadirCarrito articulo={articulo} />
                        ) : (
                          ""
                        )}
                      </div>
                      <div className="tt-row-btn">
                        {/* <a
                          href="/#"
                          className="tt-btn-quickview"
                          data-toggle="modal"
                          data-target="#ModalquickView"
                        >
                          <span className="ocultar">Cerrar</span>
                        </a> */}
                        {anadir_lista === true ? (
                          <BotonAnadirLista
                            origen="articulos_mobile"
                            codigo_articulo={articulo.codigo_articulo}
                          />
                        ) : (
                          ""
                        )}

                        {/* <a href="/#" className="tt-btn-compare">
                          <span className="ocultar">Cerrar</span>
                        </a> */}
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            ))}
          </div>
        </div>
        {this.props.origen !== "relacionados" ? (
          <div className="text-center mt-5">
            {articulos_render.articulos_siguiente !== null &&
            articulos_render.articulos_siguiente !== "" ? (
              <button onClick={this.masArticulos} className="btn btn-border">
                MOSTRAR MÁS ARTÍCULOS
              </button>
            ) : (
              <div className="text-center mt-5">
                <button className="btn btn-border01">
                  NO HAY MÁS ARTÍCULOS
                </button>
              </div>
            )}
          </div>
        ) : (
          ""
        )}
      </Fragment>
    );
  }
}

const mapStateToProps = (state) => ({
  articulos: state.listadoArticulos.articulos,
  pedido_habitual: state.listadoArticulos.pedido_habitual,
  ofertas: state.listadoArticulos.ofertas,
  novedades: state.listadoArticulos.novedades,
  relacionados: state.listadoArticulos.relacionados,
  articulos_pagina: state.listadoArticulos.articulos_pagina,
  articulos_search: state.listadoArticulos.articulos_search,
  articulos_orden: state.listadoArticulos.articulos_orden,
  filtros_aplicados: state.articulos.filtros_aplicados,
  menus: state.portal.menus,
  auth: state.auth,
});

export default connect(mapStateToProps, {
  getArticulos,
  getMasArticulos,
  getPedidoHabitual,
  getNovedades,
  getOfertas,
  getArticulosRelacionados,
  cambiaArticulosPagina,
  getArticulosByFamily,
  setBusqueda,
  cambiaArticulosOrden,
})(ListadoArticulos);
