import React, { Component, Fragment } from "react";
import { connect } from "react-redux";
import PropTypes from "prop-types";
import { getArticulos, getMasArticulos } from "../../actions/articulos";
import { getMenuFamilias } from "../../actions/familias";
import { getFiltros } from "../../actions/filtros";

import Buscador from "./Buscador";
import FamiliasMenu from "./FamiliasMenu";

import NovedadesOfertas from "./NovedadesOfertas";
import Novedades from "./Novedades";

import Banners from "./Banners";

import "../../css/articulos.css";

export class Home extends Component {
  static propTypes = {
    articulos_pagina: PropTypes.number.isRequired,
    getArticulos: PropTypes.func.isRequired,
    getMenuFamilias: PropTypes.func.isRequired,
    getFiltros: PropTypes.func.isRequired,
  };

  componentDidMount() {
    this.props.getMenuFamilias();
    this.props.getFiltros();
  }

  componentDidUpdate() {
    window.wookie();
  }

  render() {
    return (
      <Fragment>
        <Banners />
        <FamiliasMenu />
        <Buscador />
        <NovedadesOfertas />
      </Fragment>
    );
  }
}

const mapStateToProps = (state) => ({
  articulos_pagina: state.listadoArticulos.articulos_pagina,
  familias: state.articulos.familias,
  filtros: state.articulos.filtros,
  filtros_aplicados: state.articulos.filtros_aplicados,
});

export default connect(mapStateToProps, {
  getArticulos,
  getMenuFamilias,
  getFiltros,
  getMasArticulos,
})(Home);
