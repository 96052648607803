export const GET_ARTICULOS = "GET_ARTICULOS";
export const FETCH_ARTICULOS = "FETCH_ARTICULOS";
export const GET_MAS_ARTICULOS = "GET_MAS_ARTICULOS";
export const USER_LOADING = "USER_LOADING";
export const USER_LOADED = "USER_LOADED";
export const AUTH_ERROR = "AUTH_ERROR";
export const LOGIN_SUCCESS = "LOGIN_SUCCESS";
export const LOGIN_FAIL = "LOGIN_FAIL";
export const LOGOUT_SUCCESS = "LOGOUT_SUCCESS";
export const REGISTER_SUCCESS = "REGISTER_SUCCESS";
export const REGISTER_FAIL = "REGISTER_FAIL";
export const GET_ERRORS = "GET_ERRORS";
export const CREATE_MESSAGE = "CREATE_MESSAGE";
export const GET_PEDIDO_ACTIVO = "GET_PEDIDO_ACTIVO";
export const POST_LINEA_PEDIDO = "POST_LINEA_PEDIDO";
export const POST_LINEAS_PEDIDO = "POST_LINEAS_PEDIDO";
export const DELETE_LINEA_PEDIDO = "DELETE_LINEA_PEDIDO";
export const GET_DOM_ENVIO = "GET_DOM_ENVIO";
export const GET_ESTADO = "GET_ESTADO";
export const GET_PROVINCIA = "GET_PROVINCIA";
export const INTEGRAR_PEDIDO = "INTEGRAR_PEDIDO";
export const DELETE_PEDIDO = "DELETE_PEDIDO";
export const TOKEN_REFRESH = "TOKEN_REFRESH";
export const TOKEN_FAIL = "TOKEN_FAIL";
export const TOKEN_REFRESHING = "TOKEN_REFRESHING";
export const FETCH_MENU_INFORMES = "FETCH_MENU_INFORMES";
export const GET_MENU_INFORMES = "GET_MENU_INFORMES";
export const GET_FILTROS_PLUGINS_INFORME = "GET_FILTROS_PLUGINS_INFORME";
export const SET_INFORME_SELECCIONADO = "SET_INFORME_SELECCIONADO";
export const SET_DATOS_INFORME = "SET_DATOS_INFORME";
export const GET_DATOS_INFORME = "GET_DATOS_INFORME";
export const SHOW_LOADER = "SHOW_LOADER";
export const HIDE_LOADER = "HIDE_LOADER";
export const OBTENER_EFACTURA = "OBTENER_EFACTURA";
export const DESCARGA_EFACTURA = "DESCARGA_EFACTURA";
export const OBTENER_REPORT = "OBTENER_REPORT";
export const DESCARGA_REPORT = "DESCARGA_REPORT";
export const SEND_CAMBIO_PASSWORD = "SEND_CAMBIO_PASSWORD";
export const CAMBIO_PASSWORD_SUCCESS = "CAMBIO_PASSWORD_SUCCESS";
export const CAMBIO_PASSWORD_FAIL = "CAMBIO_PASSWORD_FAIL";
export const RECOVER_PASS = "RECOVER_PASS";
export const RECOVER_PASS_SUCCESS = "RECOVER_PASS_SUCCESS";
export const RECOVER_PASS_FAIL = "RECOVER_PASS_FAIL";
export const COMPROBAR_TOKEN = "COMPROBAR_TOKEN";
export const COMPROBAR_TOKEN_SUCCESS = "COMPROBAR_TOKEN_SUCCESS";
export const COMPROBAR_TOKEN_FAIL = "COMPROBAR_TOKEN_FAIL";
export const REINICIAR_PASSWORD = "REINICIAR_PASSWORD";
export const REINICIAR_PASSWORD_SUCCESS = "REINICIAR_PASSWORD_SUCCESS";
export const REINICIAR_PASSWORD_FAIL = "REINICIAR_PASSWORD_FAIL";
export const GET_ARTICULO = "GET_ARTICULO";
export const GET_GALERIA = "GET_GALERIA";
export const GET_GALERIA_SUCCESS = "GET_GALERIA_SUCCESS";
export const GET_GALERIA_FAIL = "GET_GALERIA_FAIL";
export const FETCH_MENU_FAMILIAS = "FETCH_MENU_FAMILIAS";
export const GET_MENU_FAMILIAS = "GET_MENU_FAMILIAS";
export const FETCH_FILTROS = "FETCH_FILTROS";
export const GET_FILTROS = "GET_FILTROS";
export const UPDATE_FILTROS = "UPDATE_FILTROS";
export const LIMPIAR_FILTROS = "LIMPIAR_FILTROS";
export const REMOVE_FILTRO = "REMOVE_FILTRO";
export const GET_PORTAL = "GET_PORTAL";
export const GET_PORTAL_SUCCESS = "GET_PORTAL_SUCCESS";
export const GET_PORTAL_FAIL = "GET_PORTAL_FAIL";
export const GET_PRECIO = "GET_PRECIO";
export const GET_PRECIO_SUCCESS = "GET_PRECIO_SUCCESS";
export const GET_PRECIO_FAIL = "GET_PRECIO_FAIL";
export const GET_MENUS = "GET_MENUS";
export const GET_MENUS_SUCCESS = "GET_MENUS_SUCCESS";
export const GET_MENUS_FAIL = "GET_MENUS_FAIL";
export const ENVIAR_REGISTRO = "ENVIAR_REGISTRO";
export const ENVIAR_REGISTRO_SUCCESS = "ENVIAR_REGISTRO_SUCCESS";
export const ENVIAR_REGISTRO_FAIL = "ENVIAR_REGISTRO_FAIL";
export const FETCH_LISTAS_COMPRA = "FETCH_LISTAS_COMPRA";
export const GET_LISTAS_COMPRA = "GET_LISTAS_COMPRA";
export const SET_VER_LISTA = "SET_VER_LISTA";
export const DELETE_LISTA_COMPRA = "DELETE_LISTA_COMPRA";
export const CREATE_LISTA_COMPRA = "CREATE_LISTA_COMPRA";
export const DELETE_LINEA_LISTA_COMPRA = "DELETE_LINEA_LISTA_COMPRA";
export const POST_LINEA_LISTA_COMPRA = "POST_LINEA_LISTA_COMPRA";
export const POST_LINEAS_LISTA_COMPRA = "POST_LINEAS_LISTA_COMPRA";
export const GET_DESCRIPCIONES_SUCCESS = "GET_DESCRIPCIONES_SUCCESS";
export const GET_DESCRIPCIONES_FAIL = "GET_DESCRIPCIONES_FAIL";
export const GET_DESCRIPCIONES = "GET_DESCRIPCIONES";
export const SET_ARTICULO = "SET_ARTICULO";
export const SET_LINEAS = "SET_LINEAS";
export const SET_OPEN = "SET_OPEN";
export const SET_CLOSE = "SET_CLOSE";
export const FETCH_PEDIDOS_ANTERIORES = "FETCH_PEDIDOS_ANTERIORES";
export const GET_PEDIDOS_ANTERIORES = "GET_PEDIDOS_ANTERIORES";
export const SET_VER_PEDIDO = "SET_VER_PEDIDO";
export const ENVIAR_CONTACTO = "ENVIAR_CONTACTO";
export const ENVIAR_CONTACTO_SUCCESS = "ENVIAR_CONTACTO_SUCCESS";
export const ENVIAR_CONTACTO_FAIL = "ENVIAR_CONTACTO_FAIL";
export const GET_OFERTAS = "GET_OFERTAS";
export const GET_NOVEDADES = "GET_NOVEDADES";
export const GET_PEDIDO_HABITUAL = "GET_PEDIDO_HABITUAL";
export const GET_RELACIONADOS = "GET_RELACIONADOS";
export const GET_AVISOS = "GET_AVISOS";
export const FETCH_FORMAS_ENVIO = "FETCH_FORMAS_ENVIO";
export const GET_FORMAS_ENVIO = "GET_FORMAS_ENVIO";
export const SET_DIRECCION = "SET_DIRECCION";
export const SET_FORMA_ENVIO = "SET_FORMA_ENVIO";
export const FETCH_ALMACENES = "FETCH_ALMACENES";
export const GET_ALMACENES = "GET_ALMACENES";
export const SET_ALMACEN_RECOGIDA = "SET_ALMACEN_RECOGIDA";
export const FETCH_RUTAS = "FETCH_RUTAS";
export const GET_RUTAS = "GET_RUTAS";
export const SET_RUTA_REPARTO = "SET_RUTA_REPARTO";
export const LIMPIAR_GALERIA = "LIMPIAR_GALERIA";
export const CHANGE_ARTICULOS_PAGINA = "CHANGE_ARTICULOS_PAGINA";
export const FETCH_PESTANAS = "FETCH_PESTANAS";
export const GET_PESTANAS = "GET_PESTANAS";
export const FETCH_WIDGETS = "FETCH_WIDGETS";
export const GET_WIDGETS = "GET_WIDGETS";
export const GET_MAS_DATOS_INFORME = "GET_MAS_DATOS_INFORME";
export const SET_MAS_DATOS_INFORME = "SET_MAS_DATOS_INFORME";
export const SET_LISTA_VALORES = "SET_LISTA_VALORES";
export const BORRA_HASH = "BORRA_HASH";
export const REGISTRO_COMPLETO_FAIL = "REGISTRO_COMPLETO_FAIL";
export const GET_PROVINCIAS = "GET_PROVINCIAS";
export const GET_ESTADOS = "GET_ESTADOS";
export const GET_TIPOS_EXPEDIENTES = "GET_TIPOS_EXPEDIENTES";
export const GET_TIPOS_EXPEDIENTES_CAMPOS = "GET_TIPOS_EXPEDIENTES_CAMPOS";
export const GET_EXPEDIENTES = "GET_EXPEDIENTES";
export const GET_TIPOS_NIF = "GET_TIPOS_NIF";
export const FETCH_BANNERS = "FETCH_BANNERS";
export const GET_BANNERS = "GET_BANNERS";
export const FETCH_IMG_BANNER = "FETCH_IMG_BANNER";
export const GET_IMG_BANNER = "GET_IMG_BANNER";
export const GET_IMAGEN = "GET_IMAGEN";
export const GET_IMAGEN_SUCCESS = "GET_IMAGEN_SUCCESS";
export const GET_IMAGEN_FAIL = "GET_IMAGEN_FAIL";
export const GET_MIGAS_ARTICULO = "GET_MIGAS_ARTICULO";
export const FETCH_FORMAS_PAGO = "FETCH_FORMAS_PAGO";
export const GET_FORMAS_PAGO = "GET_FORMAS_PAGO";
export const SET_FORMA_PAGO = "SET_FORMA_PAGO";
export const CERRAR_PEDIDO = "CERRAR_PEDIDO";
export const SET_BUSQUEDA = "SET_BUSQUEDA";
export const FETCH_AGRUP_HIJOS = "FETCH_AGRUP_HIJOS";
export const GET_AGRUP_HIJOS = "GET_AGRUP_HIJOS";
export const FETCH_AGRUP_COND = "FETCH_AGRUP_COND";
export const GET_AGRUP_COND = "GET_AGRUP_COND";
export const FETCH_TIRAS = "FETCH_TIRAS";
export const GET_TIRAS = "GET_TIRAS";
export const FETCH_TIRAS_DET = "FETCH_TIRAS_DET";
export const GET_TIRAS_DET = "GET_TIRAS_DET";
export const CHANGE_ARTICULOS_ORDEN = "CHANGE_ARTICULOS_ORDEN";
export const FETCH_MODULAR = "FETCH_MODULAR";
export const GET_MODULAR = "GET_MODULAR";
export const FETCH_MODULAR_DET = "FETCH_MODULAR_DET";
export const GET_MODULAR_DET = "GET_MODULAR_DET";
export const LIMPIAR_PRECIOS = "LIMPIAR_PRECIOS";
export const FETCH_PEDIDO_ACTIVO = "FETCH_PEDIDO_ACTIVO";
export const FETCH_PEDIDO_ACTIVO_FAIL = "FETCH_PEDIDO_ACTIVO_FAIL";
export const FETCH_TIPOS_PEDIDO = "FETCH_TIPOS_PEDIDO";
export const GET_TIPOS_PEDIDO = "GET_TIPOS_PEDIDO";
export const SET_TIPO_PEDIDO = "SET_TIPO_PEDIDO";