import axios from "../axiosConfig";

import { FETCH_PESTANAS, GET_PESTANAS,FETCH_WIDGETS, GET_WIDGETS } from "../actions/types.js";

import { tokenConfig } from "./auth";

export const getPestanas = () => (dispatch, getState) => {
  dispatch({
    type: FETCH_PESTANAS,
    payload: true,
  });
  axios
    .get(`/widgetsPestanas/`, tokenConfig(getState))
    .then((res) => {
      dispatch({
        type: GET_PESTANAS,
        payload: res.data,
      });
    })
    .catch((err) => console.log(err));
};

export const getWidgets = () => (dispatch, getState) => {
  dispatch({
    type: FETCH_WIDGETS,
    payload: true,
  });
  axios
    .get(`/widgets/`, tokenConfig(getState))
    .then((res) => {
      dispatch({
        type: GET_WIDGETS,
        payload: res.data.widgets,
      });
    })
    .catch((err) => console.log(err));
};