import React, { Component, Fragment } from "react";
import { connect } from "react-redux";
import PropTypes from "prop-types";

import BarChart from "./BarChart";
import PieChart from "./PieChart";
import GaugeChart from "./GaugeChart";

export class Widgets extends Component {
  static propTypes = {
    pestana: PropTypes.object.isRequired,
    widgets: PropTypes.array.isRequired,
  };

  componentDidMount() {}

  componentDidUpdate() {
    window.wookie();
  }

  render() {
    return (
      <Fragment>
        {this.props.pestana !== undefined ? (
          <div
            className={
              Number.parseInt(this.props.pestana.orden) === 1
                ? "tab-pane fade active"
                : "tab-pane fade"
            }
            id={"nav-pestana-" + this.props.pestana.id_pestana}
            role="tabpanel"
            aria-labelledby={
              "nav-pestana-" + this.props.pestana.id_pestana + "-tab"
            }
          >
            <div
              className="grid"
              style={{
                display: "grid",
                gridTemplateColumns:
                  "repeat(" +
                  this.props.pestana.columnas +
                  "," +
                  this.props.pestana.proporciones_columnas +
                  "fr)",
                gridTemplateRows:
                  "repeat(" +
                  this.props.pestana.filas +
                  "," +
                  this.props.pestana.proporciones_filas +
                  "fr)",
              }}
            >
              {this.props.widgets.map((widget) => (
                <div
                  className="grid-item"
                  id={"widget_" + widget.id_widget}
                  style={{
                    gridColumn: widget.grid_columna,
                    gridRow: widget.grid_fila,
                  }}
                >
                  {widget.tipo === "bar" ||
                  widget.tipo === "column" ||
                  widget.tipo === "line" ? (
                    <BarChart widget={widget} />
                  ) : (
                    ""
                  )}
                  {widget.tipo === "pie" || widget.tipo === "semicircle" ? (
                    <PieChart widget={widget} />
                  ) : (
                    ""
                  )}
                  {widget.tipo === "solidgauge" ? (
                    <GaugeChart widget={widget} />
                  ) : (
                    ""
                  )}
                  <style>{widget.css}</style>
                </div>
              ))}
            </div>
          </div>
        ) : (
          ""
        )}
      </Fragment>
    );
  }
}

const mapStateToProps = (state) => ({});

export default connect(mapStateToProps, {})(Widgets);
