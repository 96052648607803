import React, { Component, Fragment } from "react";
import { connect } from "react-redux";
import PropTypes from "prop-types";
import {
  getDomEnvio,
  getEstado,
  getProvincia,
  setDireccion,
} from "../../actions/domiciliosEnvio";

export class DomiciliosEnvio extends Component {
  static propTypes = {
    getDomEnvio: PropTypes.func.isRequired,
    getEstado: PropTypes.func.isRequired,
    getProvincia: PropTypes.func.isRequired,
    setDireccion: PropTypes.func.isRequired,
    domicilios_envio: PropTypes.array.isRequired,
    domicilio: PropTypes.object.isRequired,
    estado: PropTypes.object.isRequired,
    provincia: PropTypes.object.isRequired,
    pedido_cargando: PropTypes.bool.isRequired,
  };

  state = {
    domicilio: "",
    estado: "",
    provincia: "",
  };

  componentDidMount() {
    this.props.getDomEnvio();
  }

  componentDidUpdate(prevProps) {
    if (
      (prevProps.pedido_cargando !== this.props.pedido_cargando ||
        prevProps.domicilios_envio !== this.props.domicilios_envio) &&
      !this.props.pedido_cargando
    ) {

      if (
        this.props.domicilios_envio !== undefined &&
        this.props.domicilios_envio.length === 1
      ) {
        this.setState({ domicilio: this.props.domicilios_envio[0] });
        this.props.getEstado(this.props.domicilios_envio[0].estado);
        this.props.getProvincia(
          this.props.domicilios_envio[0].estado,
          this.props.domicilios_envio[0].provincia
        );
        this.props.setDireccion(this.props.domicilios_envio[0]);
      }
    }
  }

  onChange = (e) => {
    if (e.target.name === "domicilio") {
      const direccion = this.props.domicilios_envio.filter(
        (dom) => dom.id === parseInt(e.target.value)
      );
      this.setState({ [e.target.name]: direccion[0] });
      this.props.getEstado(direccion[0].estado);
      this.props.getProvincia(direccion[0].estado, direccion[0].provincia);
      this.props.setDireccion(direccion[0]);
    } else {
      this.setState({
        [e.target.name]: e.target.value,
      });
    }
  };

  render() {
    const { domicilio } = this.state;
    return (
      <Fragment>
        <div className="tt-shopcart-box">
          <h4 className="tt-title">DIRECCIÓN DE ENVÍO</h4>
          <p>Seleccione la dirección de envío en la lista</p>
          <form className="form-default">
            <div className="form-group">
              <label htmlFor="address_country">
                DOMICILIO <sup>*</sup>
              </label>
              <select
                className="form-control"
                name="domicilio"
                onChange={this.onChange}
              >
                <option key="0" value=""></option>
                {this.props.domicilios_envio.map((dom) => {
                  if (dom.id === this.props.domicilio.id) {
                    return (
                      <option key={dom.id} value={dom.id} selected>
                        {dom.nombre}
                      </option>
                    );
                  } else {
                    return (
                      <option key={dom.id} value={dom.id}>
                        {dom.nombre}
                      </option>
                    );
                  }
                })}
              </select>
            </div>
            <div className="form-group">
              <label htmlFor="address_province">DIRECCIÓN</label>
              <input
                type="text"
                name="direccion"
                value={domicilio.direccion || ""}
                className="form-control"
                placeholder="Dirección"
                readOnly
              />
            </div>
            <div className="form-group">
              <label htmlFor="address_province">LOCALIDAD</label>
              <input
                type="text"
                name="localidad"
                value={domicilio.localidad || ""}
                className="form-control"
                placeholder="Localidad"
                readOnly
              />
            </div>
            <div className="form-group">
              <label htmlFor="address_province">CÓDIGO POSTAL</label>
              <input
                type="text"
                name="cod_postal"
                value={domicilio.cod_postal || ""}
                className="form-control"
                placeholder="Código Postal"
                readOnly
              />
            </div>
            <div className="form-group">
              <label htmlFor="address_province">
                ESTADO <sup>*</sup>
              </label>
              <input
                type="text"
                name="estado"
                value={this.props.estado.nombre || ""}
                className="form-control"
                placeholder="Estado"
                onChange={this.onChange}
                readOnly
              />
            </div>
            <div className="form-group">
              <label htmlFor="address_zip">
                PROVINCIA <sup>*</sup>
              </label>
              <input
                type="text"
                name="provincia"
                value={this.props.provincia.nombre || ""}
                className="form-control"
                placeholder="Provincia"
                onChange={this.onChange}
                readOnly
              />
            </div>
          </form>
        </div>
      </Fragment>
    );
  }
}

const mapStateToProps = (state) => ({
  domicilios_envio: state.domiciliosEnvio.domicilios_envio,
  domicilio: state.domiciliosEnvio.domicilio,
  estado: state.domiciliosEnvio.estado,
  provincia: state.domiciliosEnvio.provincia,
  pedido_cargando: state.pedidos.isLoading,
});

export default connect(mapStateToProps, {
  getDomEnvio,
  getEstado,
  getProvincia,
  setDireccion,
})(DomiciliosEnvio);
