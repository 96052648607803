import React, { Component } from "react";

import Articulos from "../ecommerce/Articulos";
import Cuenta from "../usuarios/Cuenta";
import Informes from "../ecommerce/Informes";
import Login from "../usuarios/Login";
import Registro from "../usuarios/Registro";
import Widgets from "../widgets/Widgets";
import Home from "../ecommerce/Home";

import Estatico from "./Estatico";
import Pedido from "../ecommerce/Pedido";
import NovedadesOfertas from "../ecommerce/NovedadesOfertas";

export class Inicio extends Component {
  lista_componentes = {
    articulos: Articulos,
    cuenta: Cuenta,
    informes: Informes,
    login: Login,
    registro: Registro,
    estatico: Estatico,
    widgets: Widgets,
    home: Home,
    pedido: Pedido,
    novedadesOfertas: NovedadesOfertas,
  };

  render() {
    console.log("Inicio: ", this.props.componente);
    const Componente = this.lista_componentes[this.props.componente];
    console.log("Inicio - Componente: ", Componente);
    //const Componente = this.props.componente;
    return <Componente />;
  }
}

export default Inicio;
