import axios from "../axiosConfig";

import { createMessage, returnErrors } from "./messages";
import {
  USER_LOADING,
  USER_LOADED,
  AUTH_ERROR,
  LOGIN_SUCCESS,
  LOGIN_FAIL,
  LOGOUT_SUCCESS,
  REGISTER_SUCCESS,
  REGISTER_FAIL,
  TOKEN_REFRESH,
  TOKEN_FAIL,
  TOKEN_REFRESHING,
  SEND_CAMBIO_PASSWORD,
  CAMBIO_PASSWORD_SUCCESS,
  CAMBIO_PASSWORD_FAIL,
  RECOVER_PASS,
  RECOVER_PASS_FAIL,
  RECOVER_PASS_SUCCESS,
  COMPROBAR_TOKEN,
  COMPROBAR_TOKEN_SUCCESS,
  COMPROBAR_TOKEN_FAIL,
  REINICIAR_PASSWORD,
  REINICIAR_PASSWORD_SUCCESS,
  REINICIAR_PASSWORD_FAIL,
  ENVIAR_REGISTRO,
  ENVIAR_REGISTRO_SUCCESS,
  ENVIAR_REGISTRO_FAIL,
  REGISTRO_COMPLETO_FAIL,
  LIMPIAR_PRECIOS,
} from "./types";

// COMPROBAR EL TOKEN Y CARGAR EL USUARIO
export const loadUser = () => (dispatch, getState) => {
  //User Loading
  dispatch({ type: USER_LOADING });

  //console.log("BACKEND: ", config.backendUrl);
  const token = getState().auth.token;
  const refresh = getState().auth.refresh;

  if (
    token !== undefined &&
    token !== null &&
    token !== "" &&
    refresh !== undefined &&
    refresh !== null &&
    refresh !== ""
  ) {
    axios
      .get(`/usuario/user`, tokenConfig(getState))
      .then((res) => {
        dispatch({
          type: USER_LOADED,
          payload: res.data,
        });
      })
      .catch((err) => {
        console.log(err);
        if (err.response.status === 401) {
          localStorage.removeItem("token");
          localStorage.removeItem("refresh");
          dispatch({
            type: LOGOUT_SUCCESS,
            borra_hash: false,
          });
        }
      });
  } else {
    dispatch({
      type: LOGOUT_SUCCESS,
      borra_hash: false,
    });
  }
};

export const refreshToken = (dispatch) => (getState) => {
  const refresh = localStorage.getItem("refresh");

  if (refresh === undefined) {
    document.body.classList.add("loaded");
    dispatch({
      type: AUTH_ERROR,
    });
    return;
  }

  dispatch({
    type: TOKEN_REFRESHING,
  });

  const config = {
    headers: {
      "Content-Type": "application/json",
    },
  };

  const body = JSON.stringify({ refresh });

  axios
    .post("/token/refresh/", body, config)
    .then((res) => {
      document.body.classList.add("loaded");
      dispatch({
        type: TOKEN_REFRESH,
        payload: res.data,
      });

      //dispatch(getState().auth.promesa);
    })
    .catch((err) => {
      document.body.classList.add("loaded");
      dispatch({
        type: TOKEN_FAIL,
      });
    });
};

// LOGIN USER
export const login = (username, password, hash = "") => (dispatch) => {
  //User Loading
  dispatch({ type: USER_LOADING });

  document.body.classList.remove("loaded");

  //Headers
  const config = {
    headers: {
      "Content-Type": "application/json",
    },
  };

  let body;
  if (hash === "") {
    // Request Body
    body = JSON.stringify({ username, password });
  } else {
    body = JSON.stringify({ username, password, hash });
  }

  axios
    .post("/usuario/login", body, config)
    .then((res) => {
      console.log("Login: ", res.data);
      dispatch({
        type: LOGIN_SUCCESS,
        payload: res.data,
      });
      dispatch({
        type: LIMPIAR_PRECIOS,
      });
    })
    .catch((err) => {
      document.body.classList.add("loaded");
      console.log("Login:", err.response.data);
      dispatch(
        returnErrors("Usuario o contraseña no válidos", err.response.status)
      );
      dispatch({
        type: LOGIN_FAIL,
      });
    });
};

// RECUPERAR CONTRASEÑA
export const recuperarPass = (username) => (dispatch) => {
  //User Loading
  dispatch({ type: RECOVER_PASS });

  //Headers
  const config = {
    headers: {
      "Content-Type": "application/json",
    },
  };

  // Request Body
  const body = JSON.stringify({ username });

  axios
    .post("/usuario/recuperarPass", body, config)
    .then((res) => {
      console.log("Recuperar: ", res.data);
      if (res.data.resultado === "OK") {
        dispatch({
          type: RECOVER_PASS_SUCCESS,
          payload: res.data,
        });
        dispatch(
          createMessage({
            error: "",
            recuperarPass: res.data.mensaje,
          })
        );
      } else {
        dispatch({
          type: RECOVER_PASS_FAIL,
        });
        dispatch(returnErrors(res.data.mensaje, 0));
      }
    })
    .catch((err) => {
      dispatch(returnErrors(err.response.data, err.response.status));
      dispatch({
        type: RECOVER_PASS_FAIL,
      });
    });
};

// RECUPERAR CONTRASEÑA
export const comprobarToken = (token) => (dispatch) => {
  //User Loading
  dispatch({ type: COMPROBAR_TOKEN });

  //Headers
  const config = {
    headers: {
      "Content-Type": "application/json",
    },
  };

  // Request Body
  const body = JSON.stringify({ token });

  axios
    .post("/usuario/comprobarToken", body, config)
    .then((res) => {
      console.log("Comprobar: ", res.data);

      if (res.data.resultado === "OK") {
        dispatch({
          type: COMPROBAR_TOKEN_SUCCESS,
          payload: res.data.usuario.user,
        });

        dispatch({
          type: LOGIN_SUCCESS,
          payload: res.data.usuario,
        });
      } else {
        dispatch({
          type: COMPROBAR_TOKEN_FAIL,
        });
        dispatch(returnErrors(res.data.mensaje, 0));
      }
    })
    .catch((err) => {
      dispatch(returnErrors(err.response.data, err.response.status));
      dispatch({
        type: COMPROBAR_TOKEN_FAIL,
      });
    });
};

// CAMBIAR CONTRASEÑA DESPUÉS DE RECUPERAR
export const reiniciarPass = (usuario, token, password) => (
  dispatch,
  getState
) => {
  //User Loading
  dispatch({ type: REINICIAR_PASSWORD });

  // Request Body
  const body = JSON.stringify({ usuario, token, password });

  axios
    .post("/usuario/reiniciarPass", body, tokenConfig(getState))
    .then((res) => {
      if (res.data.resultado === "OK") {
        dispatch({
          type: REINICIAR_PASSWORD_SUCCESS,
          payload: res.data,
        });
        dispatch(
          createMessage({
            error: "",
            passCambiada: res.data.mensaje,
          })
        );
      } else {
        dispatch({
          type: REINICIAR_PASSWORD_FAIL,
        });
        dispatch(returnErrors(res.data.mensaje, 0));
      }
    })
    .catch((err) => {
      dispatch(returnErrors(err.response.data, err.response.status));
      dispatch({
        type: REINICIAR_PASSWORD_FAIL,
      });
    });
};

// CAMBIAR PASSWORD DE USUARIO
export const cambiarPass = (password, nuevo_password) => (
  dispatch,
  getState
) => {
  //User Loading
  dispatch({ type: SEND_CAMBIO_PASSWORD });

  // Request Body
  const body = JSON.stringify({ password, nuevo_password });

  axios
    .post("/usuario/cambiarPass", body, tokenConfig(getState))
    .then((res) => {
      if (res.data.resultado === "OK") {
        dispatch({
          type: CAMBIO_PASSWORD_SUCCESS,
          payload: res.data,
        });
        dispatch(
          createMessage({
            error: "",
            passCambiada: res.data.mensaje,
          })
        );
      } else {
        dispatch({
          type: CAMBIO_PASSWORD_FAIL,
        });
        dispatch(returnErrors(res.data.mensaje, 0));
      }
    })
    .catch((err) => {
      dispatch(returnErrors(err.response.data, err.response.status));
      dispatch({
        type: CAMBIO_PASSWORD_FAIL,
      });
    });
};

// REGISTER USER
export const register = ({ username, password, email }) => (dispatch) => {
  //Headers
  const config = {
    headers: {
      "Content-Type": "application/json",
    },
  };

  // Request Body
  const body = JSON.stringify({ username, email, password });

  axios
    .post("/auth/register", body, config)
    .then((res) => {
      dispatch({
        type: REGISTER_SUCCESS,
        payload: res.data,
      });
    })
    .catch((err) => {
      dispatch(returnErrors(err.response.data, err.response.status));
      dispatch({
        type: REGISTER_FAIL,
      });
    });
};
// LOGOUT USER
export const logout = () => (dispatch, getState) => {
  dispatch({
    type: LOGOUT_SUCCESS,
    borra_hash: true,
  });

  // axios
  //   .post("/usuario/logout", null, tokenConfig(getState))
  //   .then((res) => {
  //     dispatch({
  //       type: LOGOUT_SUCCESS,
  //     });
  //   })
  //   .catch((err) => {
  //     dispatch(returnErrors(err.response.data, err.response.status));
  //   });
};

// Setup config with token - helper function
export const tokenConfig = (getState) => {
  //GET TOKEN FROM STATE
  const token = getState().auth.token;

  //Headers
  const config = {
    headers: {
      "Content-Type": "application/json",
    },
  };
  //Si tengo token => lo añado al config de la petición
  if (token && token !== "undefined") {
    config.headers["Authorization"] = `Bearer ${token}`;
  }

  return config;
};

// FORMULARIO DE REGISTRO
export const enviarRegistro = (
  nombre,
  cliente,
  mensaje,
  email,
  usuario_web
) => (dispatch) => {
  //User Loading
  dispatch({ type: ENVIAR_REGISTRO });

  //Headers
  const config = {
    headers: {
      "Content-Type": "application/json",
    },
  };

  // Request Body
  const body = JSON.stringify({ nombre, cliente, mensaje, email, usuario_web });

  axios
    .post("/usuario/enviarRegistro", body, config)
    .then((res) => {
      console.log("Registro: ", res.data);
      if (res.data.resultado === "OK") {
        dispatch({
          type: ENVIAR_REGISTRO_SUCCESS,
        });
        dispatch(
          createMessage({
            error: "",
            recuperarPass: res.data.mensaje,
          })
        );
      } else {
        dispatch({
          type: ENVIAR_REGISTRO_FAIL,
        });
        dispatch(returnErrors(res.data.mensaje, 0));
      }
    })
    .catch((err) => {
      dispatch(
        returnErrors(
          "Lo sentimos se ha producido un error en el envío del formulario. Por favor, inténtelo más tarde.",
          err.response.status
        )
      );
      dispatch({
        type: ENVIAR_REGISTRO_FAIL,
      });
    });
};

export const enviarRegistroCompleto = (datos, hash = "") => (
  dispatch,
  getState
) => {
  // Request Body
  let body;
  if (hash === "") {
    // Request Body
    body = JSON.stringify({ datos });
  } else {
    body = JSON.stringify({ datos, hash });
  }

  axios
    .post("/usuario/registro/", body, tokenConfig(getState))
    .then((res) => {
      dispatch({
        type: LOGIN_SUCCESS,
        payload: res.data,
      });
      dispatch({
        type: LIMPIAR_PRECIOS,
      });
    })
    .catch((err) => {
      dispatch(returnErrors(err.response.data, err.response.status));
      dispatch({
        type: REGISTRO_COMPLETO_FAIL,
      });
    });
};
