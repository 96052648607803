import {
  FETCH_LISTAS_COMPRA,
  GET_LISTAS_COMPRA,
  DELETE_LISTA_COMPRA,
  CREATE_LISTA_COMPRA,
  SET_VER_LISTA,
  DELETE_LINEA_LISTA_COMPRA,
} from "../actions/types";

const initialState = {
  listas_compra: [],
  lista_detalle: 0,
  lineas_lista_detalle: [],
  isLoading: false,
};

export default function (state = initialState, action) {
  switch (action.type) {
    case FETCH_LISTAS_COMPRA:
      return {
        ...state,
        isLoading: true,
      };

    case SET_VER_LISTA:
      return {
        ...state,
        lista_detalle: parseInt(action.payload.codigo_lista),
        lineas_lista_detalle: action.payload.lineas,
      };

    case GET_LISTAS_COMPRA:
      return {
        ...state,
        isLoading: false,
        listas_compra: action.payload,
      };

    case DELETE_LISTA_COMPRA:
      let listas = state.listas_compra.filter(
        (lista) => lista.codigo_lista !== parseInt(action.payload)
      );
      return {
        ...state,
        listas_compra: listas,
      };

    case CREATE_LISTA_COMPRA:
      return {
        ...state,
        isLoading: false,
        listas_compra: [...state.listas_compra, action.payload],
      };

    case DELETE_LINEA_LISTA_COMPRA:
      let lineas = state.lineas_lista_detalle.filter(
        (linea) => linea.id !== parseInt(action.payload)
      );
      return {
        ...state,
        lineas_lista_detalle: lineas,
      };

    default:
      return state;
  }
}
