import React, { Component, Fragment } from "react";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";

import { registerLocale } from "react-datepicker";
import es from "date-fns/locale/es";
registerLocale("es", es);

export class InputFecha extends Component {
  state = {
    valor: "",
  };

  handleChange = (date) => {
    this.setState({
      valor: date,
    });
  };

  render() {
    const InputFechaDatepicker = ({ value, onClick }) => (
      <input
        className="form-control"
        type="text"
        value={value}
        name={this.props.campo.campo}
        placeholder={this.props.campo.literal}
        required={this.props.campo.obligatorio === "S"}
        onClick={onClick}
        readOnly
      />
    );

    return (
      <Fragment>
        <div className="form-group" key={this.props.index}>
          <label>{this.props.campo.literal}</label>
          <DatePicker
            onChange={this.handleChange}
            isClearable
            selected={this.state.valor}
            dateFormat="dd/MM/yyyy"
            todayButton="Hoy"
            locale="es"
            customInput={<InputFechaDatepicker />}
            className="form-control"
            name={this.props.campo.campo}
            placeholderText={this.props.campo.literal}
            required={this.props.campo.obligatorio === "S"}
          />
        </div>
      </Fragment>
    );
  }
}

export default InputFecha;
