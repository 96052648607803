import {
  FETCH_FORMAS_PAGO,
  GET_FORMAS_PAGO,
  SET_FORMA_PAGO,
} from "../actions/types.js";

const initialState = {
  formas_pago: [],
  forma_pago: {},
};

export default function (state = initialState, action) {
  switch (action.type) {
    case FETCH_FORMAS_PAGO:
      return {
        ...state,
        isLoading: true,
      };
    case GET_FORMAS_PAGO:
      return {
        ...state,
        formas_pago: action.payload,
        isLoading: false,
      };
    case SET_FORMA_PAGO:
      return {
        ...state,
        forma_pago: action.payload,
      };
    default:
      return state;
  }
}
