import React, { Component, Fragment } from "react";
import { Link, Redirect } from "react-router-dom";
import { connect } from "react-redux";
import PropTypes from "prop-types";
import { confirmAlert } from "react-confirm-alert";
import { cerrarPedido } from "../../actions/pedidos";
import { getPedidoActivo } from "../../actions/pedidos";

export class Wompi extends Component {
  static propTypes = {
    pedido: PropTypes.object.isRequired,
    forma_pago: PropTypes.object.isRequired,
    moneda: PropTypes.string.isRequired,
    cerrarPedido: PropTypes.func.isRequired,
    num_pedido: PropTypes.string.isRequired,
    serie_pedido: PropTypes.string.isRequired,
    ejercicio: PropTypes.string.isRequired,
    numero_pres: PropTypes.string.isRequired,
    numero_serie_pres: PropTypes.string.isRequired,
    getPedidoActivo: PropTypes.func.isRequired,
  };

  state = {
    volver: "",
    error: false,
  };

  cerrarPedido = (result) => {
    var transaction = result.transaction;

    if (transaction.status === "APPROVED") {
      this.setState({ error: false });
      this.props.cerrarPedido(this.props.pedido);
    } else {
      this.setState({ error: true });
      this.props.getPedidoActivo();
    }
  };

  componentDidMount() {
    var checkout = new window.WidgetCheckout({
      currency: "COP", //{this.props.moneda},
      amountInCents: Math.round(this.props.pedido.importe_cobrado) * 100,
      reference: this.props.pedido.id_pedido_libra,
      publicKey: this.props.forma_pago.parama_1,
    });

    checkout.open(this.cerrarPedido);
  }

  finalizar = () => {
    const info_pedido =
      this.props.serie_pedido !== undefined &&
      this.props.serie_pedido !== null &&
      this.props.serie_pedido !== "" &&
      this.props.num_pedido !== undefined &&
      this.props.num_pedido !== null &&
      this.props.num_pedido !== ""
        ? this.props.serie_pedido + "/" + this.props.num_pedido
        : this.props.numero_serie_pres + "/" + this.props.numero_pres;
    confirmAlert({
      title: "PEDIDO REALIZADO",
      message:
        "Estamos verificando el pago del pedido : " +
        info_pedido +
        ", puede continuar navegando.",
      closeOnEscape: false,
      closeOnClickOutside: false,
      buttons: [
        {
          label: "Aceptar",
          onClick: () => this.setState({ volver: "S" }),
        },
      ],
    });
  };

  render() {
    if (
      (Object.keys(this.props.pedido).length === 0 &&
        this.props.num_pedido === "" &&
        this.props.serie_pedido === "" &&
        this.props.numero_pres === "" &&
        this.props.numero_serie_pres === "") ||
      this.state.volver === "S"
    ) {
      return <Redirect to="/" />;
    } else if (
      Object.keys(this.props.pedido).length === 0 &&
      ((this.props.num_pedido !== "" && this.props.serie_pedido !== "") ||
        (this.props.numero_pres !== "" && this.props.numero_serie_pres !== ""))
    ) {
      this.finalizar();
    } else if (this.state.error) {
      return (
        <Fragment>
          <div className="container">
            <div id="tt-pageContent" className="articulo">
              <div className="container-indent">
                <div className="container container-fluid-mobile">
                  <h1 className="tt-title-subpages noborder">
                    PROCESO DE PAGO
                  </h1>
                  <div className="row">
                    <div className="col">
                      <p>
                        El pago ha fallado o ha sido denegado, intentelo de
                        nuevo finalizando el pedido de nuevo.
                      </p>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </Fragment>
      );
    }

    return (
      <Fragment>
        <div className="container">
          <div id="tt-pageContent" className="articulo">
            <div className="container-indent">
              <div className="container container-fluid-mobile">
                <h1 className="tt-title-subpages noborder">PROCESO DE PAGO</h1>
                <div className="row">
                  <div className="col">
                    <p>Debe completar el pago para que se procese su pedido</p>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </Fragment>
    );
  }
}

const mapStateToProps = (state) => ({
  pedido: state.pedidos.pedido,
  forma_pago: state.formasPago.forma_pago,
  moneda: state.precio.moneda,
  num_pedido: state.pedidos.num_pedido,
  serie_pedido: state.pedidos.serie_pedido,
  ejercicio: state.pedidos.ejercicio,
  numero_pres: state.pedidos.numero_pres,
  numero_serie_pres: state.pedidos.numero_serie_pres,
});

export default connect(mapStateToProps, { cerrarPedido, getPedidoActivo })(
  Wompi
);
