import axios from "../axiosConfig";
import { tokenConfig } from "./auth";
import { FETCH_MENU_FAMILIAS, GET_MENU_FAMILIAS } from "./types";

// GET MENU INFORMES
export const getMenuFamilias = () => (dispatch, getState) => {
  dispatch({
    type: FETCH_MENU_FAMILIAS,
  });

  // const refrescar = refreshToken(dispatch);

  let config = tokenConfig(getState);

  axios
    .get(`/familias/`, config)
    .then((res) => {
      dispatch({
        type: GET_MENU_FAMILIAS,
        payload: res.data,
      });
    })
    .catch((err) => {
      console.log(err);
      //
      // if (err.response.status === 401) {
      //   refrescar();
      // }
    });
};
