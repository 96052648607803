import axios from "../axiosConfig";

import { GET_MIGAS_ARTICULO } from "./types";

import { tokenConfig } from "./auth";

export const getMigasArticulo = (id_articulo, hash = "") => (
  dispatch,
  getState
) => {
  axios
    .get(
      `/articulos/migasArticulo/?articulo=${id_articulo}&inv=${hash}`,
      tokenConfig(getState)
    )
    .then((res) => {
      dispatch({
        type: GET_MIGAS_ARTICULO,
        payload: res.data.migas,
      });
    })
    .catch((err) => console.log(err));
};
