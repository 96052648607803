import axios from "../axiosConfig";
import { FETCH_ARTICULOS, GET_RELACIONADOS } from "./types";
import { tokenConfig } from "./auth";

// GET ARTICULOS
export const getArticulosRelacionados = (id_articulo, hash = "") => (
  dispatch,
  getState
) => {
  dispatch({
    type: FETCH_ARTICULOS,
    payload: true,
  });
  axios
    .get(
      `/articulos/relacionados/?id_articulo=${id_articulo}&inv=${hash}`,
      tokenConfig(getState)
    )
    .then((res) => {
      dispatch({
        type: GET_RELACIONADOS,
        payload: res.data,
      });
    })
    .catch((err) => console.log(err));
};
