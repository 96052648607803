import React, { Component } from "react";
import { Link, Redirect } from "react-router-dom";
import { connect } from "react-redux";
import PropTypes from "prop-types";
import { enviarRegistroCompleto } from "../../actions/auth";
import { getProvincias } from "../../actions/provincias";
import { getEstados } from "../../actions/estados";
import { getTiposNif } from "../../actions/tiposNif";
import { ordenarMenu } from "../../helpers/funciones";
import { getPedidoActivo } from "../../actions/pedidos";
import { getMenus } from "../../actions/portal";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import { registerLocale } from "react-datepicker";
import es from "date-fns/locale/es";
import { createMessage } from "../../actions/messages";

import "../../css/registroCompleto.css";

registerLocale("es", es);

export class RegistroCompleto extends Component {
  state = {
    nif: "",
    nombre: "",
    fecha_nacimiento: "",
    direccion: "",
    codigo_postal: "",
    localidad: "",
    provincia: "",
    estado: "",
    email: "",
    telefono: "",
    usuario_web_registro: "",
    password_registro: "",
    tipo_entidad: "",
    razon_social: "",
    terminos_condiciones: false,
    politica_cookies: false,
    politica_privacidad: false,
  };

  static propTypes = {
    enviarRegistroCompleto: PropTypes.func.isRequired,
    isAuthenticated: PropTypes.bool,
    portal: PropTypes.object.isRequired,
    menus: PropTypes.array.isRequired,
    hash: PropTypes.string,
    invitado: PropTypes.bool,
    provincias: PropTypes.array.isRequired,
    estados: PropTypes.array.isRequired,
    tipos_nif: PropTypes.array.isRequired,
    createMessage: PropTypes.func.isRequired,
  };

  componentDidMount() {
    this.props.getEstados();
  }

  componentDidUpdate(prevProps, prevState) {
    if (
      prevProps.isAuthenticated !== this.props.isAuthenticated ||
      prevProps.invitado !== this.props.invitado
    ) {
      this.props.getMenus();

      let findPos = (elem) => elem.programa === "articulos";
      if (this.props.menus !== undefined) {
        let pos = this.props.menus.findIndex(findPos);
        if (pos !== -1) {
          this.props.getPedidoActivo(
            this.props.hash != null ? this.props.hash : ""
          );
        }
      }
    }
  }

  onSubmit = (e) => {
    e.preventDefault();
    if (!this.state.terminos_condiciones) {
      this.props.createMessage({
        aceptar_registro: "Debe aceptar los terminos y condiciones de uso.",
      });
    } else if (!this.state.politica_cookies) {
      this.props.createMessage({
        aceptar_registro: "Debe aceptar la política de cookies.",
      });
    } else if (!this.state.politica_privacidad) {
      this.props.createMessage({
        aceptar_registro: "Debe aceptar la política de privacidad.",
      });
    } else {
      this.props.enviarRegistroCompleto(this.state, this.props.hash);
    }
  };

  onChange = (e) => {
    this.setState({ [e.target.name]: e.target.value });
    if (e.target.name === "estado") {
      this.props.getTiposNif(e.target.value);
      this.props.getProvincias(e.target.value);
    }
  };

  handleChange = (date) => {
    this.setState({
      fecha_nacimiento: date,
    });
  };

  render() {
    const menus = this.props.menus;

    if (menus !== undefined && menus.length > 0) {
      menus.sort(ordenarMenu);

      if (this.props.isAuthenticated && this.props.invitado === false) {
        const irA = menus.find(
          (menu) => menu.activo === "S" && menu.programa !== null
        );
        if (irA !== undefined && irA !== null) {
          return <Redirect to={"/" + irA.programa} />;
        } else {
          return "";
        }
      }
    }

    const InputFechaNacimiento = ({ value, onClick }) => (
      <input
        className={"form-control"}
        type="text"
        value={value}
        name="fecha_nacimiento"
        id="input_fecha_nacimiento"
        placeholder="Fecha nacimiento"
        onClick={onClick}
        readOnly
      />
    );

    return (
      <div id="tt-pageContent" className="registro">
        <div className="container-indent">
          <div className="container">
            <a className="tt-logo" href="index.html">
              <img className="tt-retina" src="images/custom/logo.png" alt="" />
            </a>
            <h1 className="tt-title-subpages noborder">
              {this.props.portal.descripcion}
            </h1>
            <div className="tt-login-form">
              <div className="row	justify-content-center">
                <div className="col-sm-12 col-md-8 col-lg-8">
                  <div className="tt-item">
                    <h2 className="tt-title">FORMULARIO DE REGISTRO</h2>
                    <div className="form-default justify-content-center">
                      <form onSubmit={this.onSubmit}>
                        <div className="row">
                          <div className="col-sm-12 col-md-6 col-lg-6">
                            <div className="form-group">
                              <label htmlFor="input_estado">Estado*</label>
                              <select
                                className="form-control"
                                name="estado"
                                id="input_estado"
                                onChange={this.onChange}
                                value={this.state.estado}
                                placeholder="Estado"
                              >
                                <option value="">Estado</option>
                                {this.props.estados !== undefined &&
                                this.props.estados.length > 0
                                  ? this.props.estados.map((estado, index) => {
                                      return (
                                        <option
                                          key={index}
                                          value={estado.codigo}
                                        >
                                          {estado.nombre}
                                        </option>
                                      );
                                    })
                                  : ""}
                              </select>
                            </div>
                            <div className="form-group">
                              <label htmlFor="input_tipo_entidad">
                                Tipo de entidad*
                              </label>
                              <select
                                className="form-control"
                                name="tipo_entidad"
                                id="input_tipo_entidad"
                                onChange={this.onChange}
                                value={this.state.tipo_entidad}
                                placeholder="Tipo de entidad"
                              >
                                <option value="">Tipo de entidad</option>
                                {this.props.tipos_nif !== undefined &&
                                this.props.tipos_nif.length > 0
                                  ? this.props.tipos_nif.map(
                                      (tipo_nif, index) => {
                                        return (
                                          <option
                                            key={index}
                                            value={tipo_nif.tipo}
                                          >
                                            {tipo_nif.descripcion}
                                          </option>
                                        );
                                      }
                                    )
                                  : ""}
                              </select>
                            </div>
                            <div className="form-group">
                              <label htmlFor="input_nombre">Nombre*</label>
                              <input
                                className="form-control"
                                type="text"
                                name="nombre"
                                id="input_nombre"
                                onChange={this.onChange}
                                value={this.state.nombre}
                                placeholder="Nombre"
                              />
                            </div>
                            <div className="form-group">
                              <label htmlFor="input_razon_social">
                                Razón social
                              </label>
                              <input
                                className="form-control"
                                type="text"
                                name="razon_social"
                                id="input_razon_social"
                                onChange={this.onChange}
                                value={this.state.razon_social}
                                placeholder="Razón social(Solo profesionales)"
                              />
                            </div>
                            <div className="form-group">
                              <label htmlFor="input_nif">DNI/NIF/NIT*</label>
                              <input
                                className="form-control"
                                type="text"
                                name="nif"
                                id="input_nif"
                                onChange={this.onChange}
                                value={this.state.nif}
                                placeholder="DNI/NIF/NIT"
                              />
                            </div>
                            <div className="form-group">
                              <label htmlFor="input_fecha_nacimiento">
                                Fecha nacimiento
                              </label>
                              <DatePicker
                                onChange={this.handleChange}
                                isClearable
                                selected={this.state.fecha_nacimiento}
                                dateFormat="dd/MM/yyyy"
                                todayButton="Hoy"
                                locale="es"
                                customInput={<InputFechaNacimiento />}
                                className="form-control"
                                name="fecha_nacimiento"
                                placeholderText="Fecha nacimiento"
                              />
                            </div>
                          </div>
                          <div className="col-sm-12 col-md-6 col-lg-6">
                            <div className="form-group">
                              <label htmlFor="input_provincia">
                                Provincia*
                              </label>
                              <select
                                className="form-control"
                                name="provincia"
                                id="input_provincia"
                                onChange={this.onChange}
                                value={this.state.provincia}
                                placeholder="Provincia"
                              >
                                <option value="">Provincia</option>
                                {this.props.provincias !== undefined &&
                                this.props.provincias.length > 0
                                  ? this.props.provincias.map(
                                      (provincia, index) => {
                                        return (
                                          <option
                                            key={index}
                                            value={provincia.provincia}
                                          >
                                            {provincia.nombre}
                                          </option>
                                        );
                                      }
                                    )
                                  : ""}
                              </select>
                            </div>
                            <div className="form-group">
                              <label htmlFor="input_localidad">
                                Localidad*
                              </label>
                              <input
                                className="form-control"
                                type="text"
                                name="localidad"
                                id="input_localidad"
                                onChange={this.onChange}
                                value={this.state.localidad}
                                placeholder="Localidad"
                              />
                            </div>
                            <div className="form-group">
                              <label htmlFor="input_codigo_postal">
                                Código postal*
                              </label>
                              <input
                                className="form-control"
                                type="text"
                                name="codigo_postal"
                                id="input_codigo_postal"
                                onChange={this.onChange}
                                value={this.state.codigo_postal}
                                placeholder="Código postal"
                              />
                            </div>
                            <div className="form-group">
                              <label htmlFor="input_direccion">
                                Dirección*
                              </label>
                              <input
                                className="form-control"
                                type="text"
                                name="direccion"
                                id="input_direccion"
                                onChange={this.onChange}
                                value={this.state.direccion}
                                placeholder="Dirección"
                              />
                            </div>
                            <div className="form-group">
                              <label htmlFor="input_email">Email*</label>
                              <input
                                className="form-control"
                                type="text"
                                name="email"
                                id="input_email"
                                onChange={this.onChange}
                                value={this.state.email}
                                placeholder="Email"
                              />
                            </div>

                            <div className="form-group">
                              <label htmlFor="input_telefono">Teléfono*</label>
                              <input
                                className="form-control"
                                type="text"
                                name="telefono"
                                id="input_telefono"
                                onChange={this.onChange}
                                value={this.state.telefono}
                                placeholder="Teléfono"
                              />
                            </div>
                          </div>
                        </div>
                        <div className="row">
                          <div className="col-sm-12 col-md-12 col-lg-12">
                            <div className="form-group">
                              <label htmlFor="input_usuario_web_registro">
                                Usuario web*
                              </label>
                              <input
                                className="form-control"
                                type="text"
                                name="usuario_web_registro"
                                id="input_usuario_web_registro"
                                onChange={this.onChange}
                                value={this.state.usuario_web_registro}
                                placeholder="Usuario web"
                              />
                            </div>
                            <div className="form-group">
                              <label htmlFor="input_password_registro">
                                Contraseña*
                              </label>
                              <input
                                className="form-control"
                                type="password"
                                name="password_registro"
                                id="input_password_registro"
                                onChange={this.onChange}
                                value={this.state.password_registro}
                                placeholder="Contraseña"
                              />
                            </div>
                          </div>
                        </div>

                        <div className="row">
                          <div className="col-auto mr-auto">
                            <div class="form-check">
                              <input
                                type="checkbox"
                                class="form-check-input"
                                id="terminos_condiciones"
                                name="terminos_condiciones"
                                onChange={this.onChange}
                                checked={this.state.terminos_condiciones}
                              />
                              <label
                                class="form-check-label"
                                for="terminos_condiciones"
                              >
                                He leído y acepto los términos y condicines de
                                uso
                              </label>
                            </div>
                            <div class="form-check">
                              <input
                                type="checkbox"
                                class="form-check-input"
                                id="politica_cookies"
                                name="politica_cookies"
                                onChange={this.onChange}
                                checked={this.state.politica_cookies}
                              />
                              <label
                                class="form-check-label"
                                for="politica_cookies"
                              >
                                He leído y acepto la política de cookies
                              </label>
                            </div>
                            <div class="form-check">
                              <input
                                type="checkbox"
                                class="form-check-input"
                                id="politica_privacidad"
                                name="politica_privacidad"
                                onChange={this.onChange}
                                checked={this.state.politica_privacidad}
                              />
                              <label
                                class="form-check-label"
                                for="politica_privacidad"
                              >
                                He leído y acepto la política de privacidad
                              </label>
                            </div>
                          </div>
                        </div>

                        <div className="row">
                          <div className="col-auto mr-auto">
                            <div className="form-group">
                              <button type="submit" className="btn btn-primary">
                                Enviar
                              </button>
                            </div>
                          </div>
                        </div>
                        <p>
                          ¿Ya tiene usuario?{" "}
                          <Link to="/login">Acceda al sitio</Link>
                        </p>
                      </form>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }
}

const mapStateToProps = (state) => ({
  isAuthenticated: state.auth.isAuthenticated,
  hash: state.auth.hash,
  invitado: state.auth.invitado,
  portal: state.portal.portal,
  menus: state.portal.menus,
  provincias: state.provincias.provincias,
  estados: state.estados.estados,
  tipos_nif: state.tiposNif.tipos_nif,
});

export default connect(mapStateToProps, {
  enviarRegistroCompleto,
  getEstados,
  getProvincias,
  getTiposNif,
  getMenus,
  getPedidoActivo,
  createMessage,
})(RegistroCompleto);
