import axios from "../axiosConfig";

import {
  FETCH_RUTAS,
  GET_RUTAS,
  SET_RUTA_REPARTO,
} from "../actions/types.js";

import { tokenConfig } from "./auth";

// GET ARTICULOS
export const getRutas = () => (dispatch, getState) => {
  dispatch({
    type: FETCH_RUTAS,
    payload: true,
  });
  axios
    .get(`/rutas/`, tokenConfig(getState))
    .then((res) => {
      dispatch({
        type: GET_RUTAS,
        payload: res.data,
      });
    })
    .catch((err) => console.log(err));
};

export const setRutaReparto = (ruta) => (dispatch) => {
  dispatch({
    type: SET_RUTA_REPARTO,
    payload: ruta,
  });
};
