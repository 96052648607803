import axios from "../axiosConfig";

import { FETCH_ARTICULOS, GET_PEDIDO_HABITUAL } from "./types";
import { tokenConfig } from "./auth";

// GET ARTICULOS
export const getPedidoHabitual = (hash = "") => (dispatch, getState) => {
  dispatch({
    type: FETCH_ARTICULOS,
    payload: true,
  });
  axios
    .get(`/articulos/habitualCheckout/?inv=${hash}`, tokenConfig(getState))
    .then((res) => {
      dispatch({
        type: GET_PEDIDO_HABITUAL,
        payload: res.data,
      });
    })
    .catch((err) => console.log(err));
};
