import React, { Component, Fragment } from "react";
import { connect } from "react-redux";
import { deleteLinea } from "../../actions/pedidos";
import PropTypes from "prop-types";
import { Link } from "react-router-dom";

import Imagen from "./Imagen";
import Precio from "./Precio";

import "../../css/carrito.css";

export class Carrito extends Component {
  static propTypes = {
    pedido: PropTypes.object.isRequired,
    deleteLinea: PropTypes.func.isRequired,
    hash: PropTypes.string,
    invitado: PropTypes.bool,
    locale: PropTypes.string.isRequired,
    moneda: PropTypes.string.isRequired,
  };

  onClick = (e) => {
    e.preventDefault();
    this.props.deleteLinea(e.target.dataset.linea, this.props.hash);
  };

  ocultarCarrito = (e) => {
    if (document.getElementsByClassName("tt-dropdown-obj active").length > 0) {
      document
        .getElementsByClassName("tt-dropdown-obj active")[0]
        .getElementsByClassName("tt-dropdown-menu")[0].style = "";

      document
        .getElementsByClassName("tt-dropdown-obj active")[0]
        .classList.remove("active");
    }
    if (document.getElementsByTagName("body").length > 0) {
      document
        .getElementsByTagName("body")[0]
        .classList.remove("tt-popup-dropdown");
    }
  };

  render() {
    return (
      <Fragment>
        <div className="tt-dropdown-menu">
          <div className="tt-mobile-add">
            <h6 className="tt-title">CARRITO</h6>
            <button className="tt-close">Cerrar</button>
          </div>
          <div className="tt-dropdown-inner">
            <div className="tt-cart-layout">
              <div className="tt-cart-content">
                <div className="tt-cart-list">
                  {this.props.pedido.lineas === undefined ||
                  this.props.pedido.lineas.length === 0
                    ? ""
                    : this.props.pedido.lineas.map((linea) => (
                        <div className="tt-item" key={linea.id}>
                          {linea.tipo_articulo !== "F" ? (
                            <Fragment>
                              <Link
                                to={
                                  "/articulo/" +
                                  linea.id_articulo +
                                  "/" +
                                  linea.descripcion
                                    .replaceAll("/", " ")
                                    .replaceAll(" ", "-")
                                    .replaceAll("%", "")
                                    .toLowerCase()
                                }
                              >
                                <div className="tt-item-img">
                                  <Imagen
                                    codigo_articulo={linea.articulo}
                                    principal="S"
                                    numero_imagen={1}
                                  />
                                </div>
                                <div className="tt-item-descriptions">
                                  <h2 className="tt-title">{linea.articulo}</h2>
                                  <ul className="tt-add-info">
                                    <li>{linea.descripcion}</li>
                                    <li className="ocultar">
                                      Pesentación: {linea.presentacion_pedido}
                                    </li>
                                  </ul>
                                  <div className="tt-quantity">
                                    {linea.cantidad_pedida + " X "}
                                  </div>
                                  <div className="tt-price">
                                    {linea.precio_manual === "S" ? (
                                      <Precio
                                        codigo_articulo={linea.articulo}
                                        precio_manual={linea.precio_venta}
                                      />
                                    ) : (
                                      <Precio
                                        codigo_articulo={linea.articulo}
                                      />
                                    )}
                                  </div>
                                </div>
                              </Link>

                              <div className="tt-item-close">
                                <a
                                  href="/#"
                                  onClick={this.onClick}
                                  data-linea={linea.id}
                                  className="tt-btn-close"
                                >
                                  <span className="ocultar">Cerrar</span>
                                </a>
                              </div>
                            </Fragment>
                          ) : (
                            <Fragment>
                              {" "}
                              <div className="tt-item-img">
                                <Imagen
                                  codigo_articulo={linea.articulo}
                                  principal="S"
                                  numero_imagen={1}
                                />
                              </div>
                              <div className="tt-item-descriptions">
                                <h2 className="tt-title">{linea.articulo}</h2>
                                <ul className="tt-add-info">
                                  <li>{linea.descripcion}</li>
                                  <li className="ocultar">
                                    Pesentación: {linea.presentacion_pedido}
                                  </li>
                                </ul>
                                <div className="tt-quantity">
                                  {linea.cantidad_pedida + " X "}
                                </div>
                                <div className="tt-price">
                                  {linea.precio_manual === "S" ? (
                                    <Precio
                                      codigo_articulo={linea.articulo}
                                      precio_manual={linea.precio_venta}
                                    />
                                  ) : (
                                    <Precio codigo_articulo={linea.articulo} />
                                  )}
                                </div>
                              </div>
                            </Fragment>
                          )}
                        </div>
                      ))}
                </div>
                <div className="tt-cart-total-row">
                  <div className="tt-cart-total-title">TOTAL:</div>
                  <div className="tt-cart-total-price">
                    {this.props.pedido.lineas === undefined ||
                    this.props.pedido.lineas.length === 0
                      ? 0
                      : Intl.NumberFormat(this.props.locale, {
                          style: "currency",
                          currency: this.props.moneda,
                        }).format(
                          Number.parseFloat(
                            this.props.pedido.lineas
                              .map((linea) => {
                                return Number.parseFloat(linea.precio_venta);
                              })
                              .reduce(
                                (accumulator, currentValue) =>
                                  accumulator + currentValue
                              )
                          ).toFixed(2)
                        )}
                  </div>
                </div>
                <div className="tt-cart-btn">
                  <div className="tt-item">
                    {this.props.pedido.lineas === undefined ||
                    this.props.pedido.lineas.length === 0 ? (
                      "No hay articulos en el carrito"
                    ) : (
                      <Fragment>
                        {this.props.invitado !== undefined &&
                        this.props.invitado === true ? (
                          <Link
                            to="/login"
                            className="btn"
                            onClick={this.ocultarCarrito}
                          >
                            LOGIN
                          </Link>
                        ) : (
                          <Link
                            to="/resumen"
                            className="btn"
                            onClick={this.ocultarCarrito}
                          >
                            FINALIZAR PEDIDO
                          </Link>
                        )}
                      </Fragment>
                    )}
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </Fragment>
    );
  }
}

const mapStateToProps = (state) => ({
  pedido: state.pedidos.pedido,
  hash: state.auth.hash,
  invitado: state.auth.invitado,
  locale: state.precio.locale,
  moneda: state.precio.moneda,
  precio: state.precio.precio,
});

export default connect(mapStateToProps, { deleteLinea })(Carrito);
