import axios from "../axiosConfig";

import { GET_PROVINCIAS } from "./types";
import { tokenConfig } from "./auth";

// GET PROVINCIA
export const getProvincias = (estado) => (dispatch, getState) => {
  axios
    .get(`/provincias/?estado=${estado}`, tokenConfig(getState))
    .then((res) => {
      dispatch({
        type: GET_PROVINCIAS,
        payload: res.data,
      });
    })
    .catch((err) => console.log(err));
};
