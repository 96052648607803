import React, { Component, Fragment } from "react";
import { connect } from "react-redux";
import PropTypes from "prop-types";
import { limpiarFiltros, añadirFiltro } from "../../actions/filtros";
import { setBusqueda } from "../../actions/articulos";
import { withRouter } from "react-router";

import "../../css/familias.css";

export class FamiliasMenu extends Component {
  static propTypes = {
    familias: PropTypes.array.isRequired,
    filtros_aplicados: PropTypes.array.isRequired,
    limpiarFiltros: PropTypes.func.isRequired,
    añadirFiltro: PropTypes.func.isRequired,
  };

  componentDidUpdate() {
    window.wookie();
  }

  anadirFiltro = (e) => {
    let _estadistico = e.target.dataset.estadistico;
    let _familia = e.target.dataset.codigo;
    let _nombre = e.target.dataset.familia;

    this.props.limpiarFiltros();

    this.props.setBusqueda("");

    this.props.añadirFiltro({
      estadistico: _estadistico,
      familia: _familia,
      nombre: _nombre,
    });

    if (this.props.history.location.pathname !== "/articulos") {
      this.props.history.push("/articulos");
    }
  };

  onClick = (e) => {
    e.preventDefault();

    this.anadirFiltro(e);
  };

  onClickHijo = (e) => {
    e.preventDefault();

    this.anadirFiltro(e);

    window.cerrar_hijos(e.target.parentNode);
  };

  preventClick(e) {
    e.preventDefault();

    window.abrir_hijos(e.target.parentNode);
  }

  desplegarFamilias = (e) => {
    e.preventDefault();
    document
      .getElementsByClassName("familias-primer-nivel")[0]
      .classList.toggle("visible");
  };

  render() {
    return (
      <Fragment>
        <header className="menu-familias">
          <div className="tt-color-scheme-01">
            <div className="container">
              <div className="tt-header-holder">
                <div className="tt-obj-menu">
                  <div className="tt-desctop-parent-menu tt-parent-box">
                    <div className="tt-desctop-menu tt-hover-02">
                      <nav>
                        <div className="toggle-familias-div">
                          <span
                            className="toggle-familias-button"
                            onClick={this.desplegarFamilias}
                          >
                            CATEGORÍAS <i className="icon-e-01"></i>
                          </span>
                        </div>
                        <ul className="familias-primer-nivel">
                          {this.props.familias === undefined
                            ? ""
                            : this.props.familias.map((familia, index) => {
                                if (familia.nivel === 1) {
                                  return (
                                    <li
                                      key={index}
                                      className={
                                        this.props.familias
                                          .filter(
                                            (fam) =>
                                              fam.codigo_padre ===
                                              familia.codigo_familia
                                          )
                                          .reduce(
                                            (accumulator, currentValue) =>
                                              accumulator + 1,
                                            0
                                          ) > 0
                                          ? "dropdown tt-megamenu tt-submenu"
                                          : "dropdown tt-megamenu "
                                      }
                                    >
                                      <a
                                        href=""
                                        data-familia={familia.descripcion}
                                        data-estadistico={familia.numero_tabla}
                                        data-codigo={familia.codigo_familia}
                                        onClick={
                                          familia.ultimo_nivel == "S"
                                            ? this.onClick
                                            : this.preventClick
                                        }
                                      >
                                        {familia.descripcion}{" "}
                                        {this.props.familias
                                          .filter(
                                            (fam) =>
                                              fam.codigo_padre ===
                                              familia.codigo_familia
                                          )
                                          .reduce(
                                            (accumulator, currentValue) =>
                                              accumulator + 1,
                                            0
                                          ) > 0 ? (
                                          <i className="icon-e-01"></i>
                                        ) : (
                                          ""
                                        )}
                                      </a>
                                      {this.props.familias === undefined ? (
                                        ""
                                      ) : this.props.familias
                                          .filter(
                                            (fam) =>
                                              fam.codigo_padre ===
                                              familia.codigo_familia
                                          )
                                          .reduce(
                                            (accumulator, currentValue) =>
                                              accumulator + 1,
                                            0
                                          ) > 0 ? (
                                        <div className="dropdown-menu">
                                          <div className="row tt-col-list">
                                            <div className="col">
                                              <ul className="tt-megamenu-submenu">
                                                {this.props.familias.map(
                                                  (hijo, hijo_index) => {
                                                    if (
                                                      hijo.codigo_padre ===
                                                      familia.codigo_familia
                                                    ) {
                                                      return (
                                                        <li key={hijo_index}>
                                                          <a
                                                            href="/#"
                                                            data-familia={
                                                              hijo.descripcion
                                                            }
                                                            data-estadistico={
                                                              hijo.numero_tabla
                                                            }
                                                            data-codigo={
                                                              hijo.codigo_familia
                                                            }
                                                            onClick={
                                                              this.onClickHijo
                                                            }
                                                          >
                                                            {hijo.descripcion}
                                                          </a>
                                                        </li>
                                                      );
                                                    }
                                                    return "";
                                                  }
                                                )}
                                              </ul>
                                            </div>
                                          </div>
                                        </div>
                                      ) : (
                                        ""
                                      )}
                                    </li>
                                  );
                                }
                                return "";
                              })}
                        </ul>
                      </nav>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </header>
      </Fragment>
    );
  }
}

const mapStateToProps = (state) => ({
  familias: state.articulos.familias,
  filtros_aplicados: state.articulos.filtros_aplicados,
});

export default withRouter(
  connect(mapStateToProps, {
    limpiarFiltros,
    añadirFiltro,
    setBusqueda,
  })(FamiliasMenu)
);
