import React, { Component, Fragment } from "react";
import { connect } from "react-redux";
import PropTypes from "prop-types";
import { getRutas, setRutaReparto } from "../../actions/rutas";

export class Rutas extends Component {
  static propTypes = {
    getRutas: PropTypes.func.isRequired,
    setRutaReparto: PropTypes.func.isRequired,
    rutas: PropTypes.array.isRequired,
    ruta: PropTypes.object.isRequired,
  };

  componentDidMount() {
    this.props.getRutas();
  }

  componentDidUpdate(prevProps) {
    if (prevProps.rutas !== this.props.rutas) {
      if (this.props.rutas.length === 1) {
        this.props.setRutaReparto(this.props.rutas[0]);
      }
    }
  }

  onchange = (e) => {
    const ruta_reparto = this.props.rutas.filter(
      (ruta) => ruta.ruta === e.target.value
    );

    this.props.setRutaReparto(ruta_reparto[0]);
  };

  render() {
    return (
      <Fragment>
        <div className="tt-shopcart-box">
          <h4 className="tt-title">RUTAS DE REPARTO</h4>
          <p>Seleccione la ruta de reparto</p>
          <form className="form-default">
            <div className="form-group">
              <label htmlFor="ruta">
                RUTA DE REPARTO<sup>*</sup>
              </label>
              {this.props.rutas.length > 0 ? (
                <select
                  className="form-control"
                  name="ruta"
                  onChange={this.onchange}
                >
                  <option key="0" value=""></option>
                  {this.props.rutas.map((ruta, index) => {
                    if (ruta.ruta === this.props.ruta.ruta) {
                      return (
                        <option key={index} value={ruta.ruta} selected>
                          {ruta.descripcion}
                        </option>
                      );
                    } else {
                      return (
                        <option key={index} value={ruta.ruta}>
                          {ruta.descripcion}
                        </option>
                      );
                    }
                  })}
                </select>
              ) : (
                <p>No existen rutas de entrega disponibles</p>
              )}
            </div>
          </form>
        </div>
      </Fragment>
    );
  }
}

const mapStateToProps = (state) => ({
  rutas: state.rutas.rutas,
  ruta: state.rutas.ruta,
});

export default connect(mapStateToProps, { getRutas, setRutaReparto })(Rutas);
