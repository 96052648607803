import {
  GET_PORTAL,
  GET_PORTAL_SUCCESS,
  GET_PORTAL_FAIL,
  GET_MENUS,
  GET_MENUS_SUCCESS,
  GET_MENUS_FAIL,
} from "../actions/types.js";

const initialState = {
  portal: {},
  logo: null,
  menus: [],
  isLoadingPortal: false,
  isLoadingMenus: false,
};

export default function (state = initialState, action) {
  switch (action.type) {
    case GET_PORTAL:
      return {
        ...state,
        isLoadingPortal: true,
      };
    case GET_PORTAL_SUCCESS:
      return {
        ...state,
        portal: action.payload,
        isLoadingPortal: false,
      };
    case GET_PORTAL_FAIL:
      return {
        ...state,
        portal: {},
        isLoadingPortal: false,
      };
    case GET_MENUS:
      return {
        ...state,
        isLoadingMenus: true,
      };
    case GET_MENUS_SUCCESS:
      return {
        ...state,
        menus: action.payload,
        isLoadingMenus: false,
      };
    case GET_MENUS_FAIL:
      return {
        ...state,
        menus: [],
        isLoadingMenus: false,
      };
    default:
      return state;
  }
}
