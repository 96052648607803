import {
  GET_TIPOS_EXPEDIENTES,
  GET_TIPOS_EXPEDIENTES_CAMPOS,
  GET_EXPEDIENTES,
} from "../actions/types";

const initialState = {
  tipos_expedientes: [],
  tipos_expedientes_campos: [],
  expedientes: [],
};

export default function (state = initialState, action) {
  switch (action.type) {
    case GET_TIPOS_EXPEDIENTES:
      return {
        ...state,
        isLoading: false,
        tipos_expedientes: action.payload,
      };

    case GET_TIPOS_EXPEDIENTES_CAMPOS:
      return {
        ...state,
        isLoading: false,
        tipos_expedientes_campos: action.payload,
      };

    case GET_EXPEDIENTES:
      return {
        ...state,
        isLoading: false,
        expedientes: action.payload,
      };

    default:
      return state;
  }
}
