import React, { Component, Fragment } from "react";
import { connect } from "react-redux";
import PropTypes from "prop-types";
import {
  getMenuInformes,
  getDatosInforme,
  getFiltrosPluginsInforme,
  obtenerEFactura,
  obtenerReport,
  imprimirInforme,
  getMasDatosInforme,
} from "../../actions/informes";

import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faSearch } from "@fortawesome/free-solid-svg-icons";

import { library } from "@fortawesome/fontawesome-svg-core";
//import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import * as Icons from "@fortawesome/free-solid-svg-icons";

import InformesMenu from "./InformesMenu";
import InformesFiltroField from "./InformesFiltroField";
import { InfromesFiltroFecha } from "./InformesFiltroFecha";
import InformesFiltroLOV from "./InformesFiltroLOV";

import "../../css/informes.css";

const iconList = Object.keys(Icons)
  .filter((key) => key !== "fas" && key !== "prefix")
  .map((icon) => Icons[icon]);

library.add(...iconList);

export class Informes extends Component {
  state = {
    inicio: 1,
    fin: 10,
  };

  static propTypes = {
    informes: PropTypes.array.isRequired,
    informe_seleccionado: PropTypes.string.isRequired,
    datos: PropTypes.array.isRequired,
    numero_registros: PropTypes.number.isRequired,
    filtros: PropTypes.array.isRequired,
    plugins: PropTypes.array.isRequired,
    permitir_excel: PropTypes.string.isRequired,
    getMenuInformes: PropTypes.func.isRequired,
    getFiltrosPluginsInforme: PropTypes.func.isRequired,
    obtenerEFactura: PropTypes.func.isRequired,
    obtenerReport: PropTypes.func.isRequired,
  };

  componentDidMount() {
    this.props.getMenuInformes();
  }

  componentDidUpdate(prevProps) {
    window.wookie();

    if (this.props.filtros !== prevProps.filtros) {
      let hay_filtro = this.props.filtros.some(
        (filtro) =>
          filtro.campo_de_filtro === "S" &&
          (filtro.tipo_filtro !== "E" || filtro.filtro_calendario === "S")
      );

      if (!hay_filtro) {
        let submit_formulario = document.getElementById(
          "filtros_informe_submit"
        );

        if (submit_formulario !== null) {
          submit_formulario.click();
        }
      }
    }
  }

  onPlugin = (e) => {
    e.preventDefault();

    const param = e.target.dataset;

    const plugin = this.props.plugins.filter(
      (plugin) => plugin.plugin.codigo === param.codigo
    )[0];
    const datos = this.props.datos[param.datos];

    let filtro = [];
    for (let i = 0; i < plugin.filtros_plugin.length; i++) {
      if (plugin.filtros_plugin[i].tipo_parametro === "C") {
        filtro.push({
          nombre: plugin.filtros_plugin[i].parametro,
          valor: plugin.filtros_plugin[i].valor_parametro,
        });
      } else {
        filtro.push({
          nombre: plugin.filtros_plugin[i].parametro,
          valor: datos[plugin.filtros_plugin[i].valor_parametro],
        });
      }
    }

    if (param.informe !== "") {
      const filtros = {
        filtro: filtro,
        inicio: this.state.inicio,
        fin: this.state.fin,
      };

      this.props.getFiltrosPluginsInforme(plugin.plugin.informe_llamado);

      this.props.getDatosInforme(plugin.plugin.informe_llamado, filtros);
    }

    if (param.programa === "efactura") {
      this.props.obtenerEFactura(
        filtro[0].valor,
        filtro[2].valor,
        filtro[1].valor
      );
    }

    if (param.report !== "") {
      this.props.obtenerReport(param.report, filtro);
    }
  };

  obtenerFiltros = function () {
    let filtro = [];
    for (let i = 0; i < this.props.filtros.length; i++) {
      if (this.props.filtros[i].campo_de_filtro === "S") {
        if (this.props.filtros[i].tipo_filtro === "I") {
          filtro.push({
            nombre: this.props.filtros[i].nombre_filtro + "_HASTA",
            valor: document.getElementById("filtros_informe").elements[
              this.props.filtros[i].nombre_filtro + "_HASTA"
            ].value,
          });
          filtro.push({
            nombre: this.props.filtros[i].nombre_filtro + "_DESDE",
            valor: document.getElementById("filtros_informe").elements[
              this.props.filtros[i].nombre_filtro + "_DESDE"
            ].value,
          });
        } else if (this.props.filtros[i].tipo_filtro !== "E") {
          filtro.push({
            nombre: this.props.filtros[i].nombre_filtro,
            valor: document.getElementById("filtros_informe").elements[
              this.props.filtros[i].nombre_filtro
            ].value,
          });
        }
      }
    }
    return filtro;
  };

  onSubmit = (e) => {
    e.preventDefault();

    let filtro = this.obtenerFiltros();

    this.setState({
      inicio: 1,
      fin: 10,
    });

    const filtros = {
      filtro: filtro,
      inicio: 1,
      fin: 10,
    };

    this.props.getDatosInforme(this.props.informe_seleccionado, filtros);
  };

  onPrint = (e) => {
    e.preventDefault();

    let tipo_salida = e.target.value;

    if (tipo_salida !== "") {
      let filtro = this.obtenerFiltros();

      const filtros = {
        filtro: filtro,
        inicio: this.state.inicio,
        fin: this.state.fin,
      };

      this.props.imprimirInforme(
        this.props.informe_seleccionado,
        filtros,
        tipo_salida
      );
    }
  };

  masRegistros = (e) => {
    e.preventDefault();

    var nuevo_inicio = this.state.inicio + 10;
    var nuevo_fin = this.state.fin + 10;

    this.setState({
      inicio: nuevo_inicio,
      fin: nuevo_fin,
    });

    let filtro = this.obtenerFiltros();

    const filtros = {
      filtro: filtro,
      inicio: nuevo_inicio,
      fin: nuevo_fin,
    };

    this.props.getMasDatosInforme(this.props.informe_seleccionado, filtros);
  };

  render() {
    const filtrosOcultos = {
      display: "none",
    };
    return (
      <Fragment>
        <InformesMenu />
        <div className="container contenedor_informes">
          {this.props.filtros === undefined ||
          this.props.filtros.length === 0 ? (
            <p className="sel_informe_txt">Seleccione un informe del menú</p>
          ) : (
            <Fragment>
              <div
                className="tt-collapse-block"
                style={
                  this.props.filtros.some(
                    (filtro) =>
                      filtro.campo_de_filtro === "S" &&
                      (filtro.tipo_filtro !== "E" ||
                        filtro.filtro_calendario === "S")
                  ) !== true
                    ? { display: "none" }
                    : {}
                }
              >
                <div
                  className={
                    this.props.datos.length === 0 ? "tt-item active" : "tt-item"
                  }
                >
                  <div className="tt-collapse-title">FILTROS</div>
                  <div
                    className="tt-collapse-content"
                    style={
                      this.props.datos.length === 0
                        ? { display: "block" }
                        : filtrosOcultos
                    }
                  >
                    <form
                      className="form-default"
                      id="filtros_informe"
                      onSubmit={this.onSubmit}
                    >
                      {this.props.filtros.map((filtro) => {
                        if (filtro.campo_de_filtro === "S") {
                          if (filtro.filtro_calendario === "S") {
                            return (
                              <InfromesFiltroFecha
                                key={filtro.id_columna}
                                filtro={filtro}
                              />
                            );
                          } else if (
                            filtro.lista_valores.length !== 0 &&
                            filtro.tipo_filtro !== "E"
                          ) {
                            return (
                              <InformesFiltroLOV
                                key={filtro.id_columna}
                                filtro={filtro}
                              />
                            );
                          } else if (filtro.tipo_filtro !== "E") {
                            return (
                              <InformesFiltroField
                                key={filtro.id_columna}
                                filtro={filtro}
                              />
                            );
                          }
                        }
                        return "";
                      })}
                      <button
                        type="submit"
                        className="btn btn-primary"
                        id="filtros_informe_submit"
                      >
                        <FontAwesomeIcon icon={faSearch} className="mr-2" />{" "}
                        ENVIAR
                      </button>
                    </form>
                  </div>
                </div>
              </div>
            </Fragment>
          )}
          {this.props.datos.length === 0 ? (
            ""
          ) : (
            <Fragment>
              <div className="tt-shopcart-table informes_desctop">
                <div className="col-sm-12 col-md-12 col-lg-12">
                  <div className="row imprimir">
                    <div className="col-sm-12 col-md-2 col-lg-2">
                      <select onChange={this.onPrint}>
                        <option value="">IMPRIMIR</option>
                        {this.props.permitir_excel !== undefined &&
                        this.props.permitir_excel === "S" ? (
                          <option value="XLS">EXCEL</option>
                        ) : (
                          ""
                        )}
                        {/* <option value="PDF">PDF</option> */}
                        <option value="HTML">HTML</option>
                      </select>
                    </div>
                  </div>

                  <div className="row titulos">
                    {this.props.filtros.map((cab, ind) => {
                      if (cab.tipo_filtro !== "E") {
                        return (
                          <div
                            style={
                              Number.parseInt(cab.ancho_web) !== 0
                                ? {
                                    width: cab.ancho_web + "%",
                                  }
                                : { display: "none" }
                            }
                            className={"col"}
                            key={"cab_" + ind}
                          >
                            {cab.titulo_columna}
                          </div>
                        );
                      }
                      return "";
                    })}
                    {this.props.plugins.length === 0 ? null : (
                      <div
                        className="text-left col-plugins col"
                        style={{
                          width: "10%",
                        }}
                      ></div>
                    )}
                  </div>

                  {this.props.datos.map((fila, ind) => (
                    <div className="row" key={"fil_" + ind}>
                      {this.props.filtros.map((filtro, ind) => {
                        if (filtro.tipo_filtro !== "E") {
                          return (
                            <div
                              style={
                                Number.parseInt(filtro.ancho_web) !== 0
                                  ? {
                                      width: filtro.ancho_web + "%",
                                    }
                                  : { display: "none" }
                              }
                              className={"col"}
                              key={"col_" + ind}
                            >
                              {fila[filtro.alias_columna]}
                            </div>
                          );
                        }
                        return "";
                      })}
                      {this.props.plugins.length === 0 ? null : (
                        <div
                          className="text-left col-plugins col"
                          style={{
                            width: "10%",
                          }}
                        >
                          {this.props.plugins.map((plugin) => (
                            <button
                              className="btn btn-primary btn-plugin"
                              key={plugin.plugin.codigo}
                              onClick={this.onPlugin}
                              data-datos={ind}
                              data-codigo={plugin.plugin.codigo}
                              data-informe={plugin.plugin.informe_llamado}
                              data-programa={plugin.plugin.programa_llamado}
                              data-report={plugin.plugin.report_llamado}
                            >
                              {plugin.plugin.icono !== "" ? (
                                <FontAwesomeIcon icon={plugin.plugin.icono} />
                              ) : (
                                ""
                              )}
                              {plugin.plugin.descripcion}
                            </button>
                          ))}
                        </div>
                      )}
                    </div>
                  ))}
                </div>
              </div>

              <div className="tt-shopcart-table informes_mobile">
                <div className="col-sm-12 col-md-12 col-lg-12">
                  <div className="row imprimir">
                    <div className="col-sm-12 col-md-2 col-lg-2">
                      <select onChange={this.onPrint}>
                        <option value="">IMPRIMIR</option>
                        <option value="XLS">EXCEL</option>
                        {/* <option value="PDF">PDF</option> */}
                        <option value="HTML">HTML</option>
                      </select>
                    </div>
                  </div>

                  {this.props.datos.map((fila, ind) => (
                    <div className="row" key={"fil_" + ind}>
                      <div className="col-sm-12 col-md-12 col-lg-12">
                        {this.props.filtros.map((filtro, ind) => {
                          if (filtro.tipo_filtro !== "E") {
                            return (
                              <div className="row">
                                <div
                                  className="col-6 titulos"
                                  style={
                                    Number.parseInt(filtro.ancho_web) !== 0
                                      ? {
                                          width: filtro.ancho_web + "%",
                                        }
                                      : { display: "none" }
                                  }
                                >
                                  {filtro.titulo_columna}
                                </div>
                                <div
                                  style={
                                    Number.parseInt(filtro.ancho_web) !== 0
                                      ? {
                                          width: filtro.ancho_web + "%",
                                        }
                                      : { display: "none" }
                                  }
                                  className={"col-6"}
                                  key={"col_" + ind}
                                >
                                  {fila[filtro.alias_columna]}
                                </div>
                              </div>
                            );
                          }
                          return "";
                        })}

                        {this.props.plugins.length === 0 ? null : (
                          <div className="row">
                            <div className=" col-plugins col">
                              {this.props.plugins.map((plugin) => (
                                <button
                                  className="btn btn-primary btn-plugin"
                                  key={plugin.plugin.codigo}
                                  onClick={this.onPlugin}
                                  data-datos={ind}
                                  data-codigo={plugin.plugin.codigo}
                                  data-informe={plugin.plugin.informe_llamado}
                                  data-programa={plugin.plugin.programa_llamado}
                                  data-report={plugin.plugin.report_llamado}
                                >
                                  {plugin.plugin.icono !== "" ? (
                                    <FontAwesomeIcon
                                      icon={plugin.plugin.icono}
                                    />
                                  ) : (
                                    ""
                                  )}
                                  {plugin.plugin.descripcion}
                                </button>
                              ))}
                            </div>
                          </div>
                        )}
                      </div>
                    </div>
                  ))}
                </div>
              </div>
            </Fragment>
          )}
          {this.props.datos.length ===
            Number.parseInt(this.props.numero_registros) ||
          this.state.inicio >= Number.parseInt(this.props.numero_registros) ? (
            ""
          ) : (
            <div className="text-center mt-5">
              <button onClick={this.masRegistros} className="btn btn-border">
                MOSTRAR MÁS
              </button>
            </div>
          )}
        </div>
      </Fragment>
    );
  }
}
//'<i className="' + plugin.plugin.icono + '"></i> '
const mapStateToProps = (state) => ({
  informes: state.informes.informes,
  informe_seleccionado: state.informes.informe_seleccionado,
  datos: state.informes.datos,
  numero_registros: state.informes.numero_registros,
  filtros: state.informes.filtros,
  plugins: state.informes.plugins,
  permitir_excel: state.informes.permitir_excel,
});

export default connect(mapStateToProps, {
  getMenuInformes,
  getDatosInforme,
  getFiltrosPluginsInforme,
  obtenerEFactura,
  obtenerReport,
  imprimirInforme,
  getMasDatosInforme,
})(Informes);
