import React, { Component, Fragment } from "react";
import { connect } from "react-redux";
import PropTypes from "prop-types";
import { postLinea } from "../../actions/pedidos";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faCartPlus } from "@fortawesome/free-solid-svg-icons";
import { getPrecio } from "../../actions/precio";

import "../../css/AnadirCarrito.css";

export class AnadirCarrito extends Component {
  state = {
    valor: "1",
    articulo: this.props.articulo,
  };

  static propTypes = {
    postLinea: PropTypes.func.isRequired,
    getPrecio: PropTypes.func.isRequired,
    precio: PropTypes.object.isRequired,
    hash: PropTypes.string,
    pedido: PropTypes.object.isRequired,
  };

  componentDidUpdate(prevProps, prevState) {
    if (prevProps.articulo !== this.props.articulo) {
      this.setState({ articulo: this.props.articulo });
    }
  }

  onChange = (e) => {
    this.setState({ [e.target.name]: e.target.value });
    if (
      e.target.value !== undefined &&
      e.target.value !== null &&
      e.target.value !== "" &&
      Number.parseInt(e.target.value) !== 0
    ) {
      this.props.getPrecio(
        this.props.articulo.codigo_articulo,
        this.props.hash != null ? this.props.hash : "",
        e.target.value
      );
    }
  };

  onSubmit = (e) => {
    if (!this.props.pedido_cargando) {
      e.preventDefault();
      const { valor, articulo } = this.state;

      if (this.props.precio[articulo.codigo_articulo].data !== undefined) {
        const linea = {
          articulo: articulo.codigo_articulo,
          descripcion: articulo.descrip_comercial,
          precio_venta: parseFloat(
            this.props.precio[articulo.codigo_articulo].data.precio_neto
          ),
          observaciones: "",
          estado_linea: "B",
          cantidad_pedida: valor,
          presentacion_pedido: articulo.presentacion_web,
          tipo_linea: "P",
        };

        this.props.postLinea(linea, this.props.hash);

        this.setState({
          valor: 1,
        });
      }
    }
  };

  changeQuantity = (e) => {
    e.preventDefault();
    var input = e.target.parentNode.getElementsByClassName("quantity-input")[0];
    var count =
      parseInt(input.value, 10) +
      parseInt(e.currentTarget.className === "plus-btn" ? 1 : -1, 10);

    var value = Math.max(count, 1);

    this.setState({
      valor: value,
    });

    if (
      value !== undefined &&
      value !== null &&
      value !== "" &&
      Number.parseInt(value) !== 0
    ) {
      this.props.getPrecio(
        this.props.articulo.codigo_articulo,
        this.props.hash != null ? this.props.hash : "",
        value
      );
    }
  };

  render() {
    const { valor, articulo } = this.state;

    if (
      this.props.precio[articulo.codigo_articulo] !== undefined &&
      this.props.precio[articulo.codigo_articulo].isLoading === false &&
      this.props.precio[articulo.codigo_articulo].error === false
    ) {
      return (
        <Fragment>
          <form
            onSubmit={this.onSubmit}
            className="form-inline form-default control_anadir_carrito"
          >
            <div className="input-group">
              <input
                className="form-control desctop"
                type="text"
                name="valor"
                onChange={this.onChange}
                value={valor}
                placeholder="Cantidad"
              />
              <div className="detach-quantity-desctope mobile">
                <div className="tt-input-counter style-01">
                  <span
                    className="minus-btn"
                    onClick={this.changeQuantity}
                  ></span>
                  <input
                    className="quantity-input"
                    type="text"
                    name="valor"
                    value={valor}
                    size="5"
                    onChange={this.onChange}
                  />
                  <span
                    className="plus-btn"
                    onClick={this.changeQuantity}
                  ></span>
                </div>
              </div>
              <div className="input-group-append">
                <button type="submit" className="btn btn-primary">
                  <FontAwesomeIcon icon={faCartPlus} className="mr-2" />
                  {this.props.mostrar_texto_btn === "S" ? "AÑADIR" : ""}
                </button>
              </div>
            </div>
          </form>
        </Fragment>
      );
    } else {
      return <Fragment></Fragment>;
    }
  }
}

const mapStateToProps = (state) => ({
  precio: state.precio.precio,
  hash: state.auth.hash,
  pedido: state.pedidos.pedido,
  pedido_cargando: state.pedidos.isLoading,
});

export default connect(mapStateToProps, { postLinea, getPrecio })(
  AnadirCarrito
);
