import React, { Component, Fragment } from "react";
import { connect } from "react-redux";
import PropTypes from "prop-types";
import { getTiposPedido, setTipoPedido } from "../../actions/tiposPedido";

export class TiposPedido extends Component {
  static propTypes = {
    getTiposPedido: PropTypes.func.isRequired,
    setTipoPedido: PropTypes.func.isRequired,
    tipos_pedido: PropTypes.array.isRequired,
    tipo_pedido: PropTypes.object.isRequired,
    pedido_cargando: PropTypes.bool.isRequired,
  };

  componentDidMount() {
    this.props.getTiposPedido();
  }

  componentDidUpdate(prevProps) {
    if (
      (prevProps.pedido_cargando !== this.props.pedido_cargando ||
        prevProps.tipos_pedido !== this.props.tipos_pedido) &&
      !this.props.pedido_cargando
    ) {
      if (
        this.props.tipos_pedido !== undefined &&
        this.props.tipos_pedido.length === 1
      ) {
        this.props.setTipoPedido(this.props.tipos_pedido[0]);
      }
    }
  }

  onchange = (e) => {
    const tipo_pedido = this.props.tipos_pedido.filter(
      (tipo_pedido) => tipo_pedido.tipo_pedido === e.target.value
    );

    this.props.setTipoPedido(tipo_pedido[0]);
  };

  render() {
    return (
      <Fragment>
        {this.props.tipos_pedido !== undefined &&
        this.props.tipos_pedido.length > 0 ? (
          <div className="tt-shopcart-box">
            <h4 className="tt-title">TIPO DE PEDIDO</h4>
            <p>Seleccione el tipo de pedido</p>
            <form className="form-default">
              <div className="form-group">
                <label htmlFor="tipo_pedido">
                  TIPO DE PEDIDO<sup>*</sup>
                </label>

                <select
                  className="form-control"
                  name="tipo_pedido"
                  onChange={this.onchange}
                >
                  <option key="0" value=""></option>
                  {this.props.tipos_pedido.map((tipo_pedido, index) => {
                    if (
                      tipo_pedido.tipo_pedido ===
                        this.props.tipo_pedido.tipo_pedido
                    ) {
                      return (
                        <option
                          key={index}
                          value={tipo_pedido.tipo_pedido}
                          selected
                        >
                          {tipo_pedido.descripcion}
                        </option>
                      );
                    } else {
                      return (
                        <option key={index} value={tipo_pedido.tipo_pedido}>
                          {tipo_pedido.descripcion}
                        </option>
                      );
                    }
                  })}
                </select>
              </div>
            </form>
          </div>
        ) : (
          ""
        )}
      </Fragment>
    );
  }
}

const mapStateToProps = (state) => ({
  tipos_pedido: state.tiposPedido.tipos_pedido,
  tipo_pedido: state.tiposPedido.tipo_pedido,
  pedido_cargando: state.pedidos.isLoading,
});

export default connect(mapStateToProps, { getTiposPedido, setTipoPedido })(
  TiposPedido
);
