import React, { Component, Fragment } from "react";
import { connect } from "react-redux";
import PropTypes from "prop-types";
import { enviarContacto } from "../../actions/contacto";
import config from "../../config";

export class Contacto extends Component {
  state = {
    nombre: "",
    email: "",
    asunto: "",
    mensaje: "",
  };

  static propTypes = {
    enviarContacto: PropTypes.func.isRequired,
    contacto: PropTypes.object.isRequired,
  };

  componentDidMount() {
    if (typeof window.wookie !== undefined) {
      window.wookie();
    } else {
      console.log("No se encuentra librería wookie");
    }
  }

  onSubmit = (e) => {
    e.preventDefault();
    this.props.enviarContacto(
      this.state.nombre,
      this.state.email,
      this.state.asunto,
      this.state.mensaje
    );
    this.setState({
      nombre: "",
      email: "",
      asunto: "",
      mensaje: "",
    });
  };

  onChange = (e) => this.setState({ [e.target.name]: e.target.value });

  render() {
    const { nombre, email, asunto, mensaje } = this.state;

    const iframe = {
      __html: `<iframe src="${config.contacto.map}" width="100%" height="450" frameborder="0" style="border:0;" allowfullscreen="" aria-hidden="false" tabindex="0"></iframe>`,
    };

    return (
      <Fragment>
        <div id="tt-pageContent" className="mb-5">
          {config.contacto.map !== "" ? (
            <div className="container-indent">
              <div className="container">
                <div className="contact-map">
                  <div id="map" dangerouslySetInnerHTML={iframe} />
                </div>
              </div>
            </div>
          ) : (
            ""
          )}

          <div className="container-indent">
            <div className="container container-fluid-custom-mobile-padding">
              <div className="tt-contact02-col-list">
                <div className="row">
                  <div className="col-sm-12 col-md-4 ml-sm-auto mr-sm-auto">
                    <div className="tt-contact-info">
                      <i className="tt-icon icon-f-93"></i>
                      <h6 className="tt-title">TELÉFONOS</h6>
                      <address>{config.contacto.telefono}</address>
                    </div>
                  </div>
                  <div className="col-sm-6 col-md-4">
                    <div className="tt-contact-info">
                      <i className="tt-icon icon-f-24"></i>
                      <h6 className="tt-title">DIRECCIÓN</h6>
                      <address>{config.contacto.direccion}</address>
                    </div>
                  </div>
                  <div className="col-sm-6 col-md-4">
                    <div className="tt-contact-info">
                      <i className="tt-icon icon-f-92"></i>
                      <h6 className="tt-title">HORARIO</h6>
                      <address>{config.contacto.horario}</address>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className="container-indent">
            <div className="container container-fluid-custom-mobile-padding">
              <form
                className="contact-form form-default"
                onSubmit={this.onSubmit}
              >
                <div className="row">
                  <div className="col-md-6">
                    <div className="form-group">
                      <input
                        type="text"
                        name="nombre"
                        onChange={this.onChange}
                        value={nombre}
                        className="form-control"
                        placeholder="Nombre"
                        required
                      />
                    </div>
                    <div className="form-group">
                      <input
                        type="text"
                        name="email"
                        onChange={this.onChange}
                        value={email}
                        className="form-control"
                        placeholder="Email"
                        required
                      />
                    </div>
                    <div className="form-group">
                      <input
                        type="text"
                        name="asunto"
                        onChange={this.onChange}
                        value={asunto}
                        className="form-control"
                        placeholder="Asunto"
                        required
                      />
                    </div>
                  </div>
                  <div className="col-md-6">
                    <div className="form-group">
                      <textarea
                        className="form-control"
                        name="mensaje"
                        onChange={this.onChange}
                        value={mensaje}
                        placeholder="Escriba un mensaje"
                        required
                      />
                    </div>
                  </div>
                </div>
                <div className="text-center">
                  <button type="submit" className="btn">
                    ENVIAR MENSAJE
                  </button>
                </div>
              </form>
            </div>
          </div>
        </div>
      </Fragment>
    );
  }
}

const mapStateToProps = (state) => ({
  contacto: state.contacto,
});

export default connect(mapStateToProps, { enviarContacto })(Contacto);
