import { GET_TIPOS_NIF } from "../actions/types.js";

const initialState = {
  tipos_nif: [],
};

export default function (state = initialState, action) {
  switch (action.type) {
    case GET_TIPOS_NIF:
      return {
        ...state,
        tipos_nif: action.payload,
      };
    default:
      return state;
  }
}
