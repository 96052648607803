import React, { Component, Fragment } from "react";
import { connect } from "react-redux";
import PropTypes from "prop-types";

import { getPestanas, getWidgets } from "../../actions/widgets";
import Pestana from "./Pestana";

import "../../css/widgets.css"

export class Widgets extends Component {
  static propTypes = {
    pestanas: PropTypes.array.isRequired,
    widgets: PropTypes.array.isRequired,
    getPestanas: PropTypes.func.isRequired,
    getWidgets: PropTypes.func.isRequired,
  };

  componentDidMount() {
    this.props.getPestanas();
    this.props.getWidgets();
  }

  componentDidUpdate() {
    window.wookie();
  }

  render() {
    
    return (
      <Fragment>
        <div className="container">
          <div id="tt-pageContent">
            <div className="container-indent">
              <div className="container-fluid-custom container-fluid-custom-mobile-padding">
                {this.props.pestanas !== undefined &&
                this.props.widgets !== undefined &&
                this.props.pestanas.length > 0 &&
                this.props.widgets.length > 0 ? (
                  <Fragment>
                    <nav>
                      <div class="nav nav-tabs" id="nav-tab" role="tablist">
                        {this.props.pestanas.map((pestana) => (
                          <a
                            key={pestana.id_pestana}
                            className={
                              Number.parseInt(pestana.orden) === 1
                                ? "nav-item nav-link active"
                                : "nav-item nav-link"
                            }
                            id={"nav-pestana-" + pestana.id_pestana + "-tab"}
                            data-toggle="tab"
                            href={"#nav-pestana-" + pestana.id_pestana}
                            role="tab"
                            aria-controls={"nav-pestana-" + pestana.id_pestana}
                            aria-selected="true"
                          >
                            {pestana.titulo}
                          </a>
                        ))}
                      </div>
                    </nav>
                    <div className="tab-content" id="nav-tabContent">
                      {this.props.pestanas.map((pestana) => (
                        <Pestana
                          pestana={pestana}
                          widgets={this.props.widgets.filter(
                            (widget) => widget.id_pestana === pestana.id_pestana
                          )}
                        />
                      ))}
                    </div>
                  </Fragment>
                ) : (
                  <h1>No hay widgets disponibles</h1>
                )}
              </div>
            </div>
          </div>
        </div>
      </Fragment>
    );
  }
}

const mapStateToProps = (state) => ({
  pestanas: state.widgets.pestanas,
  widgets: state.widgets.widgets,
});

export default connect(mapStateToProps, {
  getPestanas,
  getWidgets,
})(Widgets);
