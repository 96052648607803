import React, { Component, Fragment } from "react";
import { connect } from "react-redux";
import PropTypes from "prop-types";

export class ExpedientesCreados extends Component {
  static propTypes = {
    expedientes: PropTypes.array.isRequired,
  };

  render() {
    return (
      <Fragment>
        <div className="container">
          <div id="tt-pageContent">
            <div className="container-indent">
              <div className="container">
                <h1 className="tt-title-subpages noborder">LISTADO DE EXPEDIENTES CREADOS</h1>

                <div className="row">
                  <div className="col-sm-12 col-md-12 col-lg-12">
                    {this.props.expedientes !== undefined &&
                    this.props.expedientes.length > 0 ? (
                      <Fragment>
                        <div className="tt-shopcart-table listas_compra_desctop row">
                          <div className="col-sm-12 col-md-12 col-lg-12">
                            <div className="row titulos">
                              <div className="col-sm-12 col-md-3 col-lg-3">
                                USUARIO_WEB
                              </div>
                              <div className="col-sm-12 col-md-3 col-lg-3">
                                TIPO_EXPEDIENTE
                              </div>
                              <div className="col-sm-12 col-md-6 col-lg-6">
                                FECHA_ALTA
                              </div>
                            </div>
                            {this.props.expedientes.map((expediente) => {
                              return (
                                <div className="row" key={expediente.id}>
                                  <div className="col-sm-12 col-md-3 col-lg-3">
                                    {expediente.usuario_web}
                                  </div>
                                  <div className="col-sm-12 col-md-3 col-lg-3">
                                    {expediente.tipo_expediente}
                                  </div>
                                  <div className="col-sm-12 col-md-6 col-lg-6">
                                    {expediente.fecha_alta}
                                  </div>
                                </div>
                              );
                            })}
                          </div>
                        </div>

                        <div className="tt-shopcart-table listas_compra_mobile row">
                          <div className="col-sm-12 col-md-12 col-lg-12">
                            {this.props.expedientes.map((expediente) => {
                              return (
                                <div className="row" key={expediente.id}>
                                  <div className="col-12">
                                    <div className="row ">
                                      <div className="col-6 titulos">
                                        USUARIO WEB
                                      </div>
                                      <div className="col-6">
                                        {expediente.usuario_web}
                                      </div>
                                    </div>

                                    <div className="row ">
                                      <div className="col-6 titulos">
                                        TIPO_EXPEDIENTE
                                      </div>
                                      <div className="col-6">
                                        {expediente.tipo_expediente}
                                      </div>
                                    </div>
                                    <div className="row ">
                                      <div className="col-6 titulos">
                                        FECHA ALTA
                                      </div>
                                      <div className="col-6">
                                        {expediente.fecha_alta}
                                      </div>
                                    </div>
                                  </div>
                                </div>
                              );
                            })}
                          </div>
                        </div>
                      </Fragment>
                    ) : (
                      ""
                    )}
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </Fragment>
    );
  }
}

const mapStateToProps = (state) => ({});

export default connect(mapStateToProps, {})(ExpedientesCreados);
