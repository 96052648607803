import React, { Component, Fragment } from "react";
import { connect } from "react-redux";
import PropTypes from "prop-types";
import {
  postLineaLista,
  getListasCompra,
  postLineasLista,
} from "../../actions/listasCompra";
import { closeModal } from "../../actions/modalGenerica";
import ReactModal from "react-modal";

import "../../css/modal.css";

ReactModal.setAppElement("#root");

export class ModalGenerica extends Component {
  state = {
    lista_seleccionada: "",
  };

  static propTypes = {
    postLineaLista: PropTypes.func.isRequired,
    postLineasLista: PropTypes.func.isRequired,

    closeModal: PropTypes.func.isRequired,
    isOpen: PropTypes.bool.isRequired,
    codigo_articulo: PropTypes.string.isRequired,
    lineas_carrito: PropTypes.array.isRequired,
    listas_compra: PropTypes.array.isRequired,
    menus: PropTypes.array.isRequired,
  };

  onSubmit = (e) => {
    e.preventDefault();

    if (this.props.codigo_articulo !== undefined) {
      const linea = {
        codigo_lista: this.state.lista_seleccionada,
        codigo_articulo: this.props.codigo_articulo,
      };

      this.props.postLineaLista(linea);
    } else if (this.props.lineas_carrito !== undefined) {
      let lineas = [];

      this.props.lineas_carrito.forEach((linea_carrito) => {
        let lin = {
          codigo_lista: this.state.lista_seleccionada,
          codigo_articulo: linea_carrito.articulo,
        };
        lineas.push(lin);
      });

      this.props.postLineasLista(lineas);
    }

    this.props.closeModal();
  };

  onChange = (e) => this.setState({ [e.target.name]: e.target.value });

  cerrarModal = (e) => {
    e.preventDefault();
    this.props.closeModal();
  };

  render() {
    const customStyles = {
      content: {
        top: "50%",
        left: "50%",
        right: "auto",
        bottom: "auto",
        marginRight: "-50%",
        transform: "translate(-50%, -50%)",
      },
    };

    let findPos = (elem) => elem.nombre === "LISTAS";
    let findPos2 = (elem) => elem.programa === "listasCompra";
    let pos;
    let pos2;
    if (this.props.menus !== undefined) {
      pos = this.props.menus.findIndex(findPos);
      if (pos !== -1) {
        pos2 = this.props.menus[pos].hijos.findIndex(findPos2);
      } else {
        pos2 = -1;
      }
    } else {
      pos = -1;
      pos2 = -1;
    }

    if (
      pos2 !== -1 &&
      (this.props.codigo_articulo !== undefined ||
        this.props.lineas_carrito !== undefined)
    ) {
      return (
        <Fragment>
          <ReactModal
            isOpen={this.props.isOpen}
            onRequestClose={this.cerrarModal}
            style={customStyles}
          >
            <div>
              <div>
                <div>
                  <div>
                    <h5>Añadir a lista</h5>
                  </div>
                  <div>
                    <form id="anadirLista" onSubmit={this.onSubmit}>
                      <div className="form-group">
                        <label for="lista_compra">Lista</label>
                        <select
                          class="form-control"
                          id="lista_compra"
                          name="lista_seleccionada"
                          onChange={this.onChange}
                        >
                          <option value="">Selecciona lista</option>
                          {this.props.listas_compra !== undefined
                            ? this.props.listas_compra.map((lista, index) => {
                                return (
                                  <option
                                    key={index}
                                    value={lista.codigo_lista}
                                  >
                                    {lista.nombre_lista}
                                  </option>
                                );
                              })
                            : ""}
                        </select>
                      </div>
                    </form>
                  </div>
                  <div>
                    <button
                      type="submit"
                      form="anadirLista"
                      class="btn btn-primary"
                    >
                      Guardar
                    </button>
                    <button
                      type="button"
                      class="btn btn-secondary"
                      onClick={this.cerrarModal}
                    >
                      Cerrar
                    </button>
                  </div>
                </div>
              </div>
            </div>
          </ReactModal>
        </Fragment>
      );
    } else {
      return "";
    }
  }
}

const mapStateToProps = (state) => ({
  isOpen: state.modalGenerica.isOpen,
  codigo_articulo: state.modalGenerica.codigo_articulo,
  lineas_carrito: state.modalGenerica.lineas,
  listas_compra: state.listasCompra.listas_compra,
  menus: state.portal.menus,
});

export default connect(mapStateToProps, {
  postLineaLista,
  getListasCompra,
  postLineasLista,
  closeModal,
})(ModalGenerica);
