import { SET_ARTICULO,SET_LINEAS } from "../actions/types.js";

export const setArticulo = (id_articulo) => (dispatch) => {
  dispatch({
    type: SET_ARTICULO,
    payload: id_articulo,
  });
};

export const setLineas = (lineas) => (dispatch) => {
    dispatch({
      type: SET_LINEAS,
      payload: lineas,
    });
  };