import React, { Component, Fragment } from "react";
import { connect } from "react-redux";
import PropTypes from "prop-types";
import { getArticulosRelacionados } from "../../actions/articulosRelacionados";
import ListadoArticulos from "./ListadoArticulos";

export class ArticulosRelacionados extends Component {
  static propTypes = {
    getArticulosRelacionados: PropTypes.func.isRequired,
  };

  render() {
    return (
      <Fragment>
        <ListadoArticulos origen="relacionados" id_articulo={this.props.id_articulo}/>
      </Fragment>
    );
  }
}

const mapStateToProps = (state) => ({});

export default connect(mapStateToProps, { getArticulosRelacionados })(
  ArticulosRelacionados
);
