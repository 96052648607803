import React, { Component, Fragment } from "react";
import { connect } from "react-redux";
import PropTypes from "prop-types";
import { getFormasEnvio, setFormaEnvio } from "../../actions/formasEnvio";

export class FormasEnvio extends Component {
  static propTypes = {
    getFormasEnvio: PropTypes.func.isRequired,
    setFormaEnvio: PropTypes.func.isRequired,
    formas_envio: PropTypes.array.isRequired,
    forma_envio: PropTypes.object.isRequired,
  };

  componentDidMount() {
    this.props.getFormasEnvio();
  }

  componentDidUpdate(prevProps) {
    if (prevProps.formas_envio !== this.props.formas_envio) {
      if (this.props.formas_envio.length === 1) {
        this.props.setFormaEnvio(this.props.formas_envio[0]);
      }
    }
  }

  onchange = (e) => {
    const forma_env = this.props.formas_envio.filter(
      (form_env) => form_env.forma_envio === e.target.value
    );

    this.props.setFormaEnvio(forma_env[0]);
  };

  render() {
    return (
      <Fragment>
        <div className="tt-shopcart-box">
          <h4 className="tt-title">FORMAS DE ENVÍO</h4>
          <p>Seleccione una forma de envío de la lista</p>
          <form className="form-default">
            <div className="form-group">
              <label htmlFor="forma_envio">
                FORMA DE ENVÍO <sup>*</sup>
              </label>
              <select
                className="form-control"
                name="forma_envio"
                onChange={this.onchange}
              >
                <option key="0" value=""></option>
                {this.props.formas_envio.map((forma_envio, index) => {
                  if (
                    forma_envio.forma_envio ===
                    this.props.forma_envio.forma_envio
                  ) {
                    return (
                      <option
                        key={index}
                        value={forma_envio.forma_envio}
                        selected
                      >
                        {forma_envio.descripcion}
                      </option>
                    );
                  } else {
                    return (
                      <option key={index} value={forma_envio.forma_envio}>
                        {forma_envio.descripcion}
                      </option>
                    );
                  }
                })}
              </select>
            </div>
          </form>
        </div>
      </Fragment>
    );
  }
}

const mapStateToProps = (state) => ({
  formas_envio: state.formasEnvio.formas_envio,
  forma_envio: state.formasEnvio.forma_envio,
});

export default connect(mapStateToProps, { getFormasEnvio, setFormaEnvio })(
  FormasEnvio
);
