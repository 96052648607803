import React, { Component, Fragment } from "react";
import { connect } from "react-redux";
import PropTypes from "prop-types";
import { Link, Redirect } from "react-router-dom";
import { deleteLinea } from "../../actions/pedidos";
import { integraPedido, eliminarPedido } from "../../actions/pedidos";
import { postLinea } from "../../actions/pedidos";
import { createMessage } from "../../actions/messages";
import { confirmAlert } from "react-confirm-alert"; // Import
import "react-confirm-alert/src/react-confirm-alert.css";

import BotonAnadirLista from "./BotonAnadirLista";
import DomiciliosEnvio from "./DomiciliosEnvio";
import FormasEnvio from "./FormasEnvio";
import Almacenes from "./Almacenes";
import Rutas from "./Rutas";
import Imagen from "./Imagen";
import Descripcion from "./Descripcion";
import FormasPago from "./FormasPago";
import {
  getPedidoActivo,
  calculaPortesPedido,
  actualizaPedido,
} from "../../actions/pedidos";
import Precio from "./Precio";
import TiposPedido from "./TiposPedido";

import "../../css/resumen.css";

export class Resumen extends Component {
  state = {
    observaciones: "",
    volver: "",
  };

  static propTypes = {
    pedido: PropTypes.object.isRequired,
    num_pedido: PropTypes.string.isRequired,
    serie_pedido: PropTypes.string.isRequired,
    ejercicio: PropTypes.string.isRequired,
    numero_pres: PropTypes.string.isRequired,
    numero_serie_pres: PropTypes.string.isRequired,
    eliminarPedido: PropTypes.func.isRequired,
    deleteLinea: PropTypes.func.isRequired,
    domicilio_envio: PropTypes.object.isRequired,
    forma_envio: PropTypes.object.isRequired,
    almacen_recogida: PropTypes.object.isRequired,
    ruta: PropTypes.object.isRequired,
    forma_pago: PropTypes.object.isRequired,
    precio: PropTypes.object.isRequired,
    postLinea: PropTypes.func.isRequired,
    hash: PropTypes.string,
    locale: PropTypes.string.isRequired,
    moneda: PropTypes.string.isRequired,
    formas_pago: PropTypes.array,
    getPedidoActivo: PropTypes.func.isRequired,
    calculaPortesPedido: PropTypes.func.isRequired,
    pedido_cargando: PropTypes.bool.isRequired,
    tipo_pedido: PropTypes.object.isRequired,
  };

  componentDidMount() {
    this.props.getPedidoActivo();
  }

  componentDidUpdate(prevProps, prevState) {
    window.wookie();
    if (
      (prevProps.domicilio_envio !== this.props.domicilio_envio ||
        prevProps.tipo_pedido !== this.props.tipo_pedido) &&
      !this.props.pedido_cargando
    ) {
      this.props.actualizaPedido(
        this.props.domicilio_envio,
        this.props.forma_envio,
        this.props.almacen_recogida,
        this.props.ruta,
        this.props.hash,
        this.props.tipo_pedido
      );
    }
  }

  changeQuantity = (e) => {
    e.preventDefault();
    var input = e.target.parentNode.getElementsByClassName("quantity-input")[0];
    var count =
      parseInt(input.value, 10) +
      parseInt(e.currentTarget.className === "plus-btn" ? 1 : -1, 10);

    var value = Math.max(count, 1);
    const linea = {
      articulo: input.dataset["articulo"],
      descripcion: input.dataset["descripcion"],
      precio_venta: parseFloat(
        this.props.precio[input.dataset["articulo"]].data.precio_neto
      ),
      observaciones: "",
      estado_linea: "B",
      cantidad_pedida: value,
      presentacion_pedido: input.dataset["presentacion_pedido"],
      tipo_linea: "P",
    };
    this.props.postLinea(linea, this.props.hash);
  };

  onChange = (e) => {
    this.setState({ observaciones: e.target.value });
  };

  integrarPedido = (e) => {
    e.preventDefault();

    if (!this.props.pedido_cargando) {
      if (
        this.props.forma_envio.tipo === undefined ||
        this.props.forma_envio.tipo === null
      ) {
        this.props.createMessage({
          noDomEnvio: "Debe seleccionar una forma de envío.",
        });
      } else if (
        this.props.forma_envio.tipo === "D" &&
        (this.props.domicilio_envio.numero_direccion === undefined ||
          this.props.domicilio_envio.numero_direccion === null)
      ) {
        this.props.createMessage({
          noDomEnvio: "Debe seleccionar un domicilio de envío.",
        });
      } else if (
        this.props.forma_envio.tipo === "T" &&
        (this.props.almacen_recogida.almacen === undefined ||
          this.props.almacen_recogida.almacen === null)
      ) {
        this.props.createMessage({
          noDomEnvio: "Debe seleccionar un lugar de recogida.",
        });
      } else if (
        this.props.forma_envio.tipo === "R" &&
        (this.props.ruta.almacen === undefined ||
          this.props.ruta.almacen === null)
      ) {
        this.props.createMessage({
          noDomEnvio: "Debe seleccionar una ruta de reparto.",
        });
      } else if (
        this.props.formas_pago.length > 0 &&
        (this.props.forma_pago.codigo_forma_pago_web === undefined ||
          this.props.forma_pago.codigo_forma_pago_web === null)
      ) {
        this.props.createMessage({
          noDomEnvio: "Debe seleccionar una forma de pago.",
        });
      } else {
        this.props.integraPedido(
          this.props.domicilio_envio,
          this.state.observaciones,
          this.props.forma_envio,
          this.props.almacen_recogida,
          this.props.ruta,
          this.props.forma_pago
        );
      }
    }
  };

  eliminarPedido = (e) => {
    e.preventDefault();

    if (!this.props.pedido_cargando) {
      confirmAlert({
        title: "ELIMINAR PEDIDO",
        message:
          "¿Está seguro de que quiere eliminar el pedido? Esta acción no se podrá deshacer.",
        closeOnEscape: true,
        closeOnClickOutside: true,
        buttons: [
          {
            label: "Sí",
            onClick: () =>
              this.props.eliminarPedido(this.props.pedido.id_pedido),
          },
          {
            label: "No",
          },
        ],
      });
    }
  };

  eliminarLinea = (e) => {
    e.preventDefault();

    if (!this.props.pedido_cargando) {
      const linea_seleccionada = e.target.dataset.linea;

      confirmAlert({
        title: "ELIMINAR ARTÍCULO",
        message:
          "¿Está seguro de que quiere eliminar este artículo del pedido? Esta acción no se podrá deshacer.",
        closeOnEscape: true,
        closeOnClickOutside: true,
        buttons: [
          {
            label: "Sí",
            onClick: () =>
              this.props.deleteLinea(linea_seleccionada, this.props.hash),
          },
          {
            label: "No",
          },
        ],
      });
    }
  };

  recargar = (e) => {
    e.preventDefault();
    this.forceUpdate();
  };

  finalizar = () => {
    const info_pedido =
      this.props.serie_pedido !== undefined &&
      this.props.serie_pedido !== null &&
      this.props.serie_pedido !== "" &&
      this.props.num_pedido !== undefined &&
      this.props.num_pedido !== null &&
      this.props.num_pedido !== ""
        ? this.props.serie_pedido + "/" + this.props.num_pedido
        : this.props.numero_serie_pres + "/" + this.props.numero_pres;
    confirmAlert({
      title: "PEDIDO REALIZADO",
      message:
        "Hemos recibido su pedido: " +
        info_pedido +
        " correctamente.\nGracias por su confianza",
      closeOnEscape: false,
      closeOnClickOutside: false,
      buttons: [
        {
          label: "Aceptar",
          onClick: () => this.setState({ volver: "S" }),
        },
      ],
    });
  };

  render() {
    const { observaciones, volver } = this.state;

    if (
      (Object.keys(this.props.pedido).length === 0 &&
        this.props.num_pedido === "" &&
        this.props.serie_pedido === "" &&
        this.props.numero_pres === "" &&
        this.props.numero_serie_pres === "") ||
      volver === "S"
    ) {
      return <Redirect to="/" />;
    } else if (
      Object.keys(this.props.pedido).length === 0 &&
      ((this.props.num_pedido !== "" && this.props.serie_pedido !== "") ||
        (this.props.numero_pres !== "" && this.props.numero_serie_pres !== ""))
    ) {
      this.finalizar();
    } else if (
      Object.keys(this.props.pedido).length !== 0 &&
      ((this.props.num_pedido !== "" && this.props.serie_pedido !== "") ||
        (this.props.numero_pres !== "" && this.props.numero_serie_pres !== ""))
    ) {
      if (
        this.props.forma_pago.pasarela !== null &&
        this.props.forma_pago.pasarela !== ""
      ) {
        switch (this.props.forma_pago.pasarela) {
          case "WOMPI":
            return <Redirect to="/wompi" />;
            break;

          default:
            break;
        }
      }
    }

    const lineasPedido = (
      <div className="tt-shopcart-table">
        {this.props.pedido.lineas === undefined ||
        this.props.pedido.lineas.length === 0
          ? ""
          : this.props.pedido.lineas.map((linea) => (
              <div className="row linea-carrito" key={linea.id}>
                <div className="col-sm-12 col-md-3 col-lg-3">
                  <div className="tt-product-img">
                    <Imagen
                      codigo_articulo={linea.articulo}
                      principal="S"
                      numero_imagen={1}
                    />
                  </div>
                </div>
                <div className="col-sm-12 col-md-3 col-lg-3">
                  <Descripcion codigo_articulo={linea.articulo} />
                </div>
                <div className="col-sm-12 col-md-2 col-lg-2">
                  <div className="detach-quantity-desctope">
                    <div className="tt-input-counter style-01">
                      {/* {linea.tipo_linea !== "R" &&
                      linea.tipo_articulo !== "F" ? (
                        <span
                          className="minus-btn"
                          onClick={this.changeQuantity}
                        ></span>
                      ) : (
                        ""
                      )} */}

                      <input
                        className="quantity-input"
                        type="text"
                        defaultValue={linea.cantidad_pedida}
                        size="5"
                        data-articulo={linea.articulo}
                        data-presentacion_pedido={linea.presentacion_pedido}
                        data-descripcion={linea.descripcion}
                        readOnly={true}
                      />
                      {/* {linea.tipo_linea !== "R" &&
                      linea.tipo_articulo !== "F" ? (
                        <span
                          className="plus-btn"
                          onClick={this.changeQuantity}
                        ></span>
                      ) : (
                        ""
                      )} */}
                    </div>
                  </div>
                </div>
                <div className="col-sm-12 col-md-2 col-lg-2">
                  <span className="tt-price subtotal">
                    {linea.precio_manual === "S" ? (
                      <Precio
                        codigo_articulo={linea.articulo}
                        precio_manual={linea.precio_venta}
                      />
                    ) : (
                      <Precio codigo_articulo={linea.articulo} />
                    )}
                  </span>
                </div>
                <div className="col-sm-12 col-md-1 col-lg-1">
                  <a
                    href="/#"
                    className="tt-btn-close"
                    data-linea={linea.id}
                    onClick={this.eliminarLinea}
                  >
                    <span className="ocultar">Cerrar</span>
                  </a>
                </div>
              </div>
            ))}

        <div className="tt-shopcart-btn">
          <div className="col-left">
            <Link to="/articulos" className="btn-link">
              <i className="icon-e-19"></i>CONTINUAR COMPRANDO
            </Link>
          </div>
          <div className="col-right">
            <a className="btn-link" href="/#" onClick={this.eliminarPedido}>
              <i className="icon-h-02"></i>BORRAR PEDIDO
            </a>
            {/* <a className="btn-link" href="/#" onClick={this.recargar}>
              <i className="icon-h-48"></i>ACTUALIZAR CARRITO
            </a> */}
            {this.props.pedido.lineas !== undefined &&
            this.props.pedido.lineas.length > 0 ? (
              <BotonAnadirLista
                origen="resumen"
                lineas={this.props.pedido.lineas}
              />
            ) : (
              ""
            )}
          </div>
        </div>
      </div>
    );

    const finalizarPedido = (
      <div className="tt-shopcart-wrapper">
        <TiposPedido />

        <FormasEnvio />

        {this.props.forma_envio.tipo !== undefined &&
        this.props.forma_envio.tipo !== null &&
        this.props.forma_envio.tipo === "D" ? (
          <DomiciliosEnvio />
        ) : (
          ""
        )}
        {this.props.forma_envio.tipo !== undefined &&
        this.props.forma_envio.tipo !== null &&
        this.props.forma_envio.tipo === "T" ? (
          <Almacenes />
        ) : (
          ""
        )}
        {this.props.forma_envio.tipo !== undefined &&
        this.props.forma_envio.tipo !== null &&
        this.props.forma_envio.tipo === "R" ? (
          <Rutas />
        ) : (
          ""
        )}

        <FormasPago />

        <div className="tt-shopcart-box">
          <h4 className="tt-title">OBSERVACIONES</h4>
          <p>Añada alguna observación adicional al pedido</p>
          <textarea
            className="form-control"
            rows="7"
            name="observaciones"
            value={observaciones}
            onChange={this.onChange}
          />
        </div>
        <div className="tt-shopcart-box tt-boredr-large">
          <table className="tt-shopcart-table01">
            <tbody>
              {/*
              <tr>
                <th>SUBTOTAL</th>
                <td>$324</td>
              </tr>
              */}
            </tbody>
            <tfoot>
              <tr>
                <th>TOTAL</th>
                <td>
                  {this.props.pedido.lineas === undefined ||
                  this.props.pedido.lineas.length === 0
                    ? 0
                    : new Intl.NumberFormat(this.props.locale, {
                        style: "currency",
                        currency: this.props.moneda,
                      }).format(
                        Number.parseFloat(
                          this.props.pedido.lineas
                            .map((linea) => {
                              return Number.parseFloat(linea.precio_venta);
                            })
                            .reduce(
                              (accumulator, currentValue) =>
                                accumulator + currentValue
                            )
                        ).toFixed(2)
                      )}
                </td>
              </tr>
            </tfoot>
          </table>
          <a href="/#" className="btn btn-lg" onClick={this.integrarPedido}>
            <span className="icon icon-check_circle"></span>
            FINALIZAR PEDIDO
          </a>
        </div>
      </div>
    );

    const informacionPedido = (
      <div className="tt-shopcart-box">
        <h4 className="tt-title">PEDIDO INTEGRADO</h4>
        <p className="text-center">
          {this.props.serie_pedido}"/"{this.props.num_pedido}
        </p>
      </div>
    );

    return (
      <Fragment>
        <div className="container">
          <div id="tt-pageContent">
            <div className="container-indent">
              <div className="container">
                <h1 className="tt-title-subpages noborder">
                  RESUMEN DEL PEDIDO
                </h1>
                <div className="row">
                  <div className="col-sm-12 col-md-12 col-xl-12">
                    {this.props.num_pedido === "" &&
                    this.props.serie_pedido === ""
                      ? lineasPedido
                      : informacionPedido}
                  </div>
                  <div className="col-sm-12 col-md-12 col-xl-12">
                    {this.props.num_pedido === "" &&
                    this.props.serie_pedido === ""
                      ? finalizarPedido
                      : informacionPedido}
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </Fragment>
    );
  }
}

const mapStateToProps = (state) => ({
  pedido: state.pedidos.pedido,
  domicilio_envio: state.domiciliosEnvio.domicilio,
  almacen_recogida: state.almacenes.almacen,
  forma_envio: state.formasEnvio.forma_envio,
  formas_pago: state.formasPago.formas_pago,
  forma_pago: state.formasPago.forma_pago,
  num_pedido: state.pedidos.num_pedido,
  serie_pedido: state.pedidos.serie_pedido,
  ejercicio: state.pedidos.ejercicio,
  numero_pres: state.pedidos.numero_pres,
  numero_serie_pres: state.pedidos.numero_serie_pres,
  ruta: state.rutas.ruta,
  precio: state.precio.precio,
  hash: state.auth.hash,
  locale: state.precio.locale,
  moneda: state.precio.moneda,
  pedido_cargando: state.pedidos.isLoading,
  tipo_pedido: state.tiposPedido.tipo_pedido,
});

export default connect(mapStateToProps, {
  integraPedido,
  createMessage,
  eliminarPedido,
  deleteLinea,
  postLinea,
  getPedidoActivo,
  calculaPortesPedido,
  actualizaPedido,
})(Resumen);
