import React, { Component, Fragment } from "react";
import { connect } from "react-redux";
import PropTypes from "prop-types";
import { nuevoExpediente } from "../../actions/expedientes";
import InputFecha from "./InputFecha";

export class NuevoExpediente extends Component {
  static propTypes = {
    tipos_expedientes_campos: PropTypes.array.isRequired,
    tipo_expediente: PropTypes.string.isRequired,
    nuevoExpediente: PropTypes.func.isRequired,
  };

  state = {};

  onChange = (e) => {
    this.setState({ [e.target.name]: e.target.value });
  };

  onSubmit = (e) => {
    e.preventDefault();
    let valores = {};

    this.props.tipos_expedientes_campos.forEach((item) => {
      valores[item.campo] = e.currentTarget.elements[item.campo].value;
    });

    valores["tipo_expediente"] = this.props.tipo_expediente;
    this.props.nuevoExpediente(valores);
  };

  render() {
    return (
      <Fragment>
        <div className="container">
          <div id="tt-pageContent">
            <div className="container-indent">
              <div className="container">
                <h1 className="tt-title-subpages noborder">NUEVO EXPEDIENTE</h1>
                <div className="row justify-content-center">
                  <div className="form-default justify-content-center">
                    <form onSubmit={this.onSubmit}>
                      {this.props.tipos_expedientes_campos !== undefined &&
                      this.props.tipos_expedientes_campos.length > 0
                        ? this.props.tipos_expedientes_campos.map(
                            (campo, index) => {
                              switch (campo.tipo_input) {
                                case "text":
                                  return (
                                    <div className="form-group" key={index}>
                                      <label>{campo.literal}</label>
                                      <input
                                        className="form-control"
                                        type="text"
                                        name={campo.campo}
                                        placeholder={campo.literal}
                                        required={campo.obligatorio === "S"}
                                        onChange={this.onChange}
                                        value={this.state[campo.campo]}
                                      />
                                    </div>
                                  );

                                case "date":
                                  return (
                                    <InputFecha campo={campo} index={index} />
                                  );

                                case "select":
                                  break;
                                default:
                                  return (
                                    <div className="form-group" key={index}>
                                      <label>{campo.literal}</label>
                                      <input
                                        className="form-control"
                                        type="text"
                                        name={campo.campo}
                                        placeholder={campo.literal}
                                        required={campo.obligatorio === "S"}
                                        onChange={this.onChange}
                                        value={this.state[campo.campo]}
                                      />
                                    </div>
                                  );
                              }
                            }
                          )
                        : ""}
                      <div class="row">
                        <div class="col-auto mr-auto">
                          <div class="form-group">
                            <button type="submit" className="btn btn-primary">
                              Enviar
                            </button>
                          </div>
                        </div>
                      </div>
                    </form>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </Fragment>
    );
  }
}

const mapStateToProps = (state) => ({});

export default connect(mapStateToProps, {
  nuevoExpediente,
})(NuevoExpediente);
