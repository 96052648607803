import React, { Component, Fragment } from "react";
import { connect } from "react-redux";
import PropTypes from "prop-types";
import Highcharts from "highcharts";
import HighchartsReact from "highcharts-react-official";

export class BarChart extends Component {
  static propTypes = {
    widget: PropTypes.object.isRequired,
  };

  componentDidMount() {}

  componentDidUpdate() {
    window.wookie();
  }

  render() {
    
    var xAxysArrayCategories = [];
    var tmpSeriesNames = [];
    var tmpSeriesData = [];

    let props = this.props;
    if (this.props.widget !== undefined) {
      this.props.widget.datos.forEach(function callback(
        currentValue,
        index,
        array
      ) {
        if (
          !xAxysArrayCategories.includes(
            currentValue[props.widget.columna_grupo]
          )
        ) {
          xAxysArrayCategories.push(currentValue[props.widget.columna_grupo]);
        }

        if (props.widget.columna_subgrupo != null) {
          if (
            !tmpSeriesNames.includes(
              currentValue[props.widget.columna_subgrupo]
            )
          ) {
            tmpSeriesNames.push(currentValue[props.widget.columna_subgrupo]);
            tmpSeriesData[currentValue[props.widget.columna_subgrupo]] = [];
          }
          tmpSeriesData[currentValue[props.widget.columna_subgrupo]].push(
            currentValue[props.widget.columna_valor]
          );
        } else {
          //solo hay una serie el nombre será el titulo del eje x, añadimos el valor a la unica serie
          if (!tmpSeriesNames.includes(props.widget.titulo_eje_x)) {
            tmpSeriesNames.push(props.widget.titulo_eje_x);
            tmpSeriesData[props.widget.titulo_eje_x] = [];
          }
          tmpSeriesData[props.widget.titulo_eje_x].push(
            currentValue[props.widget.columna_valor]
          );
        }
      });

      var series = [];

      tmpSeriesNames.forEach(function callback(serieName) {
        var tmpSerie = { name: serieName, data: tmpSeriesData[serieName] };
        series.push(tmpSerie);
      });

      const options = {
        credits: {
          enabled: false,
        },
        colors: [
          "#A7AABD",
          "#B4485D",
          "#F07D8F",
          "#528B88",
          "#EEE8A9",
          "#D4A418",
          "#426BB9",
          "#424656",
        ],
        chart: {
          type: this.props.widget.tipo,
        },
        title: {
          text: this.props.widget.titulo,
        },
        subtitle: {
          text: this.props.widget.subtitulo_grafico,
        },
        yAxis: {
          min: 0,
          title: {
            text: this.props.widget.titulo_eje_y,
          },
        },
        xAxis: {
          categories: xAxysArrayCategories,
        },
        series: series,
      };
      return <HighchartsReact highcharts={Highcharts} options={options} />;
    } else {
      return <Fragment></Fragment>;
    }
  }
}

const mapStateToProps = (state) => ({});

export default connect(mapStateToProps, {})(BarChart);
