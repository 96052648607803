import React, { Component, Fragment } from "react";
import { connect } from "react-redux";
import PropTypes from "prop-types";
import {
  getArticulos,
  setBusqueda,
  getArticulosByFamily,
} from "../../actions/articulos";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faSearch } from "@fortawesome/free-solid-svg-icons";
import { withRouter } from "react-router";
import "../../css/buscador.css";

export class Buscador extends Component {
  state = {
    valor: "",
  };

  static propTypes = {
    articulos_pagina: PropTypes.number.isRequired,
    articulos_orden: PropTypes.string.isRequired,
    filtros_aplicados: PropTypes.array.isRequired,
    auth: PropTypes.object.isRequired,
    getArticulos: PropTypes.func.isRequired,
    getArticulosByFamily: PropTypes.func.isRequired,
  };

  onChange = (e) => {
    this.setState({ [e.target.name]: e.target.value });
  };

  onSubmit = (e) => {
    e.preventDefault();
    const { valor } = this.state;
    this.props.setBusqueda(valor);

    if (this.props.filtros_aplicados.length > 0) {
      this.props.getArticulosByFamily(
        this.props.filtros_aplicados,
        this.props.articulos_pagina,
        this.props.articulos_orden,
        valor,
        this.props.auth.hash != null ? this.props.auth.hash : ""
      );
    } else {
      this.props.getArticulos(
        valor,
        this.props.articulos_pagina,
        this.props.articulos_orden,
        this.props.auth.hash != null ? this.props.auth.hash : ""
      );
    }

    this.setState({
      valor: "",
    });

    if (this.props.history.location.pathname !== "/articulos") {
      this.props.history.push("/articulos");
    }
  };

  render() {
    const { valor } = this.state;
    return (
      <Fragment>
        <div className="row buscador">
          <div className="col-md-6"></div>
          <div className="col-md-6">
            <form
              onSubmit={this.onSubmit}
              className="form-inline form-default justify-content-end pr-3"
            >
              <div className="input-group">
                <input
                  className="form-control"
                  type="text"
                  name="valor"
                  placeholder="Buscar"
                  onChange={this.onChange}
                  value={valor}
                />
                <div className="input-group-append">
                  <button type="submit" className="btn btn-primary">
                    <FontAwesomeIcon icon={faSearch} className="mr-2" /> Buscar
                  </button>
                </div>
              </div>
            </form>
          </div>
        </div>
      </Fragment>
    );
  }
}

const mapStateToProps = (state) => ({
  articulos_pagina: state.listadoArticulos.articulos_pagina,
  articulos_orden: state.listadoArticulos.articulos_orden,
  filtros_aplicados: state.articulos.filtros_aplicados,
  auth: state.auth,
});

export default withRouter(
  connect(mapStateToProps, { getArticulos, setBusqueda, getArticulosByFamily })(
    Buscador
  )
);
