import axios from "../axiosConfig";

import { GET_TIPOS_NIF } from "./types";
import { tokenConfig } from "./auth";

// GET PROVINCIA
export const getTiposNif = (estado) => (dispatch, getState) => {
    axios
      .get(`/usuario/tiposnif/?estado=${estado}`, tokenConfig(getState))
      .then((res) => {
        dispatch({
          type: GET_TIPOS_NIF,
          payload: res.data,
        });
      })
      .catch((err) => console.log(err));
  };