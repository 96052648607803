import React, { Component, Fragment } from "react";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";

import { registerLocale } from "react-datepicker";
import es from "date-fns/locale/es";
registerLocale("es", es);

export class InfromesFiltroFecha extends Component {
  state = {
    startDate: !Array.isArray(this.props.filtro.valor_inicial_filtro)
      ? Date.parse(this.props.filtro.valor_inicial_filtro)
      : !Array.isArray(this.props.filtro.valor_inicial_filtro_desde)
      ? Date.parse(this.props.filtro.valor_inicial_filtro_desde)
      : null,
    endDate: !Array.isArray(this.props.filtro.valor_inicial_filtro_hasta)
      ? Date.parse(this.props.filtro.valor_inicial_filtro_hasta)
      : null,
  };

  handleChange = (date) => {
    this.setState({
      startDate: date,
    });
  };
  handleChangeHasta = (date) => {
    this.setState({
      endDate: date,
    });
  };

  render() {
    const InputDesde = ({ value, onClick }) => (
      <input
        className={"form-control " + this.props.filtro.nombre_filtro_idi}
        type="text"
        value={value}
        name={this.props.filtro.nombre_filtro + "_DESDE"}
        placeholder={this.props.filtro.titulo_columna + " DESDE"}
        required={this.props.filtro.obligatorio === "S"}
        onClick={onClick}
        readOnly
      />
    );
    const InputHasta = ({ value, onClick }) => (
      <input
        className={"form-control " + this.props.filtro.nombre_filtro_idi}
        type="text"
        value={value}
        name={this.props.filtro.nombre_filtro + "_HASTA"}
        placeholder={this.props.filtro.titulo_columna + " HASTA"}
        required={this.props.filtro.obligatorio === "S"}
        onClick={onClick}
        readOnly
      />
    );
    const InputSimple = ({ value, onClick }) => (
      <input
        className={"form-control " + this.props.filtro.nombre_filtro_idi}
        type="text"
        value={value}
        name={this.props.filtro.nombre_filtro}
        placeholder={this.props.filtro.titulo_columna}
        required={this.props.filtro.obligatorio === "S"}
        onClick={onClick}
        readOnly
      />
    );

    if (this.props.filtro.tipo_filtro === "I") {
      return (
        <Fragment>
          <div className="row">
            <div className="col-md-6">
              <div className="form-group">
                <DatePicker
                  onChange={this.handleChange}
                  isClearable
                  selected={this.state.startDate}
                  dateFormat={this.props.filtro.mascara ? this.props.filtro.mascara : "yyyy-MM-dd"}
                  todayButton="Hoy"
                  locale="es"
                  customInput={<InputDesde />}
                  className="form-control"
                  name={this.props.filtro.nombre_filtro + "_DESDE"}
                  placeholderText={this.props.filtro.titulo_columna + " DESDE"}
                  required={this.props.filtro.obligatorio === "S"}
                />
              </div>
            </div>
            <div className="col-md-6">
              <div className="form-group">
                <DatePicker
                  onChange={this.handleChangeHasta}
                  isClearable
                  selected={this.state.endDate}
                  dateFormat={this.props.filtro.mascara ? this.props.filtro.mascara : "yyyy-MM-dd"}
                  todayButton="Hoy"
                  locale="es"
                  customInput={<InputHasta />}
                  className="form-control"
                  name={this.props.filtro.nombre_filtro + "_HASTA"}
                  placeholderText={this.props.filtro.titulo_columna + " HASTA"}
                  required={this.props.filtro.obligatorio === "S"}
                />
              </div>
            </div>
          </div>
        </Fragment>
      );
    } else {
      return (
        <Fragment>
          <div className="row">
            <div className="col-md-12">
              <div className="form-group">
                <DatePicker
                  onChange={this.handleChange}
                  isClearable
                  selected={this.state.startDate}
                  dateFormat={this.props.filtro.mascara ? this.props.filtro.mascara : "yyyy-MM-dd"}
                  todayButton="Hoy"
                  locale="es"
                  customInput={<InputSimple />}
                  className="form-control"
                  name={this.props.filtro.nombre_filtro}
                  placeholderText={this.props.filtro.titulo_columna}
                  required={this.props.filtro.obligatorio === "S"}
                />
              </div>
            </div>
          </div>
        </Fragment>
      );
    }
  }
}

export default InfromesFiltroFecha;
