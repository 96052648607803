import  axios from "../axiosConfig";

import { FETCH_PEDIDOS_ANTERIORES, GET_PEDIDOS_ANTERIORES,SET_VER_PEDIDO } from "./types";

import { tokenConfig } from "./auth";

//////////////////////////////////////
//////////////CABECERA///////////////
/////////////////////////////////////

export const getPedidosAnteriores = () => (dispatch, getState) => {
  dispatch({
    type: FETCH_PEDIDOS_ANTERIORES,
  });
  axios
    .get(
      `/pedidos/?anteriores=S`,
      tokenConfig(getState)
    )
    .then((res) => {
      dispatch({
        type: GET_PEDIDOS_ANTERIORES,
        payload: res.data,
      });
    })
    .catch((err) => console.log(err));
};

export const getLineasPedido = (id_pedido) => (dispatch, getState) => {
  dispatch({
    type: SET_VER_PEDIDO,
    payload: id_pedido,
  });
};