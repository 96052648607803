import axios from "../axiosConfig";
import { tokenConfig } from "./auth";
import {
  FETCH_FILTROS,
  GET_FILTROS,
  LIMPIAR_FILTROS,
  UPDATE_FILTROS,
  REMOVE_FILTRO,
} from "./types";

// GET filtros
export const getFiltros = () => (dispatch, getState) => {
  dispatch({
    type: FETCH_FILTROS,
  });

  // const refrescar = refreshToken(dispatch);

  let config = tokenConfig(getState);

  axios
    .get("/filtros/", config)
    .then((res) => {
      dispatch({
        type: GET_FILTROS,
        payload: res.data,
      });
    })
    .catch((err) => {
      console.log(err);
      //
      // if (err.response.status === 401) {
      //   refrescar();
      // }
    });
};

export const limpiarFiltros = () => (dispatch, getState) => {
  dispatch({
    type: LIMPIAR_FILTROS,
  });
};

export const añadirFiltro = (filtro) => (dispatch, getState) => {
  dispatch({
    type: UPDATE_FILTROS,
    payload: filtro,
  });
};

export const quitarFiltro = (filtro) => (dispatch, getState) => {
  dispatch({
    type: REMOVE_FILTRO,
    payload: filtro,
  });
};
