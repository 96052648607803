import React, { Component } from "react";
import { Link, Redirect } from "react-router-dom";
import { connect } from "react-redux";
import PropTypes from "prop-types";
import { login } from "../../actions/auth";
import { ordenarMenu } from "../../helpers/funciones";

import { getPedidoActivo } from "../../actions/pedidos";

import { getMenus } from "../../actions/portal";
import "../../css/login.css";

export class Login extends Component {
  state = {
    username: "",
    password: "",
  };

  static propTypes = {
    login: PropTypes.func.isRequired,
    isAuthenticated: PropTypes.bool,
    portal: PropTypes.object.isRequired,
    menus: PropTypes.array.isRequired,
    hash: PropTypes.string,
    invitado: PropTypes.bool,
    getPedidoActivo: PropTypes.func.isRequired,
  };

  componentDidUpdate(prevProps, prevState) {
    window.wookie();

    if (
      prevProps.isAuthenticated !== this.props.isAuthenticated ||
      prevProps.invitado !== this.props.invitado
    ) {
      this.props.getMenus();

      let findPos = (elem) => elem.programa === "articulos";
      if (this.props.menus !== undefined) {
        let pos = this.props.menus.findIndex(findPos);
        if (pos !== -1) {
          this.props.getPedidoActivo(
            this.props.hash != null ? this.props.hash : ""
          );
        }
      }
    }
  }

  onSubmit = (e) => {
    e.preventDefault();
    this.props.login(this.state.username, this.state.password, this.props.hash);
  };

  onChange = (e) => this.setState({ [e.target.name]: e.target.value });

  render() {
    const { username, password } = this.state;
    const { portal } = this.props;
    const menus = this.props.menus;
    let menu_registro;
    let direccion_registro = "";

    if (menus !== undefined && menus.length > 0) {
      menus.sort(ordenarMenu);

      if (this.props.isAuthenticated && this.props.invitado === false) {
        const irA = menus.find(
          (menu) => menu.activo === "S" && menu.programa !== null
        );

        if (irA !== undefined && irA !== null) {
          return <Redirect to={"/" + irA.programa} />;
        } else {
          return "";
        }
      }

      menu_registro = menus.find(
        (menu) =>
          (menu.activo === "S" && menu.programa === "registro") ||
          (menu.activo === "S" && menu.programa === "registroCompleto")
      );
    }

    if (menu_registro !== undefined) {
      direccion_registro = (
        <Link to={"/" + menu_registro.programa}>Formulario de Registro</Link>
      );
    }

    return (
      <div id="tt-pageContent" className="login">
        <div className="container-indent">
          <div className="container">
            <a className="tt-logo" href="index.html">
              <img className="tt-retina" src="images/custom/logo.png" alt="" />
            </a>
            <h1 className="tt-title-subpages noborder">{portal.descripcion}</h1>
            <div className="tt-login-form">
              <div className="row	justify-content-center">
                <div className="col-md-5">
                  <div className="tt-item">
                    <h2 className="tt-title">ACCESO DE USUARIOS</h2>
                    <div className="form-default justify-content-center">
                      <form onSubmit={this.onSubmit}>
                        <div className="form-group">
                          <input
                            className="form-control"
                            type="text"
                            name="username"
                            onChange={this.onChange}
                            value={username}
                            placeholder="Usuario"
                          />
                        </div>
                        <div className="form-group">
                          <input
                            className="form-control"
                            type="password"
                            name="password"
                            onChange={this.onChange}
                            value={password}
                            placeholder="Contraseña"
                          />
                        </div>
                        <div className="row">
                          <div className="col-auto mr-auto">
                            <div className="form-group">
                              <button type="submit" className="btn btn-primary">
                                Login
                              </button>
                            </div>
                          </div>
                        </div>
                        <p>
                          ¿No recuerda su contraseña?{" "}
                          <Link to="/recuperar">Recuperar contraseña</Link>
                        </p>
                        <p>
                          ¿No tiene una cuenta?{" "}
                          {direccion_registro !== undefined
                            ? direccion_registro
                            : ""}
                        </p>
                      </form>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }
}

const mapStateToProps = (state) => ({
  isAuthenticated: state.auth.isAuthenticated,
  hash: state.auth.hash,
  invitado: state.auth.invitado,
  portal: state.portal.portal,
  menus: state.portal.menus,
});

export default connect(mapStateToProps, { login, getMenus, getPedidoActivo })(
  Login
);
