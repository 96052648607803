import React, { Component, Fragment } from "react";
import { connect } from "react-redux";
import PropTypes from "prop-types";
import { setArticulo, setLineas } from "../../actions/botonanadirlista";
import { getListasCompra } from "../../actions/listasCompra";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faList } from "@fortawesome/free-solid-svg-icons";

export class BotonAnadirLista extends Component {
  static propTypes = {
    menus: PropTypes.array.isRequired,
    setArticulo: PropTypes.func.isRequired,
    setLineas: PropTypes.func.isRequired,
    getListasCompra: PropTypes.func.isRequired,
    listas_compra: PropTypes.array.isRequired,
    isLoading: PropTypes.bool.isRequired,
  };

  componentDidMount() {
    if (this.props.listas_compra.length === 0 && !this.props.isLoading) {
      this.props.getListasCompra();
    }
  }

  openModal = (e) => {
    e.preventDefault();

    if (this.props.codigo_articulo !== undefined) {
      this.props.setArticulo(this.props.codigo_articulo);
    } else if (this.props.lineas !== undefined) {
      this.props.setLineas(this.props.lineas);
    }
  };

  render() {
    let findPos = (elem) => elem.nombre === "LISTAS";
    let findPos2 = (elem) => elem.programa === "listasCompra";
    let pos;
    let pos2;
    if (this.props.menus !== undefined) {
      pos = this.props.menus.findIndex(findPos);
      if (pos !== -1) {
        pos2 = this.props.menus[pos].hijos.findIndex(findPos2);
      } else {
        pos2 = -1;
      }
    } else {
      pos = -1;
      pos2 = -1;
    }

    if (pos2 !== -1) {
      return (
        <Fragment>
          {this.props.origen === "articulos" ? (
            <a
              href="#"
              className="tt-btn-wishlist"
              data-tooltip="Añadir a lista de deseos"
              data-tposition="left"
              onClick={this.openModal}
            ></a>
          ) : (
            ""
          )}
          {this.props.origen === "articulos_mobile" ? (
            <a href="/#" className="tt-btn-wishlist" onClick={this.openModal}>
              <span className="ocultar">Lista deseos</span>
            </a>
          ) : (
            ""
          )}
          {this.props.origen === "articulo" ? (
            <a className="btn-link" href="#" onClick={this.openModal}>
              <i className="icon-n-072"></i>AÑADIR A LISTA
            </a>
          ) : (
            ""
          )}
          {this.props.origen === "carrito" ? (
            <div className="tt-cart-btn">
              <div className="tt-item">
                <a href="#" onClick={this.openModal} className="btn">
                  GUARDAR COMO LISTA
                </a>
              </div>
            </div>
          ) : (
            ""
          )}
          {this.props.origen === "resumen" ? (
            <a className="btn-link" href="#" onClick={this.openModal}>
              <FontAwesomeIcon icon={faList} className="mr-2" /> GUARDAR COMO
              LISTA
            </a>
          ) : (
            ""
          )}
        </Fragment>
      );
    } else {
      return "";
    }
  }
}
const mapStateToProps = (state) => ({
  menus: state.portal.menus,
  listas_compra: state.listasCompra.listas_compra,
  isLoading: state.listasCompra.isLoading,
});

export default connect(mapStateToProps, {
  setArticulo,
  setLineas,
  getListasCompra,
})(BotonAnadirLista);
