import React, { Component, Fragment } from "react";
import { connect } from "react-redux";
import PropTypes from "prop-types";
import {
  eliminarLista,
  getListasCompra,
  getLineasListaCompra,
  eliminarLineaLista,
  crearLista,
} from "../../actions/listasCompra";
import { createMessage } from "../../actions/messages";
import { confirmAlert } from "react-confirm-alert"; // Import
import "react-confirm-alert/src/react-confirm-alert.css";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faEye, faTrash, faCartPlus } from "@fortawesome/free-solid-svg-icons";
import { postLinea, postLineas } from "../../actions/pedidos";
import Precio from "./Precio";
import Descripcion from "./Descripcion";

import ReactModal from "react-modal";

import "../../css/listasCompra.css";

ReactModal.setAppElement("#root");

export class ListasCompra extends Component {
  state = {
    nombre_lista: "",
    is_open: false,
  };

  static propTypes = {
    listas_compra: PropTypes.array.isRequired,
    lista_detalle: PropTypes.number.isRequired,
    lineas_lista_detalle: PropTypes.array.isRequired,
    getListasCompra: PropTypes.func.isRequired,
    eliminarLista: PropTypes.func.isRequired,
    crearLista: PropTypes.func.isRequired,
    getLineasListaCompra: PropTypes.func.isRequired,
    eliminarLineaLista: PropTypes.func.isRequired,
    postLinea: PropTypes.func.isRequired,
    postLineas: PropTypes.func.isRequired,
    precios: PropTypes.object.isRequired,
    descripciones: PropTypes.object.isRequired,
  };

  componendividMount() {
    this.props.getListasCompra();
  }

  componendividUpdate() {
    window.wookie();
  }

  deleteLista = (e) => {
    e.preventDefault();
    let _codigo_lista = e.currentTarget.dataset.codigoLista;

    confirmAlert({
      title: "ELIMINAR LISTA COMPRA",
      message:
        "¿Está seguro de que quiere eliminar la lista? Esta acción no se podrá deshacer.",
      closeOnEscape: true,
      closeOnClickOutside: true,
      buttons: [
        {
          label: "Sí",
          onClick: (e) => {
            this.props.eliminarLista(_codigo_lista);
          },
        },
        {
          label: "No",
        },
      ],
    });
  };

  verLista = (e) => {
    e.preventDefault();
    let _codigo_lista = e.currentTarget.dataset.codigoLista;

    this.props.getLineasListaCompra(_codigo_lista);

    this.setState({ is_open: true });
  };

  deleteLinea = (e) => {
    e.preventDefault();
    let _id = e.currentTarget.dataset.id;

    confirmAlert({
      title: "ELIMINAR LINEA",
      message:
        "¿Está seguro de que quiere eliminar la linea? Esta acción no se podrá deshacer.",
      closeOnEscape: true,
      closeOnClickOutside: true,
      buttons: [
        {
          label: "Sí",
          onClick: (e) => {
            this.props.eliminarLineaLista(_id);
          },
        },
        {
          label: "No",
        },
      ],
    });
  };

  anadirCarrito = (e) => {
    e.preventDefault();

    let lineas = [];

    this.props.lineas_lista_detalle.forEach((linea_lista) => {
      let linea = {
        articulo: linea_lista.codigo_articulo,
        descripcion: this.props.descripciones[linea_lista.codigo_articulo].data
          .descrip_comercial,
        precio_venta: parseFloat(
          this.props.precios[linea_lista.codigo_articulo].data.precio_neto
        ),
        observaciones: linea_lista.observaciones,
        estado_linea: "B",
        cantidad_pedida: linea_lista.cantidad_pedida,
        presentacion_pedido: linea_lista.presentacion_pedido,
        tipo_linea: "P",
      };

      lineas.push(linea);
    });

    this.props.postLineas(lineas);
  };

  anadirLineaCarrito = (e) => {
    e.preventDefault();
    let _id = parseInt(e.currentTarget.dataset.id);

    this.props.lineas_lista_detalle
      .filter((linea) => linea.id === _id)
      .forEach((linea_detalle) => {
        const linea = {
          articulo: linea_detalle.codigo_articulo,
          descripcion: this.props.descripciones[linea_detalle.codigo_articulo]
            .data.descrip_comercial,
          precio_venta: parseFloat(
            this.props.precios[linea_detalle.codigo_articulo].data.precio_neto
          ),
          observaciones: linea_detalle.observaciones,
          estado_linea: "B",
          cantidad_pedida: linea_detalle.cantidad_pedida,
          presentacion_pedido: linea_detalle.presentacion_pedido,
          tipo_linea: "P",
        };

        this.props.postLinea(linea);
      });
  };

  nuevaLista = (e) => {
    e.preventDefault();
    if (this.state.nombre_lista !== "") {
      this.props.crearLista(this.state.nombre_lista);
      this.setState({ nombre_lista: "" });
    }
  };

  onChange = (e) => this.setState({ [e.target.name]: e.target.value });

  cerrarModal = (e) => {
    e.preventDefault();
    this.setState({ is_open: false });
  };

  render() {
    const customStyles = {
      content: {
        top: "50%",
        left: "50%",
        right: "auto",
        bottom: "auto",
        marginRight: "-50%",
        transform: "translate(-50%, -50%)",
        width: "90%",
      },
      overlay: {
        "z-index": "10",
      },
    };

    return (
      <Fragment>
        <div className="container">
          <div id="tt-pageContent">
            <div className="container-indent">
              <div className="container">
                <h1 className="tt-title-subpages noborder">LISTAS COMPRA</h1>
                <div className="row">
                  <div className="col-sm-12 col-md-12 col-lg-12">
                    <form
                      className="form-inline"
                      id="nueva_lista"
                      onSubmit={this.nuevaLista}
                    >
                      <div class="form-group">
                        <label for="nombre_lista" className="sr-only">
                          Nombre
                        </label>
                        <input
                          type="text"
                          className="form-control"
                          id="nombre_lista"
                          aria-describedby="nombre_lista_help"
                          placeholder="Nombre de la lista"
                          onChange={this.onChange}
                          name="nombre_lista"
                        />
                      </div>
                      <button type="submit" class="btn btn-primary">
                        Nueva
                      </button>
                    </form>
                  </div>
                </div>
                <div className="row">
                  <div className="col-sm-12 col-md-12 col-lg-12">
                    {this.props.listas_compra.length === 0 ? (
                      ""
                    ) : (
                      <Fragment>
                        <div className="tt-shopcart-table listas_compra_desctop row">
                          <div className="col-sm-12 col-md-12 col-lg-12">
                            <div className="row titulos">
                              <div className="col-sm-12 col-md-3 col-lg-3">
                                NOMBRE
                              </div>
                              <div className="col-sm-12 col-md-3 col-lg-3">
                                FECHA CREACIÓN
                              </div>
                              <div className="col-sm-12 col-md-6 col-lg-6">
                                ACCIONES
                              </div>
                            </div>

                            {this.props.listas_compra.map((lista) => {
                              return (
                                <div className="row" key={lista.codigo_lista}>
                                  <div className="col-sm-12 col-md-3 col-lg-3">
                                    {lista.nombre_lista}
                                  </div>
                                  <div className="col-sm-12 col-md-3 col-lg-3">
                                    {new Date(
                                      Date.parse(lista.fecha_alta)
                                    ).toLocaleDateString(this.props.locale)}
                                  </div>
                                  <div className="col-sm-12 col-md-2 col-lg-2">
                                    <span
                                      onClick={this.verLista}
                                      data-codigo-lista={lista.codigo_lista}
                                    >
                                      <FontAwesomeIcon icon={faEye} />
                                    </span>
                                  </div>
                                  <div className="col-sm-12 col-md-2 col-lg-2">
                                    {lista.codigo_lista !==
                                    this.props.lista_detalle ? (
                                      ""
                                    ) : (
                                      <span
                                        onClick={this.anadirCarrito}
                                        data-codigo-lista={lista.codigo_lista}
                                      >
                                        <FontAwesomeIcon icon={faCartPlus} />
                                      </span>
                                    )}
                                  </div>
                                  <div className="col-sm-12 col-md-2 col-lg-2">
                                    <span
                                      onClick={this.deleteLista}
                                      data-codigo-lista={lista.codigo_lista}
                                    >
                                      <FontAwesomeIcon icon={faTrash} />
                                    </span>
                                  </div>
                                </div>
                              );
                            })}
                          </div>
                        </div>
                        <div className="tt-shopcart-table listas_compra_mobile row">
                          <div className="col-sm-12 col-md-12 col-lg-12">
                            {this.props.listas_compra.map((lista) => {
                              return (
                                <div className="row" key={lista.codigo_lista}>
                                  <div className="col-12">
                                    <div className="row ">
                                      <div className="col-6 titulos">
                                        NOMBRE
                                      </div>
                                      <div className="col-6">
                                        {lista.nombre_lista}
                                      </div>
                                    </div>

                                    <div className="row ">
                                      <div className="col-6 titulos">
                                        FECHA CREACIÓN
                                      </div>
                                      <div className="col-6">
                                        {new Date(
                                          Date.parse(lista.fecha_alta)
                                        ).toLocaleDateString(this.props.locale)}
                                      </div>
                                    </div>
                                    <div className="row ">
                                      <div className="col-6 titulos">
                                        ACCIONES
                                      </div>
                                      <div className="col-6">
                                        <div className="row">
                                          <div className="col-4">
                                            <span
                                              onClick={this.verLista}
                                              data-codigo-lista={
                                                lista.codigo_lista
                                              }
                                            >
                                              <FontAwesomeIcon icon={faEye} />
                                            </span>
                                          </div>

                                          <div className="col-4">
                                            {lista.codigo_lista !==
                                            this.props.lista_detalle ? (
                                              ""
                                            ) : (
                                              <span
                                                onClick={this.anadirCarrito}
                                                data-codigo-lista={
                                                  lista.codigo_lista
                                                }
                                              >
                                                <FontAwesomeIcon
                                                  icon={faCartPlus}
                                                />
                                              </span>
                                            )}
                                          </div>

                                          <div className="col-4">
                                            <span
                                              onClick={this.deleteLista}
                                              data-codigo-lista={
                                                lista.codigo_lista
                                              }
                                            >
                                              <FontAwesomeIcon icon={faTrash} />
                                            </span>
                                          </div>
                                        </div>
                                      </div>
                                    </div>
                                  </div>
                                </div>
                              );
                            })}
                          </div>
                        </div>
                      </Fragment>
                    )}
                  </div>
                </div>
              </div>
            </div>

            <ReactModal
              isOpen={this.state.is_open}
              onRequestClose={this.cerrarModal}
              style={customStyles}
            >
              <span onClick={this.cerrarModal}>
                <i className="icon-f-67"></i>
              </span>

              {this.props.lineas_lista_detalle.length === 0 ||
              this.props.lista_detalle === 0 ? (
                ""
              ) : (
                <div className="container-indent">
                  <div className="container">
                    <h1 className="tt-title-subpages noborder">
                      DETALLE LÍNEAS
                    </h1>
                    <div className="row">
                      <div className="col-sm-12 col-md-12 col-lg-12">
                        <div className="tt-shopcart-table listas_compra_detalle_desctop row">
                          <div className="col-sm-12 col-md-12 col-lg-12">
                            <div className="row titulos">
                              <div className="col-sm-12 col-md-2 col-lg-2">
                                ARTICULO
                              </div>
                              <div className="col-sm-12 col-md-2 col-lg-2">
                                DESCRIPCIÓN
                              </div>
                              <div className="col-sm-12 col-md-1 col-lg-1">
                                PRECIO
                              </div>
                              <div className="col-sm-12 col-md-1 col-lg-1">
                                CANTIDAD
                              </div>
                              <div className="col-sm-12 col-md-2 col-lg-2">
                                PRESENTACIÓN
                              </div>
                              <div className="col-sm-12 col-md-2 col-lg-2">
                                OBSERVACIONES
                              </div>
                              <div className="col-sm-12 col-md-2 col-lg-2">
                                ACCIONES
                              </div>
                            </div>

                            {this.props.lineas_lista_detalle.map((linea) => {
                              return (
                                <div className="row" key={linea.id}>
                                  <div className="col-sm-12 col-md-2 col-lg-2">
                                    {linea.codigo_articulo}
                                  </div>
                                  <div className="col-sm-12 col-md-2 col-lg-2">
                                    <Descripcion
                                      codigo_articulo={linea.codigo_articulo}
                                    />
                                  </div>
                                  <div className="col-sm-12 col-md-1 col-lg-1">
                                    <Precio
                                      codigo_articulo={linea.codigo_articulo}
                                    />
                                  </div>
                                  <div className="col-sm-12 col-md-1 col-lg-1">
                                    {linea.cantidad}
                                  </div>
                                  <div className="col-sm-12 col-md-2 col-lg-2">
                                    {linea.presentacion}
                                  </div>
                                  <div className="col-sm-12 col-md-2 col-lg-2">
                                    {linea.observaciones}
                                  </div>
                                  <div className="col-sm-12 col-md-1 col-lg-1">
                                    <span
                                      onClick={this.anadirLineaCarrito}
                                      data-id={linea.id}
                                    >
                                      <FontAwesomeIcon icon={faCartPlus} />
                                    </span>
                                  </div>
                                  <div className="col-sm-12 col-md-1 col-lg-1">
                                    <span
                                      onClick={this.deleteLinea}
                                      data-id={linea.id}
                                    >
                                      <FontAwesomeIcon icon={faTrash} />
                                    </span>
                                  </div>
                                </div>
                              );
                            })}
                          </div>
                        </div>
                        <div className="tt-shopcart-table listas_compra_detalle_mobile row">
                          <div className="col-sm-12 col-md-12 col-lg-12">
                            {this.props.lineas_lista_detalle.map((linea) => {
                              return (
                                <div className="row">
                                  <div className="col-12">
                                    <div className="row">
                                      <div className="col-6 titulos">
                                        ARTICULO
                                      </div>
                                      <div className="col-6">
                                        {linea.codigo_articulo}
                                      </div>
                                    </div>

                                    <div className="row">
                                      <div className="col-6 titulos">
                                        DESCRIPCIÓN
                                      </div>
                                      <div className="col-6">
                                        <Descripcion
                                          codigo_articulo={
                                            linea.codigo_articulo
                                          }
                                        />
                                      </div>
                                    </div>

                                    <div className="row">
                                      <div className="col-6 titulos">
                                        PRECIO
                                      </div>
                                      <div className="col-6">
                                        <Precio
                                          codigo_articulo={
                                            linea.codigo_articulo
                                          }
                                        />
                                      </div>
                                    </div>

                                    <div className="row">
                                      <div className="col-6 titulos">
                                        CANTIDAD
                                      </div>
                                      <div className="col-6">
                                        {linea.cantidad}
                                      </div>
                                    </div>

                                    <div className="row">
                                      <div className="col-6 titulos">
                                        PRESENTACIÓN
                                      </div>
                                      <div className="col-6">
                                        {linea.presentacion}
                                      </div>
                                    </div>

                                    <div className="row">
                                      <div className="col-6 titulos">
                                        OBSERVACIONES
                                      </div>
                                      <div className="col-6">
                                        {linea.observaciones}
                                      </div>
                                    </div>

                                    <div className="row">
                                      <div className="col-6 titulos">
                                        ACCIONES
                                      </div>
                                      <div className="col-6">
                                        <div className="row">
                                          <div className="col-6">
                                            <span
                                              onClick={this.anadirLineaCarrito}
                                              data-id={linea.id}
                                            >
                                              <FontAwesomeIcon
                                                icon={faCartPlus}
                                              />
                                            </span>
                                          </div>

                                          <div className="col-6">
                                            <span
                                              onClick={this.deleteLinea}
                                              data-id={linea.id}
                                            >
                                              <FontAwesomeIcon icon={faTrash} />
                                            </span>
                                          </div>
                                        </div>
                                      </div>
                                    </div>
                                  </div>
                                </div>
                              );
                            })}
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              )}
            </ReactModal>
          </div>
        </div>
      </Fragment>
    );
  }
}

const mapStateToProps = (state) => ({
  listas_compra: state.listasCompra.listas_compra,
  lista_detalle: state.listasCompra.lista_detalle,
  lineas_lista_detalle: state.listasCompra.lineas_lista_detalle,
  precios: state.precio.precio,
  descripciones: state.descripcion.descripciones,
  locale: state.precio.locale,
});

export default connect(mapStateToProps, {
  eliminarLista,
  getListasCompra,
  getLineasListaCompra,
  createMessage,
  eliminarLineaLista,
  crearLista,
  postLinea,
  postLineas,
})(ListasCompra);
