import { GET_MIGAS_ARTICULO } from "../actions/types.js";

const initialState = {
  migas: [],
};

export default function (state = initialState, action) {
  switch (action.type) {
    case GET_MIGAS_ARTICULO:
      return {
        ...state,
        migas: action.payload,
      };
    default:
      return state;
  }
}
