import React, { Component, Fragment } from "react";
import { connect } from "react-redux";
import PropTypes from "prop-types";
import { getArticulos, getMasArticulos } from "../../actions/articulos";
import { getMenuFamilias } from "../../actions/familias";
import { getFiltros } from "../../actions/filtros";

import Buscador from "./Buscador";
import FamiliasMenu from "./FamiliasMenu";
import ArticulosFiltros from "./ArticulosFiltros";
import ListadoArticulos from "./ListadoArticulos";

import Banners from "./Banners";

import "../../css/articulos.css";

export class Articulos extends Component {
  static propTypes = {
    articulos_pagina: PropTypes.number.isRequired,
    getArticulos: PropTypes.func.isRequired,
    getMenuFamilias: PropTypes.func.isRequired,
    getFiltros: PropTypes.func.isRequired,
  };

  componentDidMount() {
    if (this.props.familias === undefined || this.props.familias.length === 0) {
      this.props.getMenuFamilias();
    }
    if (this.props.filtros === undefined || this.props.filtros.length === 0) {
      this.props.getFiltros();
    }
  }
  componentDidUpdate() {
    window.wookie();
  }

  render() {
    return (
      <Fragment>
        <FamiliasMenu />
        <Banners id_banner="PRUEBA"/>
        <Buscador />
        <div className="container">
          <div id="tt-pageContent" className="articulos">
            <div className="container-indent">
              <div className="container-fluid-custom container-fluid-custom-mobile-padding">
                <div className="row">
                  <ArticulosFiltros />
                  <div className="col-md-12 col-lg-9 col-xl-9">
                    <ListadoArticulos origen="articulos" />
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </Fragment>
    );
  }
}

const mapStateToProps = (state) => ({
  articulos_pagina: state.listadoArticulos.articulos_pagina,
  familias: state.articulos.familias,
  filtros: state.articulos.filtros,
  filtros_aplicados: state.articulos.filtros_aplicados,
});

export default connect(mapStateToProps, {
  getArticulos,
  getMenuFamilias,
  getFiltros,
  getMasArticulos,
})(Articulos);
